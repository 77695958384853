import React, { useRef, useState } from 'react';
import { View, Text, Pressable } from 'react-native';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const StudioMetric = (props) => {

    const chartRef = useRef(null);
    const theme = props.theme
    const data = props.data
    const currentMetrics = props.currentMetrics
    const currentDim = props.currentDim
    const item = props.item
    const deleteHandler = props.deleteHandler
    const activeLayoutItem = props.activeLayoutItem
    const metric1 = item.metric1Value
    const value = data[0][metric1] || 0
    const height = item.h
    const isPreview = props.isPreview
    const color = props.item.color || theme.color

    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            :
              null,
        );
      };

      const handleClose = () => {
        setContextMenu(null);
      };


    const valueFormat = (value) => {

        if (metric1 === 'spend' || metric1 === 'cpa' || metric1 === 'ngr' || metric1 === 'ftd_ngr' || metric1 === 'budget' || metric1 === 'actual_spend') {

                        const output = parseFloat(((parseFloat(value)).toFixed(2))).toLocaleString();
            //const output = Number(parseFloat(value).toFixed(2)).toLocaleString();

            return `€${output}`
            
        } else if (metric1 === 'roas' || metric1 === 'ctr' || metric1 === 'conv' || metric1 === 'spend_rate') {

                        const output = parseFloat(((parseFloat(value * 100)).toFixed(2))).toLocaleString();
            //const output = Number(parseFloat(value).toFixed(2)).toLocaleString();

            return `${output}%`
            
        }else {
            return value.toLocaleString()
        }
    }

    const titleFormat = (value) => {
        if (metric1 === 'ftds') {
            return currentMetrics.primaryMetric
        } else if (metric1 === 'ftd') {
            return currentMetrics.primaryMetric
        } else if (metric1 === 'regs') {
            return currentMetrics.secondaryMetric
        } else if (metric1 === 'reg') {
            return currentMetrics.secondaryMetric
        } else if (metric1 === 'ngr') {
            return currentMetrics.primaryRevenue
        } else if (metric1 === 'ftd_ngr') {
            return currentMetrics.primaryRevenue
        } else return value

    }



    return (
        <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%' }}>
        <View style={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', borderRadius: 7, padding: '1.5%'}}>
            <View style={{width: '100%', height: '15%', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: color, fontSize: height * 3.5, fontFamily: 'Electrolize'}}>
                    {titleFormat(metric1)}
                </Text>
            </View>
            <View style={{width: '100%', height: '85%', justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{color: color, fontSize: height * 6.5, fontFamily: 'Electrolize'}}>
                    {valueFormat(value)}
                </Text>
            </View>

        </View>
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {isPreview && <Pressable onPress={() => deleteHandler(activeLayoutItem)}>
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: 'white'}} 
            //onClick={downloadHandler}
            //onClick={() => clickHandler(activeLayoutItem)}
            >
              Delete Widget
          </MenuItem>
          </Pressable>}
        </Menu>
        </div>
    )
}

export default StudioMetric