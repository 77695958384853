import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;


export default function AttEnhancedTrendsModal(props) {
    const controlArray = props.controlArray
    const open = controlArray.open
    const setOpen = controlArray.setOpen
    const theme = props.theme    
    const config = controlArray.config
    const setConfig = controlArray.setConfig
    
    const [value1, setValue1] = useState(config.graph)
    const [value2, setValue2] = useState(config.timeValue)    
    const [value3, setValue3] = useState(config.dimension)
    const [value4, setValue4] = useState(config.metric)
    const timeList = ['daily', 'weekly', 'monthly', 'yearly']
    const graphList = ['bar', 'line']
    const dimList = ['brand', 'channel']
    const metricList = [ `spend`,
        `regs_ft_lifetime`,
        `ftds_custom_pixel`,
        `ftds_ft_lifetime`,
        `ftds_lt_lifetime`,
        `ftds_linear_lifetime`,
        `ftds_td_first_lifetime`,
        `ftds_td_last_lifetime`,
        `ftds_pos_lifetime`,
        `ngr_custom_pixel`]

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const handleSave = () => {
        const updateConfig = {
            metric: value4,
            timeValue: value2,
            graph: value1,
            dimension: value3
        }
        setConfig(updateConfig)
        setOpen(false);
    }

    const timeOptions = timeList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const graphOptions = graphList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const dimOptions = dimList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const metricOptions = metricList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const filterHandler = (value, index) => {

        if (index === 0) {
            setValue1(value)
        } else if (index === 1) {
            setValue2(value)
        } else if (index === 2) {
            setValue3(value)
        } else if (index === 3) {
            setValue4(value)
        }

    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Report Config</DialogTitle>
                    <DialogContent sx={{
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <InputLabel id="demo-dialog-select-label">Select Chart Type</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label1"
                                    id="demo-dialog-select"
                                    value={value1}
                                    sx={{fontFamily: 'Electrolize'}}

                                    onChange={(event) => filterHandler(event.target.value, 0)}
                                    input={<OutlinedInput label="Select Chart Type" />}
                                >
                                    {graphOptions}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <InputLabel id="demo-dialog-select-label">Select Timeframe</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label2"
                                    id="demo-dialog-select"
                                    value={value2}
                                    sx={{fontFamily: 'Electrolize'}}

                                    onChange={(event) => filterHandler(event.target.value, 1)}
                                    input={<OutlinedInput label="Select Timeframe" />}
                                >
                                    {timeOptions}
                                </Select>
                            </FormControl>

                        </Box>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>

                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <InputLabel id="demo-dialog-select-label">Select Metric</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label1"
                                    id="demo-dialog-select"
                                    value={value4}
                                    sx={{fontFamily: 'Electrolize'}}

                                    onChange={(event) => filterHandler(event.target.value, 3)}
                                    input={<OutlinedInput label="Select Metric" />}
                                >
                                    {metricOptions}
                                </Select>
                            </FormControl>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSave}>Update</Button>
                    </DialogActions>
                </View>

            </Dialog>
        </div>

    );
}