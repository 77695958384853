export default function ExecSummaryMetricsSummaryParse (aggResult, brand, country) {

    var dataset = aggResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterByBrand(data, brand) {
        return data.filter(item => item.brand === brand);
    }

    function filterByCountries(data, countries) {
        return data.filter(item => countries.includes(item.country));
    }


    const brandFiltered = filterByBrand(dataset, brand)
    const countryFiltered  = filterByCountries(brandFiltered, country)

    const aggregatedData = countryFiltered.reduce((acc, item) => {
        const dim = item.dim;
        const minus2_day = item.minus2_day;
        const minus3_day = item.minus3_day;
        const mtd = item.mtd;
        const prevMonth = item.prevMonth;
        const prevMonthYear = item.prevMonthYear;
        const prevYTD = item.prevYTD;
        const prevWeek = item.prevWeek;
        const yesterday = item.yesterday;
        const ytd = item.ytd
        

        if (!acc[dim]) {
            acc[dim] = {
                dim: dim,
                minus2_day: parseFloat(minus2_day) || 0,
                minus3_day: parseFloat(minus3_day) || 0,
                mtd: parseFloat(mtd) || 0,
                prevMonth: parseInt(prevMonth) || 0,
                prevMonthYear: parseFloat(prevMonthYear) || 0,
                prevYTD: parseFloat(prevYTD) || 0,
                prevWeek: parseFloat(prevWeek) || 0,
                yesterday: parseFloat(yesterday) || 0,
                ytd: parseFloat(ytd) || 0,
            };
        } else {
            acc[dim].minus2_day += parseFloat(minus2_day) || 0;
            acc[dim].minus3_day += parseFloat(minus3_day) || 0;
            acc[dim].mtd += parseFloat(mtd) || 0;
            acc[dim].prevMonth += parseInt(prevMonth) || 0;
            acc[dim].prevMonthYear += parseFloat(prevMonthYear) || 0;
            acc[dim].prevYTD += parseFloat(prevYTD) || 0;
            acc[dim].prevWeek += parseFloat(prevWeek) || 0;
            acc[dim].yesterday += parseFloat(yesterday) || 0;
            acc[dim].ytd += parseFloat(ytd) || 0;
        }

        return acc;

    }, {});

    const flatOutput = Object.values(aggregatedData)

    const actives_minus2 = flatOutput[10]['minus2_day']
    const actives_minus3 = flatOutput[10]['minus3_day']
    const actives_mtd = flatOutput[10]['mtd']
    const actives_prevMonth = flatOutput[10]['prevMonth']
    const actives_prevMonthYear = flatOutput[10]['prevMonthYear']
    const actives_prevWeek = flatOutput[10]['prevWeek']
    const actives_prevYTD = flatOutput[10]['prevYTD']
    const actives_yesterday = flatOutput[10]['yesterday']
    const actives_ytd = flatOutput[10]['ytd']

    const ggr_minus2 = flatOutput[3]['minus2_day']
    const ggr_minus3 = flatOutput[3]['minus3_day']
    const ggr_mtd = flatOutput[3]['mtd']
    const ggr_prevMonth = flatOutput[3]['prevMonth']
    const ggr_prevMonthYear = flatOutput[3]['prevMonthYear']
    const ggr_prevWeek = flatOutput[3]['prevWeek']
    const ggr_prevYTD = flatOutput[3]['prevYTD']
    const ggr_yesterday = flatOutput[3]['yesterday']
    const ggr_ytd = flatOutput[3]['ytd']

    const ngr_minus2 = flatOutput[2]['minus2_day']
    const ngr_minus3 = flatOutput[2]['minus3_day']
    const ngr_mtd = flatOutput[2]['mtd']
    const ngr_prevMonth = flatOutput[2]['prevMonth']
    const ngr_prevMonthYear = flatOutput[2]['prevMonthYear']
    const ngr_prevWeek = flatOutput[2]['prevWeek']
    const ngr_prevYTD = flatOutput[2]['prevYTD']
    const ngr_yesterday = flatOutput[2]['yesterday']
    const ngr_ytd = flatOutput[2]['ytd']

    const cashwager_minus2 = flatOutput[8]['minus2_day']
    const cashwager_minus3 = flatOutput[8]['minus3_day']
    const cashwager_mtd = flatOutput[8]['mtd']
    const cashwager_prevMonth = flatOutput[8]['prevMonth']
    const cashwager_prevMonthYear = flatOutput[8]['prevMonthYear']
    const cashwager_prevWeek = flatOutput[8]['prevWeek']
    const cashwager_prevYTD = flatOutput[8]['prevYTD']
    const cashwager_yesterday = flatOutput[8]['yesterday']
    const cashwager_ytd = flatOutput[8]['ytd']

    const deposits_minus2 = flatOutput[5]['minus2_day']
    const deposits_minus3 = flatOutput[5]['minus3_day']
    const deposits_mtd = flatOutput[5]['mtd']
    const deposits_prevMonth = flatOutput[5]['prevMonth']
    const deposits_prevMonthYear = flatOutput[5]['prevMonthYear']
    const deposits_prevWeek = flatOutput[5]['prevWeek']
    const deposits_prevYTD = flatOutput[5]['prevYTD']
    const deposits_yesterday = flatOutput[5]['yesterday']
    const deposits_ytd = flatOutput[5]['ytd']

    const adpu = {
        dim: 'ADPU',
        minus2_day: (deposits_minus2 || 0) / (actives_minus2 || 0),
        minus3_day: (deposits_minus3 || 0) / (actives_minus3 || 0),
        mtd: (deposits_mtd || 0) / (actives_mtd || 0),
        prevMonth: (deposits_prevMonth || 0) / (actives_prevMonth || 0),
        prevMonthYear: (deposits_prevMonthYear || 0) / (actives_prevMonthYear || 0),
        prevWeek: (deposits_prevWeek || 0) / (actives_prevWeek || 0),
        prevYTD: (deposits_prevYTD || 0) / (actives_prevYTD || 0),
        yesterday: (deposits_yesterday || 0) / (actives_yesterday || 0),
        ytd: (deposits_ytd || 0) / (actives_ytd || 0)
    }

    const ggr_margin = {
        dim: 'GGR Margin',
        minus2_day: (ggr_minus2  || 0) / (cashwager_minus2 || 0),
        minus3_day: (ggr_minus3 || 0)  / (cashwager_minus3 || 0),
        mtd: (ggr_mtd || 0)  / (cashwager_mtd || 0),
        prevMonth: (ggr_prevMonth || 0)  / (cashwager_prevMonth || 0),
        prevMonthYear: (ggr_prevMonthYear || 0)  / (cashwager_prevMonthYear || 0),
        prevWeek: (ggr_prevWeek || 0)  / (cashwager_prevWeek || 0),
        prevYTD: (ggr_prevYTD || 0)  / (cashwager_prevYTD || 0),
        yesterday: (ggr_yesterday || 0)  / (cashwager_yesterday || 0),
        ytd: (ggr_ytd || 0)  / (cashwager_ytd || 0)
    }

    const arpu = {
        dim: 'ARPU',
        minus2_day: (ngr_minus2 || 0) / (actives_minus2 || 0),
        minus3_day: (ngr_minus3 || 0) / (actives_minus3 || 0),
        mtd: (ngr_mtd || 0) / (actives_mtd || 0),
        prevMonth: (ngr_prevMonth || 0) / (actives_prevMonth || 0),
        prevMonthYear: (ngr_prevMonthYear || 0) / (actives_prevMonthYear || 0),
        prevWeek: (ngr_prevWeek || 0) / (actives_prevWeek || 0),
        prevYTD: (ngr_prevYTD || 0) / (actives_prevYTD || 0),
        yesterday: (ngr_yesterday || 0) / (actives_yesterday || 0),
        ytd: (ngr_ytd || 0) / (actives_ytd || 0)
    }

    const hold = {
        dim: 'Hold',
        minus2_day: (ngr_minus2 || 0) / (deposits_minus2 || 0),
        minus3_day: (ngr_minus3 || 0) / (deposits_minus3 || 0),
        mtd: (ngr_mtd || 0) / (deposits_mtd || 0),
        prevMonth: (ngr_prevMonth || 0) / (deposits_prevMonth || 0),
        prevMonthYear: (ngr_prevMonthYear || 0) / (deposits_prevMonthYear || 0),
        prevWeek: (ngr_prevWeek || 0) / (deposits_prevWeek || 0),
        prevYTD: (ngr_prevYTD || 0) / (deposits_prevYTD || 0),
        yesterday: (ngr_yesterday || 0) / (deposits_yesterday || 0),
        ytd: (ngr_ytd || 0) / (deposits_ytd || 0)
    }

    const output = [
        flatOutput[0],
        flatOutput[1],
        flatOutput[2],
        flatOutput[3],
        flatOutput[4],
        flatOutput[5],
        flatOutput[6],
        flatOutput[7],
        flatOutput[8],
        flatOutput[9],
        flatOutput[10],
        adpu,
        ggr_margin,
        arpu,
        hold
    ]

    let dataProp;

    dataProp = output.map((obj) => {
        const dim = obj.dim
        const minus2_day = obj.minus2_day
        const minus3_day = obj.minus3_day
        const mtd = obj.mtd
        const prevMonth = obj.prevMonth
        const prevMonthYear = obj.prevMonthYear
        const prevYTD = obj.prevYTD
        const prevWeek = obj.prevWeek
        const yesterday = obj.yesterday
        const ytd = obj.ytd
        const mom = (!prevMonth || isNaN(prevMonth) || prevMonth === 0) ? 0 : parseFloat(((mtd - prevMonth) / prevMonth).toFixed(2));
        const mtdYoY = (!prevMonthYear || isNaN(prevMonthYear) || prevMonthYear === 0) ? 0 : parseFloat(((mtd - prevMonthYear) / prevMonthYear).toFixed(2));
        const ytdYoY = (!prevYTD || isNaN(prevYTD) || prevYTD === 0) ? 0 : parseFloat(((ytd - prevYTD) / prevYTD).toFixed(2));

        function checkInfinite (value) {
            let output;
            if (!isFinite(value)) {
                output = "-"
            } else { output = value}
            return output
        }

        return {
          metric: dim || "NULL",
          yesterday: yesterday ||0,
          same_day_last_week: prevWeek || 0,
          two_days_ago: minus2_day || 0,
          three_days_ago: minus3_day || 0,
          mtd: mtd || 0,
          mom: checkInfinite(mom) || 0,
          mtd_yoy: checkInfinite(mtdYoY) || 0,
          ytd_yoy: checkInfinite(ytdYoY) || 0

    
        };
      });

    

    return dataProp

}