import update from 'immutability-helper'
import { memo, useCallback, useState } from 'react'
import { Box } from './Box.js'
import { Dustbin } from './Dustbin.js'
import { ItemTypes } from './ItemTypes.js'
import { View, StyleSheet } from 'react-native'
import routemap from '../../../routes.json'

export const Container = memo(function Container(props) {

    const theme = props.theme
    const dropHandler = props.dropHandler
    const currentConfig = props.currentConfig
    const configHandler = props.configHandler
    const clientID = props.clientID
    const currentRoutes = routemap[clientID]['config']

    const excludeRoutes = ['Home', 'Login', 'TESTING', 'SignedUp', 'AI Chat', 'Options - User']


  const filterList = currentRoutes.filter((item) => {
    return !excludeRoutes.includes(item.text);
  });

    function transformDrawers(inputArray) {
        return inputArray.map(item => {
            return {
                name: item.text,
                type: ItemTypes.SHORTCUT,
                link: item.link,
                parent: item.parent,

            };
        });
    }

    const drawerMap = transformDrawers(filterList)



    const [dustbins, setDustbins] = useState([
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
    ])
    const [chartGraph1, setchartGraph1] = useState([
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        { accepts: [ItemTypes.SHORTCUT], lastDroppedItem: null },
        


    ])
 


    const [droppedChild1Names, setDroppedChild1Names] = useState([])
    function isDropped(boxName) {
        return droppedChild1Names.indexOf(boxName) > -1
    }


    const handleChild1Drop = useCallback(
        (index, item, type) => {
            const { name } = item
            setDroppedChild1Names(
                update(droppedChild1Names, name ? { $push: [name] } : { $push: [] }),
                configHandler(index, name, type)
            )
            setchartGraph1(
                update(chartGraph1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            )
        },
        [droppedChild1Names, chartGraph1, dropHandler],
    )

    const acceptsType = [];
    const acceptsXaxis = [];
    const acceptsMetrics = []

    dustbins.forEach(dustbin => {
        if (dustbin.accepts[0] === 'type') {
            acceptsType.push(dustbin);
        } else if (dustbin.accepts[0] === 'xAxis') {
            acceptsXaxis.push(dustbin);
        } else {
            acceptsMetrics.push(dustbin);
        }
    });

    return (
        <View style={styles.parent}>
        <View style={styles.container}>
            <View style={styles.topRow}>
                <View style={styles.graphTopRow}>
                <div style={{alignItems: 'center', justifyContent: 'center'}}>
                    {drawerMap.map(({ name, type, parent }, index) => (
                        <Box
                            name={name}
                            type={type}
                            parent={parent}
                            isDropped={isDropped(name)}
                            key={index}
                            theme={theme}
                            style={{padding: '1px'}}
                        />
                    ))}
                </div>

                </View>

            </View>
            <View style={styles.bottomRow}>
                
                    <View style={{ width: '100%', 
                                        height: '100%', 
                                        paddingLeft: '0%',
                                        flexDirection: 'row',

                                        fontFamily: 'Electrolize',
                                        borderColor: 'red',
                                        borderWidth: 0}}>
                    {chartGraph1.map(({ accepts, lastDroppedItem }, index) => (
                        <View style={styles.bottomRowPanel}
                            key={index}>
                        <Dustbin
                            accept={accepts}
                            lastDroppedItem={lastDroppedItem}
                            onDrop={(item) => handleChild1Drop(index, item, 'shortcut')}
                            key={index}
                            theme={theme}
                            currentConfig={currentConfig}
                            indexCount={index}
                            chartType={'shortcut'}
                            />
                            </View>
                        ))}                            
                    </View>  

                

            </View>
            

        </View>
           

        </View>
        
    )
})

const styles = StyleSheet.create({
    
    parent: {
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
    },    
    container: {
        borderWidth: 2,
        borderColor: 'grey',
        borderRadius: 10,
        height: '100%',
        width: '100%',
        fontFamily: 'Electrolize',
        flexDirection: 'column'
    },
    footer : {
        borderWidth: 0,
        borderColor: 'grey',
        height: '100%',
        width: '20%',
        fontFamily: 'Electrolize',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '0.5%'

    },
    footerFAB: {
        borderWidth: 0,
        borderColor: 'grey',
        height: '10%',
        width: '20%',
        fontFamily: 'Electrolize',

        justifyContent: 'center',

    },

    topRow: {
        width: '100%',
        height: '75%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '0.5%',


    },
    bottomRow: {
        width: '100%',
        height: '25%',
        flexDirection: 'row',

    },
    graphTopRow: {
        height: '100%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0.5%'
        

    },
    panelContainer: {
        height: '100%',
        width: '33.333333%',
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: 'grey',
    },
    panelContainerRow: {
        width: '100%',
        height: '33.333333%',
        flexDirection: 'row',
    },
    panelContainerRowItem: {
        width: '50%',
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center'
    },
    topRowPanel: {
        width: '100%',
        height: '33.333333%',
        borderWidth: 1,
        borderColor: 'grey',
        
    },
    bottomRowPanel: {
        width: '11.11111111%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
    },
})
