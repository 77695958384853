import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import DashSelect from './DashSelect';
import DashTemplate1 from './DashTempate1';
import DashTemplate2 from './DashTemplate2.js';
import DashTemplate3 from './DashTemplate3.js';
import DashTemplate4 from './DashTemplate4.js';
import { postToSignedUrl } from '../../UserConfigs/AwsSigner.js';
import signRequest from '../../UserConfigs/AwsSigner.js';
import LoadingComponent from '../../Components/Loader.js';
import ParseMain from '../DashCube/ParseMain.js';
import ParseMetric from '../DashCube/ParseMetric.js';
import moment from 'moment-timezone';

const windowHeight = Dimensions.get('window').height;

const DashPanel = (props) => {

    const [currentDash, setCurrentDash] = useState('Dashboard 1')
    const uniqueBrands = props.uniqueBrands
    const uniqueChannels = props.uniqueChannels
    const uniqueTypes = props.uniqueTypes
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const uniqueDimensions = props.uniqueDimensions
    const uniqueMetrics = props.uniqueMeasures
    const filters = props.filters
    const theme = props.theme
    const dashConfigBase = props.dashConfig
    const modalVisible = props.modalVisible
    const setModalVisible = props.setModalVisible
    const [dashConfig, setDashConfig] = useState(dashConfigBase)
    const [updateState, setUpdateState] = useState(null)
    const cubeTable = props.cubeTable
    const tooltipArray = props.tooltipArray
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const start = filters.start
    const end = filters.end
    const brand = (filters.brand === "Total") ? "null" : filters.brand;
    const channel = (filters.channel === "Total") ? "null" : filters.channel;
    const type = (filters.kw_type === "Total") ? "null" : filters.kw_type;

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = [brand]
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = [channel]
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = [type]
        filter3Operator = ['equals']
    }

    const reportIndices = {
        'Dashboard 1': 0,
        'Dashboard 2': 1,
        'Dashboard 3': 2,
        'Dashboard 4': 3,
        'Dashboard 5': 4,
        'Dashboard 6': 5,
      };
      
      // Use the lookup table to get the reportIndex
    const reportIndex = reportIndices[currentDash] || 0;

    function mainGraphHandler (dashKey, dashValue) {
        dashConfigBase[reportIndex][currentDash][dashKey] = dashValue
        setDashConfig(dashConfigBase)
        localStorage.setItem("dashConfig", JSON.stringify(dashConfig))
        setUpdateState(dashKey+dashValue)
    }

    function childGraphHandler (dashKey, dashValue, dashChild) {
        dashConfigBase[reportIndex][currentDash][dashChild][dashKey] = dashValue
        setDashConfig(dashConfigBase)
        localStorage.setItem("dashConfig", JSON.stringify(dashConfig))
        setUpdateState(dashKey+dashValue+dashChild)
    }

    function templateHandler (templateValue) {
        dashConfigBase[reportIndex][currentDash].template = templateValue
        setDashConfig(dashConfigBase)
        localStorage.setItem("dashConfig", JSON.stringify(dashConfig))
        setUpdateState(templateValue)
    }

    const currentConfig = dashConfig[reportIndex][currentDash]
    const currentTemplate = currentConfig.template
    const currentTime = currentConfig.time
    const currentType = currentConfig.type
    const currentMetric1 = currentConfig.metric1
    const currentMetric2 = currentConfig.metric2
    const xAxis = currentConfig.xAxis
    const panel1 = currentConfig.panel1
    const panel2 = currentConfig.panel2
    const panel3 = currentConfig.panel3
    const panel4 = currentConfig.panel4
    const panel5 = currentConfig.panel5
    const panel6 = currentConfig.panel6
    const child1 = currentConfig.childGraph1
    const child2 = currentConfig.childGraph2
    const child3 = currentConfig.childGraph3
    const child4 = currentConfig.childGraph4
    const child5 = currentConfig.childGraph5
    const child6 = currentConfig.childGraph6


    function calculateDate(date, time) {
        // Create a copy of the input date to avoid modifying the original date
        const newDate = moment.tz(date, 'Europe/Paris');
      
        if (time === 'weekly') {
            // Subtract 8 weeks (8 * 7 days) from the date
            newDate.subtract(8, 'weeks');
          } else if (time === 'monthly') {
            // Subtract 12 months from the date
            newDate.subtract(12, 'months');
          }
        
          // Format the new date as ISO date (YYYY-MM-DD)
          return newDate.format('YYYY-MM-DD');
        }

    currentConfig.start = calculateDate(start, currentTime);
    child1.start = calculateDate(start, child1.time);
    child2.start = calculateDate(start, child2.time);
    child3.start = calculateDate(start, child3.time);
    child4.start = calculateDate(start, child4.time);
    child5.start = calculateDate(start, child5.time);
    child6.start = calculateDate(start, child6.time);



    const currentSettings = {
        currentTemplate,
        currentTime,
        currentType,
        currentMetric1,
        currentMetric2,
        xAxis,
        panel1,
        panel2,
        panel3,
        panel4,
        panel5,
        panel6,
        child1,
        child2,
        child3,
        child4,
        child5,
        child6
    }

    const cubeDims = ['date', 'brand', 'channel', 'kw_type']


    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_dash_builder_cube`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };


    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
      }, [configArray, filters])

    useEffect(() => {
        WebFont.load({
            google: {
            families: ['Electrolize']
            }
        });
        }, []);

        if (isLoading) {
            return (
              <LoadingComponent theme={theme} />
            );
          }
          
          if (!configResponse) {
            return (
              <LoadingComponent theme={theme} />
            );
          }
    
    const cubeResponseBase = configResponse.data

    const brandDefault = `brand`
    const typeDefault = `kw_type`
    const channelDefault = `channel`
    const dateDefault = `date`
    const timestampDefault = `date`
    const timestampDayDefault = `date`
    const impressionDefault = `impressions`
    const clicksDefault = `clicks`
    const regsDefault = `regs`
    const spendDefault = `spend`
    const ftdDefault = `ftds`
    const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DDT00:00:00.000");
    

    const fallback = [{
        [brandDefault]: "No Data",
        [channelDefault]: "No Data",
        [clicksDefault]: "0",
        [dateDefault]: yesterday,
        [ftdDefault]: "0",
        [impressionDefault]: "0",
        [typeDefault]: "No Data",
        [regsDefault]: "0",
        [spendDefault]: "0",
        [timestampDefault]: yesterday,
        [timestampDayDefault]: yesterday,
    }]

    const cubeResponse = cubeResponseBase.length > 0 
                     ? cubeResponseBase
                     : fallback;
    const mainAgg = ParseMain(cubeResponse, xAxis, currentMetric1, currentMetric2, cubeTable, currentType, currentConfig.start, end, currentTime,)
    const childAgg1 = ParseMain(cubeResponse, child1.xAxis, child1.metric1, child1.metric2, cubeTable, child1.type, child1.start, end, child1.time)
    const childAgg2 = ParseMain(cubeResponse, child2.xAxis, child2.metric1, child2.metric2, cubeTable, child2.type, child2.start, end, child2.time)
    const childAgg3 = ParseMain(cubeResponse, child3.xAxis, child3.metric1, child3.metric2, cubeTable, child3.type, child3.start, end, child3.time)
    const childAgg4 = ParseMain(cubeResponse, child4.xAxis, child4.metric1, child4.metric2, cubeTable, child4.type, child4.start, end, child4.time)
    const childAgg5 = ParseMain(cubeResponse, child5.xAxis, child5.metric1, child5.metric2, cubeTable, child5.type, child5.start, end, child5.time)
    const childAgg6 = ParseMain(cubeResponse, child6.xAxis, child6.metric1, child6.metric2, cubeTable, child6.type, child6.start, end, child6.time)
    const panelAgg1 = ParseMetric(cubeResponse, panel1, currentMetric1, currentMetric2, cubeTable, currentType)
    const panelAgg2 = ParseMetric(cubeResponse, panel2, currentMetric1, currentMetric2, cubeTable, currentType)
    const panelAgg3 = ParseMetric(cubeResponse, panel3, currentMetric1, currentMetric2, cubeTable, currentType)
    const panelAgg4 = ParseMetric(cubeResponse, panel4, currentMetric1, currentMetric2, cubeTable, currentType)
    const panelAgg5 = ParseMetric(cubeResponse, panel5, currentMetric1, currentMetric2, cubeTable, currentType)
    const panelAgg6 = ParseMetric(cubeResponse, panel6, currentMetric1, currentMetric2, cubeTable, currentType)

   
    const renderComponentContent = () => {
        if (currentTemplate === 1) {
            return (<DashTemplate1
                theme={theme}
                setDashConfig={setDashConfig}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                currentSettings={currentSettings}
                mainGraphHandler={mainGraphHandler}
                childGraphHandler={childGraphHandler}
                uniqueDimensions={uniqueDimensions}
                uniqueMetrics={uniqueMetrics}
                reportIndex={reportIndex}
                currentDash={currentDash}
                dashConfig={dashConfig}
                mainAgg={mainAgg}
                childAgg1={childAgg1}
                childAgg2={childAgg2}
                childAgg3={childAgg3}
                childAgg4={childAgg4}
                childAgg5={childAgg5}
                childAgg6={childAgg6}
                panelAgg1={panelAgg1}
                panelAgg2={panelAgg2}
                panelAgg3={panelAgg3}
                panelAgg4={panelAgg4}
                panelAgg5={panelAgg5}
                panelAgg6={panelAgg6}
                currentTemplate={currentTemplate}
                updateState={updateState}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                tooltipArray={tooltipArray}
                currentMetrics={currentMetrics}
                />)
        } else if (currentTemplate === 2) {
            return (<DashTemplate2
                theme={theme}
                setDashConfig={setDashConfig}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                currentSettings={currentSettings}
                mainGraphHandler={mainGraphHandler}
                childGraphHandler={childGraphHandler}
                uniqueDimensions={uniqueDimensions}
                uniqueMetrics={uniqueMetrics}
                reportIndex={reportIndex}
                currentDash={currentDash}
                dashConfig={dashConfig}
                mainAgg={mainAgg}
                childAgg1={childAgg1}
                childAgg2={childAgg2}
                childAgg3={childAgg3}
                childAgg4={childAgg4}
                childAgg5={childAgg5}
                childAgg6={childAgg6}
                panelAgg1={panelAgg1}
                panelAgg2={panelAgg2}
                panelAgg3={panelAgg3}
                panelAgg4={panelAgg4}
                panelAgg5={panelAgg5}
                panelAgg6={panelAgg6}
                currentTemplate={currentTemplate}
                updateState={updateState}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                tooltipArray={tooltipArray}
                currentMetrics={currentMetrics}
                />

            )
        } else if (currentTemplate === 3) {
            return (<DashTemplate3
                theme={theme}
                setDashConfig={setDashConfig}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                currentSettings={currentSettings}
                mainGraphHandler={mainGraphHandler}
                childGraphHandler={childGraphHandler}
                uniqueDimensions={uniqueDimensions}
                uniqueMetrics={uniqueMetrics}
                reportIndex={reportIndex}
                currentDash={currentDash}
                dashConfig={dashConfig}
                mainAgg={mainAgg}
                childAgg1={childAgg1}
                childAgg2={childAgg2}
                childAgg3={childAgg3}
                childAgg4={childAgg4}
                childAgg5={childAgg5}
                childAgg6={childAgg6}
                panelAgg1={panelAgg1}
                panelAgg2={panelAgg2}
                panelAgg3={panelAgg3}
                panelAgg4={panelAgg4}
                panelAgg5={panelAgg5}
                panelAgg6={panelAgg6}
                currentTemplate={currentTemplate}
                updateState={updateState}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                tooltipArray={tooltipArray}
                currentMetrics={currentMetrics}
                />

            )
        } else if (currentTemplate === 4) {
            return (<DashTemplate4
                theme={theme}
                setDashConfig={setDashConfig}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                handleStateChange={handleStateChange}
                propObject={propObject}
                currentSettings={currentSettings}
                mainGraphHandler={mainGraphHandler}
                childGraphHandler={childGraphHandler}
                uniqueDimensions={uniqueDimensions}
                uniqueMetrics={uniqueMetrics}
                reportIndex={reportIndex}
                currentDash={currentDash}
                dashConfig={dashConfig}
                mainAgg={mainAgg}
                childAgg1={childAgg1}
                childAgg2={childAgg2}
                childAgg3={childAgg3}
                childAgg4={childAgg4}
                childAgg5={childAgg5}
                childAgg6={childAgg6}
                panelAgg1={panelAgg1}
                panelAgg2={panelAgg2}
                panelAgg3={panelAgg3}
                panelAgg4={panelAgg4}
                panelAgg5={panelAgg5}
                panelAgg6={panelAgg6}
                currentTemplate={currentTemplate}
                updateState={updateState}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                tooltipArray={tooltipArray}
                currentMetrics={currentMetrics}
                />

            )
        }
        
        else { return null}
    }

    return (
        <View style={styles.container}>
            <DashSelect
                setCurrentDash={setCurrentDash}
                currentDash={currentDash}
                theme={theme}
                templateHandler={templateHandler}
                currentTemplate={currentTemplate}
                updateState={updateState}
                />
            {renderComponentContent()}

        </View>
    )

}

export default DashPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        fontFamily: 'Electrolize',
    }
})