import React, { useState, useEffect } from 'react';
import { View, StyleSheet, } from 'react-native';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway';
import postToApiGateway from '../../UserConfigs/ApiGateway';
import { LicenseInfo } from '@mui/x-license-pro';
import LoaderPercent from '../../Components/LoaderPercent';
import StudioEditStep2 from './StudioEditStep2';

const StudioEdit = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const setVisible = props.setVisible
    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const customReports = props.customReports
    const currentItem = props.currentItem
    const currentReport = customReports[currentItem]
    const currentTableBase = currentReport.table
    const setCurrentStepBase = props.setCurrentStep
    const setUpdate = props.setUpdate
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const patternAPI = configArray.patternAPI
    const [isLoading, setIsLoading] = useState(true)
    const [configResponse, setConfigResponse] = useState(null)
    const [currentStep, setCurrentStep] = useState(1)
    const [currentTable, setCurrentTable] = useState(currentTableBase)

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
            
        }
    };

    const event_data = {
        "request_type": "client_tables",
        "client": client_lower
    }

    useEffect(() => {
            requestData(event_data, patternAPI, support_key, support_id)
        }, [configArray])

    const savehandler = (event, reportName) => {
        let reportCopy;
        if (customReports.length === 0) {
            reportCopy = {[reportName]: event}
        } else {
            reportCopy = JSON.parse(JSON.stringify(customReports))
            reportCopy[reportName] = event
        }

        const saveEventData = {
            "request_type": "save_report",
            "client": client_lower,
            "client_id": configArray.clientMapId,
            "auth0_user_id": configArray.auth0_user_id,
            "report_data": reportCopy
        }


        requestData(saveEventData, patternAPI, support_key, support_id)
        setCurrentStepBase(0)
        setUpdate(saveEventData)
    }

    if (isLoading) {
        return (
            <LoaderPercent theme={theme} />
        );
        }
        
        if (!configResponse) {
        return (
            <LoaderPercent theme={theme} />
        );
        }

    const schemaData = configResponse.data.body

    return (


            <View
                style={{
                    flexDirection: 'column',
                    borderColor: 'red',
                    borderWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    


                }}>
                <View style={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    borderColor: theme.color,
                    backgroundColor: theme.backgroundColor,
                    borderWidth: 1,
                    //borderRadius: 15,
                    padding: '1%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <View style={{width: '100%', height:'100%', flexDirection: 'row'}}>

                    <StudioEditStep2
                        theme={theme}
                        configArray={configArray}
                        setVisible={setVisible}
                        setCurrentStep={setCurrentStep}
                        schemaData={schemaData}
                        setCurrentTable={setCurrentTable}
                        currentTable={currentTable}
                        currentMetrics={currentMetrics}
                        setCurrentStepBase={setCurrentStepBase}
                        savehandler={savehandler}
                        currentReport={currentReport}
                        currentItem={currentItem}
                    />

                    </View>

                </View>

            </View>

    )

}

export default StudioEdit;

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        //height: 50,
    },
});