import StudioLine from "./StudioLine"
import StudioBar from "./StudioBar"
import StudioMixed from "./StudioMixed"
import StudioTable from "./StudioTable"
import StudioMetric from "./StudioMetric"
import StudioSpace from "./StudioSpace"
import StudioPie from "./StudioPie"
import StudioScatter from "./StudioScatter"
import StudioCohorts from "./StudioCohorts"

const StudioChartsContainer = (props) => {

    const item = props.item
    const theme = props.theme
    const data = props.data
    const currentMetrics = props.currentMetrics
    const deleteHandler = props.deleteHandler
    const activeLayoutItem = props.activeLayoutItem
    const type = item.typeValue
    const xAxisValue = item.xAxisValue
    const metric1Value = item.metric1Value
    const metric2Value = item.metric2Value
    const currentFilters = item.currentFilters
    const metricList = item.metricList
    const dateAgg = item.dateAgg
    const isPreview = !props.currentStep;

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
      };
    
    const dateLabels = getUniqueValues(data, xAxisValue)

    if (type === 'line') {
        return (<StudioLine
            theme={theme}
            dateLabels={dateLabels}
            metric={metric1Value}
            timeFrame={dateAgg}
            data={data}
            currentMetrics={currentMetrics}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}
        />)
    } else if (type === 'bar') {
        return (<StudioBar
            theme={theme}
            labels={dateLabels}
            metric={metric1Value}
            timeFrame={dateAgg}
            data={data}
            currentMetrics={currentMetrics}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}
        />)
    } else if (type === 'mixed') {
        return (<StudioMixed
            theme={theme}
            labels={dateLabels}
            dateLabels={dateLabels}
            metric1={metric1Value}
            metric2={metric2Value}
            timeFrame={dateAgg}
            data={data}
            currentMetrics={currentMetrics}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}
        />)
    } else if (type === 'table') {
        return (<StudioTable
            theme={theme}
            currentMetrics={currentMetrics}
            dataProp={data}
            currentDim={xAxisValue}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}

        />)
    } else if (type === 'metric') {
        return (<StudioMetric
            theme={theme}
            currentMetrics={currentMetrics}
            data={data}
            currentDim={xAxisValue}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}

        />)
    } else if (type === 'space') {
        return (<StudioSpace
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            theme={theme}
            isPreview={isPreview}
        />)
    } else if (type === 'pie') {
        return (<StudioPie
            theme={theme}
            currentMetrics={currentMetrics}
            data={data}
            currentDim={xAxisValue}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            labels={dateLabels}
            metric={metric1Value}
            isPreview={isPreview}

        />)
    } else if (type === 'scatter') {
        return (<StudioScatter
            theme={theme}
            labels={dateLabels}
            dateLabels={dateLabels}
            metric1={metric1Value}
            metric2={metric2Value}
            timeFrame={dateAgg}
            data={data}
            currentMetrics={currentMetrics}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}
        />)
    } else if (type === 'cohorts') {
        return (<StudioCohorts
            theme={theme}
            labels={dateLabels}
            dateLabels={dateLabels}
            metric1={metric1Value}
            metric2={metric2Value}
            timeFrame={dateAgg}
            data={data}
            currentMetrics={currentMetrics}
            item={item}
            deleteHandler={deleteHandler}
            activeLayoutItem={activeLayoutItem}
            isPreview={isPreview}
        />)
    }

}

export default StudioChartsContainer