import moment from "moment";

export default function AttEnhancedTrendsParse(cubeResponse, xAxisValue, time, metric, dimension) {
    var dataset = cubeResponse;

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    var agg_result = [];

    dataset.reduce(function (res, value) {
        let axisValue;

        // Format the xAxisValue based on the time granularity
        if (xAxisValue === "date" && time === "daily") {
            axisValue = moment.tz(value[xAxisValue], "Europe/Paris").format("YYYY-MM-DD");
        } else if (xAxisValue === "date" && time === "monthly") {
            axisValue = moment.tz(value[xAxisValue], "Europe/Paris").format("YYYY-MM");
        } else if (xAxisValue === "date" && time === "weekly") {
            axisValue = moment.tz(value[xAxisValue], "Europe/Paris").startOf("isoWeek").format("YYYY-MM-DD");
        } else if (xAxisValue === "date" && time === "yearly") {
            axisValue = moment.tz(value[xAxisValue], "Europe/Paris").format("YYYY");
        } else {
            axisValue = value[xAxisValue];
        }

        const dim = dimension;
        const dimValue = value[dim];

        // Create a unique key combining xAxisValue and dimension
        const key = `${axisValue}-${dimValue}`;

        // Initialize the aggregation object if this key is not in res
        if (!res[key]) {
            res[key] = {
                [xAxisValue]: axisValue,
                [dim]: dimValue,
                [metric]: 0,
            };
            agg_result.push(res[key]);
        }

        // Aggregate the metric
        res[key][metric] += parseInt(value[metric]) || 0;
        return res;
    }, {});

    // Sort the results if xAxisValue is a date
    if (xAxisValue === "date") {
        agg_result.sort((a, b) => {
            const dateA = moment.tz(a[xAxisValue], "Europe/Paris");
            const dateB = moment.tz(b[xAxisValue], "Europe/Paris");
            return dateA - dateB;
        });
    }

    return agg_result;
}
