import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Dimensions } from 'react-native'

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const MenuProps = {
    MenuListProps: {
        disablePadding: true
    },
    PaperProps: {
        style: {
            maxWidth: 250,
            maxHeight: '85%'
        },

    },
};


export default function StudioControlMultiSelect(props) {

    const filterListBase = props.filterList    
    const setFilterList2 = props.setFilterList
    const currentValuesBase = props.currentValues
    const theme = props.theme
    const defaultMetrics = props.defaultMetrics
    const currentMetrics = props.currentMetrics

    const currentMetricHandler = (metric) => {
        if (metric === 'ftds') {
            return currentMetrics.primaryMetric
        } else if (metric === 'ftd') {
            return currentMetrics.primaryMetric
        } else if (metric === 'regs') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'reg') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'ngr') {
            return currentMetrics.primaryRevenue
        } else if (metric === 'ftd_ngr') {
            return currentMetrics.primaryRevenue
        } else {
            return metric
        }
    }

    function filterBaseList(strings, exclude) {
        return strings.filter(item => item !== exclude);
    }

    const filteredList = filterBaseList(filterListBase, "CREATE CALCULATED FIELD");
    let currentValues;
    if (currentValuesBase.length === 0) {
        currentValues = defaultMetrics.list
    } else {
        currentValues = currentValuesBase
    }
    const [filterList, setFilterList] = useState(currentValues)

    

    function handleClose(prop) {

        setFilterList2(filterList)
        
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setFilterList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        
        
    };

    return (
        <div >
            <FormControl sx={{ width: '100%',
                                height: '100%',
                                fontFamily : 'Electrolize' , 
                                color : theme.color,
                                borderWidth : 0.1,
                                borderColor: theme.backgroundColor2,
                                alignItems: 'center',
                                justifyContent: 'center' }} >

                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={filterList}
                    onChange={handleChange}
                    onClose={handleClose}
                    input={<OutlinedInput/>}
                    renderValue={(selected) => 
                        selected.map((val) => currentMetricHandler(val)).join(', ')
                    }
                    MenuProps={MenuProps}
                    style={{
                        width : '100%' ,
                        left : 0, 
                        fontFamily : 'Electrolize', 
                        color : theme.color , 
                        backgroundColor : theme.backgroundColor,
                        border: `1px solid ${theme.backgroundColor2}`,

                    }}


                >
                    {filteredList.map((name) => (
                        <MenuItem key={name} value={name} style={{
                            width : '100%',
                            fontFamily : 'Electrolize', 
                            color : theme.color , 
                            backgroundColor : theme.backgroundColor,
                            borderWidth : 1,
                            border: `1px solid grey`,
                        }}>
                            
                            <Checkbox
                                sx={{color: theme.color}}
                                checked={filterList.includes(name)} />
                            <ListItemText primary={currentMetricHandler(name)} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}