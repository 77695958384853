import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import AnalyticsPanelTable from '../Analytics/AnalyticsPanelTable';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway.js';
import postToApiGateway from '../UserConfigs/ApiGateway.js';
import LoadingComponent from '../Components/Loader.js';
import ParseAnalytics from './CubeFunctions/ParseAnalytics.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const AnalyticsPanelsTableV2 = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "AnalyticsPanelsTableV2";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_analytics_monthly";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const filter1name = 'brand'
    const filter2name = 'channel'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter1List,
        filter2List,
        setFilter1List,
        setFilter2List,
    }

    const filterBase = {
        start: "2024-10-01",
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: "2024-10-01",
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
        };

        setFilters(filterUpdate)

    };


    const cubeDims = [filter1name, filter2name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;

            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const filterSql = [dim1Value, dim2Value]

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "timestamp",
            "dimensions": cubeDims,
            "measures": [
                "pageviews",
                "new_users",
                "sessions",
                "reg_start",
                "regs",
                "ftds",
                "spend",
                "ngr",
                "cpa"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    useEffect(() => {
        requestData(cubeQuery, patternAPI, support_key, support_id)
    }, [configArray, filters])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (!configResponse) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = configResponse.data.body

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(cubeResponse, filter1name)
    const uniqueFilter2 = getUniqueValues(cubeResponse, filter2name)

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const agg_daily_result = ParseAnalytics(cubeResponse, 'brand');
    const agg_brand_result = ParseAnalytics(cubeResponse, 'brand');
    const agg_channel_result = ParseAnalytics(cubeResponse, 'channel');
    const agg_type_result = ParseAnalytics(cubeResponse, 'kw_type');

    const dateLabels = getUniqueValues(agg_daily_result, 'month');

    if (agg_daily_result.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const dataProp = {
        agg_daily_result,
        agg_brand_result,
        agg_channel_result,
        agg_type_result,
        dateLabels
    }

    const dataPropFilter = {
        aggResult : agg_daily_result,
        uniqueFilter1,
        uniqueFilter2,
    }

    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <AnalyticsPanelTable
                    style={{ flex: 1 }}
                    theme={theme}
                    dataProp={dataProp}
                    dateLabels={dateLabels}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    setHomeTitle={homeTitle}
                    timeFrame={"Monthly"}
                    filterParams={filterParams}
                    dataPropFilter={dataPropFilter}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                />
            </View>
        </View>

    )

}

export default AnalyticsPanelsTableV2

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})