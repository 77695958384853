import React, { useState } from 'react';
import { View, 
    StyleSheet,
    Dimensions
} from 'react-native'
import OptionsGroupingHomeModal from './OptionsGroupingHome/OptionsGroupingHomeModal';
import OptionsGroupingDefinitions from './OptionsGroupingDefinitions/OptionsGroupingDefinitions';
import OptionsMetrics from '../OptionsMetrics/OptionsMetrics';

const windowHeight = Dimensions.get('window').height;

const OptionsGroupingPanels = (props) => {

    const theme = props.theme
    const updateArray = props.updateArray
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const cubeTable = props.cubeTable
    
    const [currentView, setCurrentView] = useState('Home')
    const homeopen = currentView === 'Home'


    const renderComponentContent = () => {
        if (currentView === 'Home') {
            return null;
        } else if (currentView === 'Dimensions') {
            return <OptionsGroupingDefinitions
                theme={theme}
                configArray={configArray}
                updateArray={updateArray}
                currentMetrics={currentMetrics}
                cubeTable={cubeTable}
                setCurrentView={setCurrentView}
            />;
        } else if (currentView === 'Metrics') {
            return <OptionsMetrics
                theme={theme}
                configArray={configArray}
                updateArray={updateArray}
                currentMetrics={currentMetrics}
                cubeTable={cubeTable}
                setCurrentView={setCurrentView}
            />;
        } else {
            return null;
        }
    };


    return (
        <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
            <OptionsGroupingHomeModal
            open={homeopen}
            setCurrentView={setCurrentView}
            theme={theme}
            />
            {renderComponentContent()}

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: windowHeight * 0.93
        //backgroundColor: 'white'
    }
})

export default OptionsGroupingPanels