import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import {
    View,
    Text
} from 'react-native'
import DialogActions from '@mui/material/DialogActions';

export default function StudioDelete(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const deleteHandler = props.deleteHandler
    const currentItem = props.currentItem

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const saveHandler = () => {
        deleteHandler(currentItem)
        setOpen(false)
    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                //maxWidth={"true"}

            >
                <View style={{ width: '100%'}}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize',color: theme.color, backgroundColor: theme.backgroundColor  }}>Are You Sure You Want To Delete Report?</DialogTitle>
                    
                </View>
                <View style={{ width: '100%', backgroundColor: theme.backgroundColor, alignItems: 'center', justifyContent: 'center'}}>
                    <DialogContentText style={{color: theme.color, fontFamily: 'Electrolize', fontWeight: 200, backgroundColor: theme.backgroundColor}}>
                        {currentItem}
                    </DialogContentText>
                </View>
                <DialogActions sx={{backgroundColor: theme.backgroundColor }} >
                    <Button onClick={handleClose} style={{color: theme.color, fontFamily: 'Electrolize', fontWeight: 200}} >Cancel</Button>
                    <Button onClick={saveHandler} style={{color: 'red', fontFamily: 'Electrolize', fontWeight: 600}}>Delete</Button>
                </DialogActions>

            </Dialog>
        </div>

    );
}