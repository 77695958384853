import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway.js';
import postToApiGateway from '../../UserConfigs/ApiGateway.js';
import LoadingComponent from '../../Components/Loader.js';
import AnalyticsCWVPanels from './AnalyticsCWVPanels.js';
import NoDataContainer from '../../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const AnalyticsCWVPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [timeResponse, setTimeResponse] = useState(null)
    const [pageResponse, setPageResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "AnalyticsCWV";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    let clientAdj;
    if (client === "test") {
        clientAdj = "rank"
    } else {
        clientAdj = client
    }
    const cubeTable = clientAdj + "_cwv";
    const reportDimBase = props.reportDim
    const currentMetrics = props.currentMetrics
    const currentConfigBase = {
        dim: reportDimBase,
        type: 'Total'
    }
    const reportDim = currentConfigBase.dim
    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    const filter1name = 'brand'
    const filter2name = 'content_category'
    const filter3name = 'event_name'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : [],
      }
    const [filterList, setFilterList] = useState(filterValueBase);
    

    const filterParams = {
        filter1name,
        filter1List,
        setFilter1List,
        filter2name,
        filter2List,
        setFilter2List,
        filter3name,
        filter3List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: "Total",
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: 'Total',
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
        };

        setFilters(filterUpdate)

    };

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const dim4Value = 'brand != \"localhost\"'
    const filterSql = [dim1Value, dim2Value, dim3Value, dim4Value]

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": ['brand', 'event_name', 'cwv_rating'],
          "measures": [
            "page_views",
            "sessions",
            "new_users",
            "cwv_value",
          ],
          "custom_dimensions": ["avg(cwv_value) AS cwv_avg", "count(*) as count"],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

    const timeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": ['date', 'cwv_rating'],
          "measures": [
            "cwv_value",
            "page_views",
          ],
          "custom_dimensions": ["avg(cwv_value) AS cwv_avg", "count(*) as count"],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_time`
        }
    }

    const pageQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": ['page_location', 'content_category'],
          "measures": [
            "page_views",
            "cwv_value"
            
          ],
          "custom_dimensions": ["avg(cwv_value) AS cwv_avg", "count(*) as count"],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_page`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestTimeData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setTimeResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
            setTimeResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

      const requestPageData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setPageResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
            setPageResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestData(cubeQuery, patternAPI, support_key, support_id)
        requestTimeData(timeQuery, patternAPI, support_key, support_id)
        requestPageData(pageQuery, patternAPI, support_key, support_id)
      }, [configArray, filters])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!timeResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!pageResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const updateArray = configResponse.data.body
    const timeArray = timeResponse.data.body
    const pageArray = pageResponse.data.body

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(updateArray, filter1name)
    const uniqueFilter2 = getUniqueValues(pageArray, filter2name)
    const uniqueFilter3 = getUniqueValues(updateArray, filter3name)

    if (updateArray.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    return (
        <View style={styles.container}>
            <AnalyticsCWVPanels
            theme={theme}
            dataProp={updateArray}
            timeArray={timeArray}
            pageArray={pageArray}
            handleStateChange={handleStateChange}
            propObject={propObject}
            filterParams={filterParams}
            filterList={filterList}
            setFilterList={setFilterList}
            currentMetrics={currentMetrics}
            uniqueFilter1={uniqueFilter1}
            uniqueFilter2={uniqueFilter2}
            uniqueFilter3={uniqueFilter3}
            />
        </View>

    )

}

export default AnalyticsCWVPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})