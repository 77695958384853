import React, { useState } from 'react';
import { View, StyleSheet, } from 'react-native';
import { LicenseInfo } from '@mui/x-license-pro';
import StudioPreviewPanel from './StudioPreviewPanel';

const StudioPreview = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const setVisible = props.setVisible
    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const customReports = props.customReports
    const currentItem = props.currentItem
    const currentReportBase = customReports[currentItem]
    const [currentReport, setCurrentReport] = useState(currentReportBase)
    const reportlist = Object.keys(customReports)
    const currentTableBase = currentReport.table
    const setCurrentStep = props.setCurrentStep
    const schemaData = props.schemaData
    const currentStep = props.currentStep


    return (


            <View
                style={{
                    flexDirection: 'column',
                    borderColor: 'red',
                    borderWidth: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    


                }}>
                <View style={{
                    margin: 0,
                    height: '100%',
                    width: '100%',
                    borderColor: theme.color,
                    backgroundColor: theme.backgroundColor,
                    borderWidth: 1,
                    //borderRadius: 15,
                    padding: '0.5%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <View style={{width: '100%', height:'100%', flexDirection: 'row'}}>

                    <StudioPreviewPanel
                        theme={theme}
                        configArray={configArray}
                        setVisible={setVisible}
                        currentTable={currentTableBase}
                        currentMetrics={currentMetrics}
                        currentReport={currentReport}
                        currentItem={currentItem}
                        setCurrentStep={setCurrentStep}
                        setCurrentReport={setCurrentReport}
                        reportList={reportlist}
                        schemaData={schemaData}
                        currentStep={currentStep}
                    />

                    </View>

                </View>

            </View>

    )

}

export default StudioPreview;

const styles = StyleSheet.create({
    containerStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        //height: 50,
    },
});