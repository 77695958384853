import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader'
import moment from 'moment-timezone';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway.js';
import postToApiGateway from '../UserConfigs/ApiGateway.js';
import ProgPanels from '../Programmatic/ProgPanels';
import NoDataContainer from '../Components/NoDataContainer.js';

const ProgrammaticBanner = (props) => {

  const configArray = props.configArray
  const support_id = configArray.support_id
  const support_key = configArray.support_key
  const client_upper = configArray.clientID
  const client_lower = client_upper.toLowerCase()
  const client_id = configArray.clientMapId
  const auth0_user_id = configArray.auth0_user_id
  const patternAPI = configArray.patternAPI
  const [configResponse, setConfigResponse] = useState(null)
  const [dimResponse, setDimResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientUpper = configArray.clientID
  const client = clientUpper.toLowerCase();
  const cubeTable = client + "_banners"
  const theme = props.theme
  const homeTitle = "Display - Banners";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const chartType = "banner"

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  const filter1name = 'brand'
  const filter2name = 'channel'
  const filter3name = 'campaign'

  const [filter1List, setFilter1List] = useState('Total')
  const [filter2List, setFilter2List] = useState('Total')
  const [filter3List, setFilter3List] = useState('Total')

  const filterValueBase = {
    filter1List: [],
    filter2List: [],
    filter3List: []
  }
  const [filterList, setFilterList] = useState(filterValueBase);

  const filterParams = {
    filter1name,
    filter2name,
    filter3name,
    filter1List,
    filter2List,
    filter3List,
    setFilter1List,
    setFilter2List,
    setFilter3List,
  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    [filter1name]: "Total",
    [filter2name]: 'Total',
    [filter3name]: "Total",
  };


  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }



  const [filters, setFilters] = useState(filterBase);

  const filterStartDate = filters.start
  const filterEndDate = filters.end

  let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }

  let filter3Filter, filter3Operator;

  if (filters[filter3name] === 'Total') {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else if (filters[filter3name].length === 0) {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else if (filters[filter3name][0] === 'Total') {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else {
    filter3Filter = filter3List
    filter3Operator = ['equals']
  }


  const propObjectBase = {
    data: [],
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    [filter1name]: "Total",
    [filter2name]: 'Total',
    [filter3name]: "Total",
  };


  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {
    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      [filter1name]: prop[filter1name],
      [filter2name]: prop[filter2name],
      [filter3name]: prop[filter3name],
    };

    setFilters(filterUpdate)

  };

  const cubeDims = ['banner']
  const dimList = [filter1name, filter2name, 'campaign']

  function convertToSQLCondition(dim, list) {
    if (list === 'Total' || list.includes('null')) {
      return `${dim} is not null`;
    } else {
      // Escape double quotes with a single backslash
      const escapedItems = list.map(item => `\"${item}\"`);
      const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;

      // Return the raw string, ensuring it is not further escaped
      return sqlCondition;
    }
  }

  const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
  const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
  const dim3Value = convertToSQLCondition('campaign', filter3Filter)
  const filterSql = [dim1Value, dim2Value, dim3Value]

  function formatString(input) {
    return input.toLowerCase().replace(/ /g, "_");
}

  const cubeQuery = {

    "request_type": "query",
    "query": {
      "start": filterStartDate,
      "end": filterEndDate,
      "table": cubeTable,
      "date_dim": "date",
      "dimensions": cubeDims,
      "measures": [
        "impressions",
        "clicks",
        "cost",
        "regs",
        "ftds",
        "ngr"
      ],
      "custom_dimensions": [],
      "custom_dimensions_aliases": [],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": filterSql,
      "client_id": client_id,
      "auth0_user_id": auth0_user_id,
      "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
    }
  }

  const dimQuery = {

    "request_type": "query",
    "query": {
      "start": filterStartDate,
      "end": filterEndDate,
      "table": cubeTable,
      "date_dim": "date",
      "dimensions": dimList,
      "measures": [
        "impressions"
      ],
      "custom_dimensions": [],
      "custom_dimensions_aliases": [],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": filterSql,
      "client_id": client_id,
      "auth0_user_id": auth0_user_id,
      "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
    }
  }

  const requestData = async (event_data, support_url, support_key, support_id) => {
    setIsLoading(true)
    setConfigResponse(null); // Clear previous response
    const requestType = event_data.request_type
    const endpointStage = configArray.testingState
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    try {
      // First, get the signed URL
      const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

      // Send the POST request to the signed URL
      const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
      setConfigResponse(result)
      setIsLoading(false)
    } catch (error) {
      console.error("Error posting to Lambda:", error);
    } finally {
      //setSaveLoading(false);
    }
  };

  const requestDimData = async (event_data, support_url, support_key, support_id) => {
    setIsLoading(true)
    setDimResponse(null); // Clear previous response
    const requestType = event_data.request_type
    const endpointStage = configArray.testingState
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    try {
      // First, get the signed URL
      const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

      // Send the POST request to the signed URL
      const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
      setDimResponse(result)
      setIsLoading(false)
    } catch (error) {
      console.error("Error posting to Lambda:", error);
    } finally {
      //setSaveLoading(false);
    }
  };

  useEffect(() => {
    requestData(cubeQuery, patternAPI, support_key, support_id)
    requestDimData(dimQuery, patternAPI, support_key, support_id)
  }, [configArray, filters])

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    impressions: true,
    clicks: false,
    ctr: true,
    spend: true,
    regs: true,
    ftds: true,
    ngr: true,
    cpa: true,
  });

  if (isLoading) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!configResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!dimResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeResponse = configResponse.data.body
  const dimCubeResponse = dimResponse.data.body

  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };
  
  const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
  const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)
  const uniqueFilter3 = getUniqueValues(dimCubeResponse, filter3name)
  
  const lastUpdated = 'loading...'
  
  if (cubeResponse.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
  }

  const dataProp = {
    aggResult: cubeResponse,
    uniqueFilter1,
    uniqueFilter2,
    uniqueFilter3,
  }

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientUpper}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientUpper}
      />
      <ProgPanels
        theme={theme}
        dataProp={dataProp}
        propObject={propObject}
        handleStateChange={handleStateChange}
        filterParams={filterParams}
        dataPropProg={dataProp}
        filters={filters}
        cubeTable={cubeTable}
        chartType={chartType}
        cubeResponse={cubeResponse}
        cubeDim={'banner'}
        setFilterList={setFilterList}
        filterList={filterList}
        currentMetrics={currentMetrics}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      />

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default ProgrammaticBanner