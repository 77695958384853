import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import OptionsGroupingPanels from './OptionsGroupingPanels.js';

const windowHeight = Dimensions.get('window').height;

const OptionsGroupingPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const configArray = props.configArray
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayPlus2 = yesterday2.clone().add(0, 'hour');
    const yesterdayProp = yesterdayPlus2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup_enhanced";

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <OptionsGroupingPanels
                    theme={theme}
                    //updateArray={updateArray}
                    currentMetrics={currentMetrics}
                    configArray={configArray}
                    cubeTable={cubeTable}
                />

            </View>
        </View>

    )

}

export default OptionsGroupingPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})