export default function CohortDepositRunning(data, cubeTable) {

    const updateArray = data.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const agg_cohorts = updateArray.map((obj) => {
        const cost = parseFloat(obj.cost)
        const ngr = parseFloat(obj.ngr)
        const ftds = parseFloat(obj.ftds)
        const deposit_amount = parseFloat(obj.deposit_amount)

        return {
            cohort_month: obj.cohort_month,
            months_from_ftd: obj.monthsfromftd,
            ngr: ngr,
            cost: cost,
            ftds: ftds,
            deposit_amount: deposit_amount
        };
    });

    const aggregationResult = {};

    // Iterate over the JSON data and aggregate values based on "cohort_month" and "months_since_ftd" dimensions
    agg_cohorts.forEach((item) => {
        const { cohort_month, months_from_ftd, ngr, cost, ftds, deposit_amount } = item;
        const key = `${cohort_month}_${months_from_ftd}`;

        if (!aggregationResult[key]) {
            aggregationResult[key] = {
                cohort_month,
                months_from_ftd,
                ngr: 0,
                cost: 0,
                ftds: 0,
                deposit_amount: 0
            };
        }

        aggregationResult[key].ngr += parseInt(ngr);
        aggregationResult[key].cost += parseInt(cost);
        aggregationResult[key].ftds += parseInt(ftds);
        aggregationResult[key].deposit_amount += parseInt(deposit_amount);
    });

    // Convert the aggregation result object to an array
    const aggregatedData = Object.values(aggregationResult);

    aggregatedData.sort((a, b) => {
        if (a.cohort_month === b.cohort_month) {
            return a.months_from_ftd - b.months_from_ftd;
        }
        return a.cohort_month.localeCompare(b.cohort_month);
    });

    // Calculate running values within each cohort_month
    let running_ngr = 0;
    let running_cost = 0;
    let running_roas = 0;
    let running_ftds = 0;
    let running_arpu = 0;
    let running_deposits = 0;
    let running_dep_roas = 0
    const result = aggregatedData.map((row, index, array) => {
        if (index === 0 || row.cohort_month !== array[index - 1].cohort_month) {
            running_ngr = 0;
            running_cost = 0;
            running_ftds = 0;
            running_deposits = 0;
        }
        running_ngr += row.ngr;
        running_cost += row.cost;
        running_ftds += row.ftds;
        running_deposits += row.deposit_amount * 0.6;
        

        // Check if running_cost is zero and set a default value for ROAS
        running_roas = running_cost === 0 ? 0 : (parseFloat((running_ngr / running_cost)) * 100).toFixed(0);
        running_arpu = running_ngr === 0 ? 0 : (parseFloat((running_ngr / running_ftds)) ).toFixed(0);
        running_dep_roas = running_cost === 0 ? 0 : (parseFloat(((running_deposits) / running_cost)) * 100).toFixed(0);
        

        return { ...row, running_ngr, running_cost, running_roas, running_ftds, running_arpu, running_dep_roas, running_deposits };
    });

    const filteredData = result.map(({ cost, cohort_month, months_from_ftd, ngr, running_ngr, running_roas, ftds, running_ftds, running_arpu, running_dep_roas, running_deposits }) => ({
        cohort_month,
        months_from_ftd,
        ngr,
        running_ngr,
        running_roas,
        cost,
        ftds,
        running_ftds,
        running_arpu,
        running_dep_roas,
        running_deposits
    }));

    return filteredData

}