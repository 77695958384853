import React from 'react';
import { View , 
    StyleSheet
} from 'react-native'
import AnalyticsCWVParseTime from './AnalyticsCWVParseTime';
import FiltersEnhancedSolo from '../../ComponentsEnhanced/FiltersEnhancedSolo';
import PieChart from '../../ChartsDesktop/PieChart';
import LineChart from '../../ChartsDesktop/LineChart';
import BarChartStackedCwv from '../../ChartsDesktop/BarChartStackedCwv';
import BarChart from '../../ChartsDesktop/BarChart';
import AnalyticsCWVDataGrid from './AnalyticsCWVDataGrid';

const AnalyticsCWVPanels = (props) => {

    const theme = props.theme
    const typeArray = props.dataProp
    const timeArray = props.timeArray
    const pageArray = props.pageArray
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const currentMetrics = props.currentMetrics
    const uniqueFilter1 = props.uniqueFilter1
    const uniqueFilter2 = props.uniqueFilter2
    const uniqueFilter3 = props.uniqueFilter3

    const leftPanel = {
        height: '100%',
        width: "60%",
        flexDirection: 'column'
    }

    const rightPanel = {
        height: '100%',
        width: "40%",
        borderColor: 'red',
        borderWidth: 0,
        flexDirection: 'column'
    }

    const innerLeftRow = {
        height: '50%',
        width: '100%',
        flexDirection: 'row',
        borderColor: 'red',
        borderWidth: 0,
        flexDirection: 'row',
        //padding: '0.5%',
        
    }

    const innerLeftChildRow = {
        width: '50%',
        height: '100%',
        borderColor: '#dddddd',
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        
        borderRadius: 2,
        //margin: '0.5%'

    }

    const innerRightTop = {
        width: '100%',
        height: "50%",
        borderColor: 'grey',
        borderWidth: 0,
    }

    const innerRightBottom = {
        width: '100%',
        height: "50%"
    }

    const parsedOutputs = AnalyticsCWVParseTime(timeArray, typeArray, pageArray)
    const dateArray = parsedOutputs.dateArray
    const goodArray = parsedOutputs.goodArray
    const poorArray = parsedOutputs.poorArray
    const improvementArray = parsedOutputs.improvementArray
    const pageArrays = parsedOutputs.pageArrays
    const ratingArray = parsedOutputs.ratingArray
    const ratingLabels = ratingArray.labels
    const ratingValues = ratingArray.values.map(value => (parseFloat(value.toFixed(2)) * 100));
    const dateLabels = parsedOutputs.dateLabels
    const eventArray = parsedOutputs.eventArray
    const eventLabels = parsedOutputs.eventLabels
    const contentArray = parsedOutputs.contentArray

    return (
        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <FiltersEnhancedSolo
                    style={{
                        height: '10%',
                        borderColor: 'yellow',
                        borderWidth: 0,
                        width: '100%',
                        fontFamily: 'Electrolize',
                        
                    }}
                    uniqueFilter1={uniqueFilter1}
                    uniqueFilter2={uniqueFilter2}
                    uniqueFilter3={uniqueFilter3}
                    handleStateChange={handleStateChange}
                    propObject={propObject}
                    filterParams={filterParams}
                    theme={theme}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    large={1}
                    singleModel={true}
                    filtertext={'Brand'}
                />

            </View>
            <View style={{
                        height: '90%',
                        width: '100%',
                        borderColor: 'yellow',
                        borderWidth: 0,
                        flexDirection: 'row'
                        }}>
                <View style={leftPanel}>
                    <View style={innerLeftRow}>
                        <View style={innerLeftChildRow}>
                            <PieChart
                                theme={theme}
                                dataProp={ratingValues}
                                labels={ratingLabels}
                                />

                        </View>
                        <View style={innerLeftChildRow}>
                            <LineChart
                                theme={theme}
                                agg_daily_result={dateArray}
                                dateLabels={dateLabels}
                                timeFrame={'daily'}
                                currentMetrics={currentMetrics}
                                metric={'cwv_avg'}
                                large={false}
                                />                            
                        </View>

                    </View>
                    <View style={innerLeftRow}>
                        <View style={innerLeftChildRow}>
                                <BarChartStackedCwv
                                    theme={theme}
                                    agg_daily_result={timeArray}
                                    dateLabels={dateLabels}
                                    metric={'page_views_percent'}
                                    currentMetrics={currentMetrics}
                                    goodArray={goodArray}
                                    poorArray={poorArray}
                                    improvementArray={improvementArray}
                                    />
                            
                        </View>
                        <View style={innerLeftChildRow}>
                            <BarChart
                                theme={theme}
                                dataProp={eventArray}
                                labels={eventLabels}
                                currentMetrics={currentMetrics}
                                metric={'cwv_avg'}
                            />
                            
                        </View>
                        
                    </View>

                </View>
                <View style={rightPanel}>
                    <View style={innerRightTop}>
                        <AnalyticsCWVDataGrid
                            theme={theme}
                            currentMetrics={currentMetrics}
                            dataProp={pageArrays}
                            />

                    </View>
                    <View style={innerRightBottom}>
                        <AnalyticsCWVDataGrid
                            theme={theme}
                            currentMetrics={currentMetrics}
                            dataProp={contentArray}
                            />

                        
                    </View>

                </View>

            </View>

        </View>
    )

}

export default AnalyticsCWVPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : '100%',
      width : '100%',
      //minHeight: windowHeight * 0.93,
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      padding: '0%',
    },
    filterContainer: {
        height: '10%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
    },
    bodyContainer: {
        height: '0%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'yellow',

    }
})