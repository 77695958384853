import React, { useEffect, useState } from 'react';
import { View, Pressable } from 'react-native'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const StudioSpace = (props) => {

    const theme = props.theme
    const deleteHandler = props.deleteHandler
    const activeLayoutItem = props.activeLayoutItem
    const isPreview = props.isPreview

    const [contextMenu, setContextMenu] = useState(null);
    
      const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            :
              null,
        );
      };
    
      const handleClose = () => {
        setContextMenu(null);
      };

        return (
            <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%'}}>
            <View style={{width: '100%', height: '100%', backgroundColor: theme.backgroundColor}}>

            </View>
            <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {isPreview && <Pressable onPress={() => deleteHandler(activeLayoutItem)}>
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: 'white'}} 
            //onClick={downloadHandler}
            //onClick={() => clickHandler(activeLayoutItem)}
            >
              Delete Widget
          </MenuItem>
          </Pressable>}
        </Menu>
            </div>
        )
    }

export default StudioSpace