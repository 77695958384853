import { View, Text, StyleSheet, Dimensions } from 'react-native'
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const OptionsGroupingNoData = (props) => {
    const theme = props.theme

    const iconStyle = {
        fontSize : windowWidth * 0.1,
        color: theme.panelBackground
    }

    const textStyle = {
        fontSize : windowWidth * 0.02,
        color: theme.panelBackground,
        fontFamily: 'Electrolize',
    }

    return (
        <View style={styles.container}>
            <SyncProblemIcon
                style={iconStyle}
            />
            <Text style={textStyle}>
            No Custom Definitions Present
            </Text>
            <Text style={textStyle}>
            Please define then return to preview
            </Text>

        </View>
    )

}

export default OptionsGroupingNoData

const styles = StyleSheet.create({
    container: {
        borderWidth: 2,
        borderColor: 'grey',
        height: windowHeight * 0.5,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        alignItems: 'center',
        justifyContent: 'center'
    },

})