import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Pressable,
    Dimensions
} from 'react-native'
import TuneIcon from '@mui/icons-material/Tune';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Tooltip from '@mui/material/Tooltip';

import StudioPreviewFiltersMulti from './StudioPreviewFiltersMulti';
import StudioPreviewDate from './StudioPreviewDate';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const StudioPreviewFilters = (props) => {

    const uniqueFilter1 = props.uniqueFilter1
    const uniqueFilter2 = props.uniqueFilter2
    const uniqueFilter3 = props.uniqueFilter3
    const isCumulative = props.isCumulative
    const setIsCumulative = props.setIsCumulative
    const largeProp = props.large
    const theme = props.theme
    const setOpen = props.setOpen
    const filter1name = props.filterType1
    const filter2name = props.filterType2
    const filter3name = props.filterType3
    const filter1 = props.filter1
    const filter2 = props.filter2
    const filter3 = props.filter3
    const setFilter1List = props.setFilter1List
    const setFilter2List = props.setFilter2List
    const setFilter3List = props.setFilter3List
    const dateRange = props.dateRange
    const setDateRange = props.setDateRange


    const [cumuState, setCumuState] = useState(false)

    const settingStyle = {
        //position: 'absolute',
        //right: '0.6%',
        //top: '105%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
        borderWidth: 0,
        borderColor: 'red',
    }

    const cumuIconStyle = {
        color: isCumulative ? theme.backgroundColor2 : theme.color,
        fontSize: windowWidth * 0.015
    }
    const iconStyle = {
        color: theme.color,
        fontSize: windowWidth * 0.015
    }

    const toggleCumulative = () => {
        setIsCumulative((prevState) => !prevState);
    };

    return (


        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <StudioPreviewFiltersMulti
                    listValues={uniqueFilter1}
                    type={filter1name}
                    setFilterList={setFilter1List}
                    filterList={filter1}
                    theme={theme}
                />

            </View>
            <View style={styles.filterContainer}>
            <StudioPreviewFiltersMulti
                    listValues={uniqueFilter2}
                    type={filter2name}
                    setFilterList={setFilter2List}
                    filterList={filter2}
                    theme={theme}
                />
            </View>
            <View style={styles.filterContainer}>
            <StudioPreviewFiltersMulti
                    listValues={uniqueFilter3}
                    type={filter3name}
                    setFilterList={setFilter3List}
                    filterList={filter3}
                    theme={theme}
                />
            </View>


            <View style={styles.dateFilterContainer}>
                <StudioPreviewDate
                    theme={theme}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                />

            </View>
            <View style={styles.controlContainer}>
                <View style={styles.controlItemContainer}>
                    <Tooltip
                        title={"Cumulative"}

                        componentsProps={{
                            arrow: { sx: { color: theme.backgroundColor2, } },
                            tooltip: {
                                sx: {
                                    bgcolor: theme.backgroundColor2,
                                    color: "white",
                                    fontFamily: 'Electrolize',
                                }
                            },
                        }}
                        arrow
                    >
                        <Pressable style={settingStyle} onPress={toggleCumulative}>
                            <ShowChartIcon style={cumuIconStyle} />
                        </Pressable>
                    </Tooltip>

                </View>
                <View style={styles.controlItemContainer}>
                    <Tooltip
                        title={"Adjust Report"}

                        componentsProps={{
                            arrow: { sx: { color: theme.backgroundColor2, } },
                            tooltip: {
                                sx: {
                                    bgcolor: theme.backgroundColor2,
                                    color: "white",
                                    fontFamily: 'Electrolize',
                                }
                            },
                        }}
                        arrow
                    >
                        <Pressable style={settingStyle} onPress={() => setOpen(true)}>
                            <TuneIcon style={iconStyle} />
                        </Pressable>
                    </Tooltip>

                </View>

            </View>

        </View>


    )
}

export default StudioPreviewFilters

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        height: windowHeight * 0.06,
        padding: '0.5%',
        marginTop: '0%'

    },
    filterContainer: {
        width: '23%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'blue',
        //paddingLeft: '0.5%',
        //padding: '0.5%'
    },
    dateFilterContainer: {

        borderWidth: 0,
        borderColor: 'red',
        height: '100%',
        width: '23%',
        //paddingLeft: '0.5%',
        padding: '0.5%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    controlContainer: {
        width: '8%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'row',

    },
    controlItemContainer: {
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'yellow',
        borderWidth: 0,
    }
})