import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import moment from 'moment';
import OptionsMetricsAvailable from './OptionsMetricsAvailable';
import { postToSignedUrl } from '../../UserConfigs/AwsSigner';
import signRequest from '../../UserConfigs/AwsSigner';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import LoopIcon from '@mui/icons-material/Loop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default function OptionsMetricEdit(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const child = props.child
    const index = props.index
    const cubeTable = props.cubeTable
    const updateHandler = props.updateHandler
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const [expressionValue, setExpressionValue] = useState(child.value)
    const [functionValue, setFunctionValue] = useState(child.function)
    const [formatValue, setFormatValue] = useState(child.format)
    const [nameValue, setNameValue] = useState(child.name)
    const [isLoading, setIsLoading] = useState(false)
    const [metricsOpen, setMetricsOpen] = useState(false)
    const [validateResponse, setValidateResponse] = useState(null)
    const functionOptions = ['sum', 'avg', 'max', 'min']
    const formatOptions = ['integer', 'float', 'currency']

    const newChild = {
        "name": nameValue,
        "value": expressionValue,
        "format": formatValue,
        "function": functionValue
    }

    const prevChildRef = useRef(null); // Ref to store the previous state
    const isSame = JSON.stringify(newChild) === JSON.stringify(prevChildRef.current)

    // useEffect to track changes in newChild
    useEffect(() => {
        prevChildRef.current = newChild;
    }, [newChild]);

    let permittedState = false;
    let validateState = false;

    const hasDisallowed = nameValue === "" || /[^a-zA-Z0-9_]/.test(nameValue) || /\b(SELECT|INSERT|UPDATE|DELETE|MERGE|DROP|ALTER|CREATE|EXEC|EXECUTE|TRUNCATE|GRANT|REVOKE|UNION|INTERSECT|EXCEPT|HAVING|WHERE|FROM|JOIN|INNER|OUTER|LEFT|RIGHT|ORDER|GROUP|BY|INTO|VALUES|SET)\b/i.test(expressionValue);
    const hasSpaces = nameValue === "" || /[^a-zA-Z0-9_]/.test(nameValue)
    const hasSqlKw = /\b(SELECT|INSERT|UPDATE|DELETE|MERGE|DROP|ALTER|CREATE|EXEC|EXECUTE|TRUNCATE|GRANT|REVOKE|UNION|INTERSECT|EXCEPT|HAVING|WHERE|FROM|JOIN|INNER|OUTER|LEFT|RIGHT|ORDER|GROUP|BY|INTO|VALUES|SET)\b/i.test(expressionValue);

    if (hasDisallowed) {
        permittedState = true
        validateState = true
    } else if (!isSame) {
        permittedState = true
        validateState = false
    } else if (isSame && !validateResponse) {
        permittedState = true
        validateState = false
    } else if (!isSame && !validateResponse) {
        permittedState = true
        validateState = false
    } else if (isSame && validateResponse.data.status_code === 200) {
        permittedState = false
        validateState = false
    } else if (isSame && validateResponse.data.status_code !== 200) {
        permittedState = true
        validateState = false
    }

    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayPlus2 = yesterday2.clone().add(0, 'hour');
    const yesterdayProp = yesterdayPlus2.format('YYYY-MM-DD');

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    const sqlPreview = `${functionValue} ( ${expressionValue} )   as   ${nameValue}`

    function adjustExpressionValue(expressionValue, currentMetrics) {
        const replacements = {
            [currentMetrics.primaryMetricString]: 'ftds',
            [currentMetrics.secondaryMetricString]: 'regs',
            [currentMetrics.primaryRevenueString]: 'ftd_ngr',
            [currentMetrics.primaryMetric]: 'ftds',
            [currentMetrics.secondaryMetric]: 'regs',
            [currentMetrics.primaryRevenue]: 'ftd_ngr'
        };

        Object.keys(replacements).forEach(key => {
            if (expressionValue.includes(key)) {
                expressionValue = expressionValue.replace(new RegExp(key, 'g'), replacements[key]);
            }
        });

        return expressionValue;
    }

    const currentMetricsValues = {
        primaryMetricString: currentMetrics.primaryMetricString,
        secondaryMetricString: currentMetrics.secondaryMetricString,
        primaryRevenueString: currentMetrics.primaryRevenueString,
        primaryMetric: currentMetrics.primaryMetric,
        secondaryMetric: currentMetrics.secondaryMetric,
        primaryRevenue: currentMetrics.primaryRevenue
    };

    const sqlValueFix = adjustExpressionValue(expressionValue, currentMetricsValues);

    const sqlMetric = `${functionValue} ( ${sqlValueFix} )`

    const dryRunQuery = {

        "request_type": "dry_run_query",
        "query": {
            "start": startDateString,
            "end": yesterdayProp,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": [],
            "measures": [
                "impressions"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [sqlMetric],
            "custom_measures_aliases": [nameValue],
            "filters": []
        }
    }

    const requestValidateData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setValidateResponse(null); // Clear previous response

        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setValidateResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Host:", error);
        } finally {
        }
    };

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const ColorButtonPrimary = styled(Button)(({ theme }) => ({
        color: 'white',
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const validateHandler = () => {
        requestValidateData(dryRunQuery, config_url, support_key, support_id, 'POST')
    }


    const saveHandler = () => {
        updateHandler(newChild, index)

    }


    const textResponse = () => {

        if (hasSpaces) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'red', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    Please remove any spaces or special characters in the name.
                </Text>
            )
        } else if (hasSqlKw) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'red', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    SQL/DML keywords are not allowed
                </Text>
            )
        } else if (!isSame) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'orange', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    Please validate your metric.
                </Text>
            )
        } else if (isSame && !validateResponse) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'orange', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    Please validate your metric.
                </Text>
            )
        } else if (!isSame && !validateResponse) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'orange', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    Please validate your metric.
                </Text>
            )
        } else if (isSame && validateResponse.data.status_code === 200) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'green', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    {validateResponse.data.message} Estimated bytes processed: {validateResponse.data.estimated_bytes_processed}
                </Text>
            )
        } else if (isSame && validateResponse.data.status_code !== 200) {
            return (
                <Text style={{ fontFamily: 'Electrolize', color: 'red', fontSize: windowWidth * 0.009, fontWeight: 400 }}>
                    {validateResponse.data.message}
                </Text>
            )
        }
    }

    const imageResponse = () => {

        if (isLoading) {
            return (
                <LoopIcon style={{ color: theme.backgroundColor2, fontSize: windowWidth * 0.015 }} />
            )
        }

        if (hasDisallowed) {
            return (
                <ErrorIcon style={{ color: 'red', fontSize: windowWidth * 0.015 }} />
            )
        } else if (!isSame) {
            return (
                <ErrorIcon style={{ color: 'orange', fontSize: windowWidth * 0.015 }} />
            )
        } else if (isSame && !validateResponse) {
            return (
                <ErrorIcon style={{ color: 'orange', fontSize: windowWidth * 0.015 }} />
            )
        } else if (!isSame && !validateResponse) {
            return (
                <ErrorIcon style={{ color: 'orange', fontSize: windowWidth * 0.015 }} />
            )
        } else if (isSame && validateResponse.data.status_code === 200) {
            return (
                <CheckCircleIcon style={{ color: 'green', fontSize: windowWidth * 0.015 }} />
            )
        } else if (isSame && validateResponse.data.status_code !== 200) {
            return (
                <ErrorIcon style={{ color: 'red', fontSize: windowWidth * 0.015 }} />
            )
        }

    }

    const validateWidth = permittedState ? "65%" : "50%";



    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%', backgroundColor: theme.backgroundColor }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize', color: theme.color }}>Edit Metric</DialogTitle>
                    <DialogContent sx={{
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: windowWidth * 0.75,
                            height: windowHeight * 0.30,
                            //backgroundColor: theme.backgroundColor
                        }}>
                            <View style={{ width: '100%', height: '15%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: theme.backgroundColor2 }}>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                                    <Text style={{ fontFamily: 'Electrolize', color: 'white', fontSize: windowWidth * 0.01 }}>
                                        Name
                                    </Text>

                                </View>
                                <View style={{ width: '40%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                                    <Text style={{ fontFamily: 'Electrolize', color: 'white', fontSize: windowWidth * 0.01 }}>
                                        Expression
                                    </Text>
                                </View>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                                    <Text style={{ fontFamily: 'Electrolize', color: 'white', fontSize: windowWidth * 0.01 }}>
                                        Function
                                    </Text>
                                </View>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                                    <Text style={{ fontFamily: 'Electrolize', color: 'white', fontSize: windowWidth * 0.01 }}>
                                        Format
                                    </Text>
                                </View>

                            </View>
                            <View style={{ width: '100%', height: '25%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '1%' }}>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 0, alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <TextBox
                                        setValue={setNameValue}
                                        currentValue={nameValue}
                                        disabled={false}
                                        theme={theme}
                                    />

                                </View>
                                <View style={{ width: '40%', height: '100%', borderColor: 'grey', borderWidth: 0, alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <TextBox
                                        setValue={setExpressionValue}
                                        currentValue={expressionValue}
                                        disabled={false}
                                        theme={theme}
                                    />

                                </View>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 0, alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <Dropdown
                                        theme={theme}
                                        currentValue={functionValue}
                                        setValue={setFunctionValue}
                                        options={functionOptions}
                                    />

                                </View>
                                <View style={{ width: '20%', height: '100%', borderColor: 'grey', borderWidth: 0, alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <Dropdown
                                        theme={theme}
                                        currentValue={formatValue}
                                        setValue={setFormatValue}
                                        options={formatOptions}
                                    />

                                </View>

                            </View>
                            <View style={{ width: '100%', height: '25%', borderColor: 'grey', borderWidth: 0, alignItems: 'center', justifyContent: 'center', padding: '0%' }}>



                                <Text style={{ fontFamily: 'Electrolize', color: theme.backgroundColor2, fontSize: windowWidth * 0.012, fontWeight: 400, borderColor: theme.backgroundColor2, borderWidth: 1, padding: '1%' }}>
                                    {sqlPreview}

                                </Text>

                            </View>
                            <View style={{ width: '100%', height: '25%', flexDirection: 'row', padding: '1%' }}>
                                <View style={{ width: validateWidth, height: '100%', alignItems: 'start', justifyContent: 'center', padding: '0%' }}>
                                    {textResponse()}

                                </View>
                                <View style={{ width: '5%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    {imageResponse()}

                                </View>
                                <View style={{ width: '15%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => setMetricsOpen(true)} >
                                        <ColorButtonPrimary variant="contained" theme={theme} disabled={validateState}>
                                            AVAILABLE METRICS
                                        </ColorButtonPrimary>
                                    </View>
                                    <OptionsMetricsAvailable
                                        theme={theme}
                                        open={metricsOpen}
                                        setOpen={setMetricsOpen}
                                        currentMetrics={currentMetrics}
                                    />

                                </View>
                                <View style={{ width: '15%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => validateHandler()} >
                                        <ColorButtonPrimary variant="contained" theme={theme} disabled={validateState}>
                                            VALIDATE EXPRESSION
                                        </ColorButtonPrimary>
                                    </View>

                                </View>
                                {!permittedState && <View style={{ width: '15%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '0%' }}>
                                    <View style={{ width: '100%', alignItems: 'right', padding: '2px' }} onClick={() => saveHandler()} >
                                        <ColorButtonPrimary variant="contained" theme={theme} disabled={permittedState}>
                                            UPDATE METRIC
                                        </ColorButtonPrimary>
                                    </View>

                                </View>}

                            </View>
                        </Box>

                    </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}

const Dropdown = (props) => {

    const value = props.currentValue
    const options = props.options
    const disabled = props.disabled
    const setValue = props.setValue
    const theme = props.theme
    const [textValue, setTextValue] = useState(value)

    let bgColor, textColor;
    if (theme.backgroundColor === '#1E1E1F') {
        bgColor = theme.color
        textColor = theme.backgroundColor
    } else {
        bgColor = theme.backgroundColor
        textColor = theme.color
    }

    const updateHander = (value) => {


        setValue(value)
        setTextValue(value)

    }

    const dimensionsMap = options.map((item, index) => (
        <MenuItem key={`dimension-${index}`} value={item}>
            {item}
        </MenuItem>
    ));

    return (
        <Box component="div" sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',

        }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>

                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={textValue}
                    disabled={true}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        color: textColor,
                        backgroundColor: bgColor,
                    }}


                    onChange={(event) => updateHander(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {dimensionsMap}
                </Select>
            </FormControl>

        </Box>
    )
}

const TextBox = (props) => {
    const value = props.currentValue
    const disabled = props.disabled
    const setValue = props.setValue
    const theme = props.theme
    const [textValue, setTextValue] = useState(value)

    let bgColor, textColor;
    if (theme.backgroundColor === '#1E1E1F') {
        bgColor = theme.color
        textColor = theme.backgroundColor
    } else {
        bgColor = theme.backgroundColor
        textColor = theme.color
    }

    const updateHander = (value) => {

        if (value === null || value === undefined || value === "") return;

        setValue(value);
        setTextValue(value);

    }

    const TextWarning = () => {

        const textStyle = {
            fontFamily: 'Electrolize',
            color: 'red',
            fontSize: windowWidth * 0.006,
            textAlign: 'center'
        }

        return (
            <View>
                <Text style={textStyle}>
                    Please remove any spaces or special characters.
                </Text>
                <Text style={textStyle}>
                    Underscores are cool.
                </Text>
            </View>
        )
    }

    return (
        <Box component="div" sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',

        }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>

                <OutlinedInput
                    id="outlined-search"
                    onChange={e => updateHander(e.target.value)}
                    type="search"
                    value={textValue}
                    disabled={disabled}
                    sx={{
                        width: '100%',
                        left: 0,
                        fontFamily: 'Electrolize',
                        color: textColor,
                        backgroundColor: bgColor,
                        textAlign: 'center'
                    }}

                />
            </FormControl>

        </Box>
    )
}