import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import StudioEditMetricsDefinitions from './StudioEditMetricsDefinitions';


import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;


export default function StudioEditMetrics(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const setCurrentMetricIndex = props.setCurrentMetricIndex
    const currentMetricIndex = props.currentMetricIndex
    const metrics = props.metrics
    const customArray = props.customArray
    const configArray = props.configArray
    const cubeTable = props.currentTable
    const baseMetricLength = props.baseMetricLength
    const metricArray = props.metricArray
    const setMetricArray = props.setMetricArray
    //const customArray = props.customArray
    //const dimensions = props.dimensions
    //const dimArray = props.dimArray
    //const setDimArray = props.setDimArray
    //const currentDim = props.currentDim
    //const currentDimIndex = props.currentDimIndex
    //const setCurrentDimIndex = props.setCurrentDimIndex

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{width: '100%'}}>
                <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor, color: theme.color}}>Custom Metrics</DialogTitle>
                <DialogContent sx={{
                    backgroundColor: theme.backgroundColor
                }}>
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>

                        <StudioEditMetricsDefinitions
                            theme={theme}
                            setOpen={setOpen}
                            setCurrentMetricIndex={setCurrentMetricIndex}
                            currentMetricIndex={currentMetricIndex}
                            metrics={metrics}
                            customArray={customArray}
                            configArray={configArray}
                            cubeTable={cubeTable}
                            baseMetricLength={baseMetricLength}
                            metricArray={metricArray}
                            setMetricArray={setMetricArray}
                        />


                    </Box>

                </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}