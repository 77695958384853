import moment from 'moment-timezone';

export default function CustomDailyParse (cubeResponse, xAxisValue, table, time) {
    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
      return null;
    }
    let dimVar;
    if (xAxisValue === 'date') {
        dimVar = 'timestamp'
    } else {
        dimVar = xAxisValue
    }
    const xAxis = xAxisValue
    const impressions = `impressions`
    const clicks = `clicks`
    const spend = `spend`
    const regs = `regs`
    const ftds = `ftds`
    const ftd_ngr = `ftd_ngr`
    const deposits  =`deposits`
    const deposit_amount = `deposit_amount`
    const reactivations = `reactivations`
    const retention_ngr = `retention_ngr`

    var agg_result = [];

      dataset.reduce(function (res, value) {

        let axisValue;

        if (xAxisValue === 'date' && time === 'daily') {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM-DD')
        } else if (xAxisValue === "date" && time === 'monthly') {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
          }  else if (xAxisValue === "date" && time === "weekly") {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
          } else if (xAxisValue === "date" && time === "yearly") {
            axisValue = moment.tz(value[xAxis], 'Europe/Paris').format('YYYY-MM')
          } else { axisValue = value[xAxis] }

        if (!res[axisValue]) {
          res[axisValue] = {
            [xAxisValue]: axisValue,
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
            ftd_ngr: 0,
            retention_ngr: 0,
            deposits: 0,
            deposit_amount: 0,
            reactivations: 0
          };
          agg_result.push(res[axisValue]);
        }
        res[axisValue].impressions += parseInt(value[impressions]) || 0;
        res[axisValue].clicks += parseInt(value[clicks]) || 0;
        res[axisValue].spend += parseInt(value[spend]) || 0;
        res[axisValue].regs += parseInt(value[regs]) || 0;
        res[axisValue].ftds += parseInt(value[ftds]) || 0;

        res[axisValue].ftd_ngr += parseInt(value[ftd_ngr]) || 0;
        res[axisValue].retention_ngr += parseInt(value[retention_ngr]) || 0;
        res[axisValue].deposits += parseInt(value[deposits]) || 0;
        res[axisValue].deposit_amount += parseInt(value[deposit_amount]) || 0;
        res[axisValue].reactivations += parseInt(value[reactivations]) || 0;
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const impressions = obj.impressions
        const clicks = obj.clicks
        const spend = obj.spend;
        const regs = obj.regs
        const ftds = obj.ftds;
        const ngr = obj.ftd_ngr;
        const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
        const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
        const roas = ngr === 0 || spend === 0 || isNaN(spend) ? 0 : ngr / spend;

        return {
          ...obj,
          ctr: parseFloat(ctr.toFixed(4)),
          //cpc: parseFloat(cpc).toFixed(2),
          cpc: parseFloat(cpc.toFixed(2)),
          cpa: parseInt(cpa),
          cpr: parseInt(cpr),
          roas: parseFloat(roas.toFixed(4)),
        };
      });

    const keysToKeep = ['date', xAxisValue, 'impressions', 'clicks', 'ctr', 'cpc', 'spend', 'regs', 'ftds', 'ftd_ngr', 'retention_ngr', 'deposits', 'deposit_amount', 'reactivations', 'cpa', 'roas']

      const filteredResult = agg_result.map(item => {
        const filteredItem = {};
      
        keysToKeep.forEach(key => {
          if (item.hasOwnProperty(key)) {
            filteredItem[key] = item[key];
          }
        });
      
        return filteredItem;
      });
     
      if (xAxisValue === 'date' && time === "monthly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      if (xAxisValue === 'date' && time === "weekly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      if (xAxisValue === 'date' && time === "yearly") {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }
      
      if (xAxisValue === 'date' && time !== 'monthly') {
        filteredResult.sort((a, b) => {
          const dateA = moment.tz(a.date, 'Europe/Paris');
          const dateB = moment.tz(b.date, 'Europe/Paris');
          return dateA - dateB;
        });
      
        // Format the date objects as 'YYYY-MM-DD'
        filteredResult.forEach(item => {
          try {
            item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM-DD');
          } catch (error) {
            console.error(`Error formatting date: ${item.date}`);
          }
        });
      }

      else {filteredResult.sort((a, b) => a[xAxisValue] - b[xAxisValue]);}

      return filteredResult
}