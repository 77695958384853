import * as React from 'react';
import { View, Text, Dimensions } from 'react-native'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';


const windowWidth = Dimensions.get('window').width;

export default function TemplateCard(props) {

    const text = props.text
    const clickHandler = props.clickHandler
    const theme = props.theme
    const disableColor = props.disableColor
    const templateId = props.templateId
    const Icon = props.icon
    const buttonText = props.buttonText

    let iconHeight;
    if (windowWidth > 2000) {
        iconHeight = 68
    } else if (windowWidth > 1500) {
        iconHeight = 78
    } else {
        iconHeight = 90
    }

    return (
        <Card sx={{ minWidth: 200, width: '100%', height: '100%', border: '1px solid white' }}>
            <CardContent sx={{backgroundColor: theme.backgroundColor}} >
                <Typography variant="h5" component="div" sx={{mb: 2, fontSize: windowWidth * 0.01, fontFamily: 'Electrolize', height: '10%', color: theme.color}} >
                    {text}
                </Typography>
                <View style={{width: '100%', alignItems: 'center', justifyContent: 'center', minHeight: iconHeight, height: iconHeight}}>
                    <Icon/>
                </View>

            </CardContent>
            <CardActions sx={{backgroundColor: disableColor, alignItems: 'end', height: '15%'}}>
                <Button 
                    sx={{fontFamily: 'Electrolize', color: 'white'}} 
                    size="small"
                    onClick={() => clickHandler(templateId)}
                    >
                        {buttonText}
                    </Button>
            </CardActions>
        </Card>
    );
}