import React, { useState } from 'react'
import {
    View,
    StyleSheet,
    Text,
    Pressable,
    Dimensions
} from 'react-native'
import DocTemplateModal from './DocTemplateModal';
import ModalPreview from './Modals/ModalPreview';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined';
import Tooltip from '@mui/material/Tooltip';

const windowWidth = Dimensions.get('window').width;

const DocPanel = (props) => {

    const [templateVisible, setTemplateVisible] = useState(false);
    const [currentSlideData, setCurrentSlideData] = useState({});
    const [dateVisible, setDateVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false)
    const theme = props.theme;
    const currentConfig = props.currentConfig;
    const currentMetrics = props.currentMetrics
    const client = props.client
    const setCurrentConfig = props.setCurrentConfig;
    const docSize = currentConfig.length;
    const uniqueBrands = props.uniqueBrands;
    const uniqueChannels = props.uniqueChannels;
    const uniqueTypes = props.uniqueTypes;
    const chartTypes = props.chartTypes;
    const metrics = props.metrics;
    const updateSlide = props.updateSlide;
    const updateDate = props.updateDate;
    const cubeResponse = props.cubeResponse
    const cubeTable = props.cubeTable
    const dates = props.dates
    const startDate = dates.startDateString
    const endDate = dates.yesterdayProp

    const configTemplate = {
        "slide": docSize + 1,
        "type": "line",
        "start": startDate,
        "end": endDate,
        "xAxis": "date",
        "metric1": "spend",
        "metric2": "ftds",
        "brand": "Total",
        "channel": "Total",
        "kw_type": "Total"

    }

    function addConfig() {
        const newConfig = {
            ...configTemplate,
            slide: docSize + 1
        };

        setCurrentConfig([...currentConfig, newConfig]);
    }

    const deleteConfig = (slideIndex) => {
        const updatedConfig = currentConfig.filter((_, index) => index !== slideIndex)
            .map((slide, index) => ({ ...slide, slide: index + 1 }));

        setCurrentConfig(updatedConfig);
        localStorage.setItem("docConfig", JSON.stringify(updatedConfig));
    };

    const openModalWithSlideData = (slideData) => {
        setCurrentSlideData(slideData);
        setTemplateVisible(true);
    };

    const configLength = currentConfig.length
    const isConfigLengthOne = configLength === 1 ? false : true;


    const slideContainer = {
        margin: '1%',
        borderWidth: 1,
        borderColor: theme.color,
        borderRadius: 15,
        backgroundColor: theme.backgroundColor2,
        width: '28%',
        minHeight: '25%',
        padding: '1%',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        alignItems: 'center',
        justifyContent: 'center'
    }

    let dynamicText;
    if (windowWidth > 1600) {
        dynamicText = 1
    } else { dynamicText = 0.5 }

    const text = {
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontSize: 40 * dynamicText,
        color: theme.color2,
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
        textTransform: 'capitalize'
    }

    function filterJsonKeys(jsonArray, keysToKeep) {
        return jsonArray.map(obj => {
            const filteredObj = {};
            keysToKeep.forEach(key => {
                if (obj.hasOwnProperty(key)) {
                    filteredObj[key] = obj[key];
                }
            });
            return filteredObj;
        });
    }

    const keysToKeep = ['slide', 'type', 'xAxis', 'metric1', 'start', 'end']; // Specify the keys you want to keep

    const filteredArray = filterJsonKeys(currentConfig, keysToKeep);

    let dynamicLeft;

    if (windowWidth < 1600) {
        dynamicLeft = '92%'
    } else { dynamicLeft = '95%' }

    const SlideComponent = ({ slideData, onDelete, onOpenModal }) => (
        <View style={slideContainer}>
        <Tooltip
                    title={"Remove slide"}
                    placement="top"
                    componentsProps={{
                        arrow: { sx: { color: theme.backgroundColor2, } },
                        tooltip: {
                            sx: {
                                bgcolor: theme.backgroundColor2,
                                color: "white",
                                fontFamily: 'Electrolize',
                            }
                        },
                    }}
                    arrow
                >
            <Pressable
                style={{
                    position: 'absolute',
                    left: dynamicLeft,
                    top: '0%',
                    zIndex: 999,
                    borderColor: 'grey',
                    borderWidth: 0
                }}
                onPress={onDelete}>
                {isConfigLengthOne && <RemoveCircleOutlinedIcon />}
            </Pressable>
            </Tooltip>
            <Pressable
                onPress={onOpenModal}
            >
                {Object.entries(slideData).map(([key, value]) => {
                    let convLabel

                    if (value === 'ftds') {
                        convLabel = currentMetrics.primaryMetric
                    } else if (value === 'ngr') {
                        convLabel = currentMetrics.primaryRevenue
                    } else if (value === 'regs') {
                        convLabel = currentMetrics.secondaryMetric
                    } else if (value === 'kw_type') {
                        convLabel = currentMetrics.primaryDimensionString
                    } else {convLabel = value}

                    return (
                    
                    <Text key={key} style={text}>
                        {key}: {convLabel}
                    </Text>

                )}
                )}

            </Pressable>
        </View>
    );


    return (
        <View>
            <View style={styles.container} >
                {filteredArray.map((slide, index) => (
                    <SlideComponent
                        key={index + slide}
                        slideData={slide}
                        onDelete={() => deleteConfig(index)}
                        onOpenModal={() => openModalWithSlideData(slide)}
                        id={"captureComponent" + index}
                    >

                    </SlideComponent>
                ))}


                <View
                    style={styles.addSlide}
                >
                    <View
                        style={{
                            width: '100%',
                            height: '100%',
                            flexDirection: 'column'
                        }}>
                        <Tooltip
                            title={"Add slide"}
                            placement="bottom"
                            componentsProps={{
                                arrow: { sx: { color: theme.backgroundColor2, } },
                                tooltip: {
                                    sx: {
                                        bgcolor: theme.backgroundColor2,
                                        color: "white",
                                        fontFamily: 'Electrolize',
                                    }
                                },
                            }}
                            arrow
                        >
                            <Pressable
                                onPress={() => addConfig()}
                                style={{
                                    width: '100%',
                                    height: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                <AddCircleOutlineOutlinedIcon
                                    style={{
                                        color: theme.panelBackground,
                                        fontFamily: 'Electrolize',
                                        fontSize: 140 * dynamicText,
                                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',

                                    }} />
                            </Pressable>
                        </Tooltip>
                        <Tooltip
                            title={"Preview presentation"}
                            placement="bottom"
                            componentsProps={{
                                arrow: { sx: { color: theme.backgroundColor2, } },
                                tooltip: {
                                    sx: {
                                        bgcolor: theme.backgroundColor2,
                                        color: "white",
                                        fontFamily: 'Electrolize',
                                    }
                                },
                            }}
                            arrow
                        >
                            <Pressable
                                onPress={() => setPreviewVisible(true)}
                                style={{
                                    width: '100%',
                                    height: '50%',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>

                                <PanoramaOutlinedIcon
                                    style={{
                                        color: theme.panelBackground,
                                        fontFamily: 'Electrolize',
                                        fontSize: 130 * dynamicText,
                                        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',

                                    }} />

                            </Pressable>
                        </Tooltip>
                    </View>
                </View>

            </View>
            <DocTemplateModal
                theme={theme}
                visible={templateVisible}
                config={currentSlideData}
                setTemplateVisible={setTemplateVisible}
                uniqueBrands={uniqueBrands}
                uniqueChannels={uniqueChannels}
                uniqueTypes={uniqueTypes}
                chartTypes={chartTypes}
                metrics={metrics}
                updateSlide={updateSlide}
                dateVisible={dateVisible}
                setDateVisible={setDateVisible}
                updateDate={updateDate}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}
            />
            <ModalPreview
                theme={theme}
                visible={previewVisible}
                setVisible={setPreviewVisible}
                config={currentSlideData}
                cubeResponse={cubeResponse}
                cubeTable={cubeTable}
                client={client}
                currentConfig={currentConfig}
                currentMetrics={currentMetrics}
            />

        </View>
    );


}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        fontFamily: 'Electrolize',
        padding: '1%',
        flexWrap: 'wrap',

        justifyContent: 'center'
    },
    slideContainer: {
        margin: '1%',
        borderWidth: 1,
        borderColor: '#ccc',

        width: '28%',
        minHeight: '25%',
        padding: '1%'
    },
    addSlide: {
        margin: '1%',
        borderWidth: 1,
        borderColor: 'grey',
        width: '28%',
        borderRadius: 10

    },
    text: {
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontSize: 20
    }
})

export default DocPanel