import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import Button from '@mui/material/Button';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default function StudioEditMetricsAvailable(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const metrics = props.metrics

    const metricLength = metrics.length
    const metricSize = `${100 / metricLength}%`

    const currentMetricHandler = (metric) => {
        if (metric === 'ftds') {
            return currentMetrics.primaryMetric
        } else if (metric === 'ftd') {
            return currentMetrics.primaryMetric
        } else if (metric === 'regs') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'reg') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'ngr') {
            return currentMetrics.primaryRevenue
        } else if (metric === 'ftd_ngr') {
            return currentMetrics.primaryRevenue
        } else {
            return metric
        }
    }

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const metricContainerStyle = {
        width: 150,
        height: 65,
        backgroundColor: theme.backgroundColor2,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',
        borderColor: theme.backgroundColor,
        borderWidth: 2
    }

    const metricTextStyle = {
        textAlign: 'center',
        fontSize: windowWidth * 0.01,
        width: '90%',
        color: 'white',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',
    }

    

    const MetricPaper = ({ value }) => {

        return (
            <View style={metricContainerStyle}>
                <Text style={metricTextStyle}>
                    {currentMetricHandler(value)}
                </Text>
            </View>

        )
    }



    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor2, color: 'white' }}>Available Metrics</DialogTitle>
                    <DialogContent sx={{
                        backgroundColor: theme.backgroundColor
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: windowWidth * 0.8,
                            height: windowHeight * 0.23,
                            
                        }}>
                            

                                {metrics.map((child, index) =>
                                    <View style={{padding: '0.2%'}} key={`grouping-map-${index}`}>
                                        <MetricPaper
                                            value={child}
                                        />
                                    </View>
                                )}
                            

                        </Box>

                    </DialogContent>
                    <DialogActions sx={{
                        backgroundColor: theme.backgroundColor
                    }}>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </View>

            </Dialog>
        </div>

    );
}

