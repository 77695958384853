import moment from 'moment-timezone';

export default function AnalyticsCWVParseTime (timeArray, typeArray, pageArray) {

    var dataset = timeArray

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterByType(data, rating) {
        return data.filter(item => item.cwv_rating === rating);
    }

    let timeParse;

    timeParse = timeArray.map((obj) => {


        return {

            ...obj,
        }

    })

    const aggregateTypeFunction = (data, dim) => {
        const aggResult = []; // Initialize the aggregation result array
    
        const res = data.reduce((acc, value) => {
            const dimvalue = value[dim];
    
            if (!acc[dimvalue]) {
                // Initialize aggregation for a new date
                acc[dimvalue] = {
                    [dim]: dimvalue,
                    page_views: 0,
                    cwv_value: 0,
                    cwv_avg: 0,
                    cwv_avg_raw: 0,
                    count: 0,
                };
                aggResult.push(acc[dimvalue]); // Add to aggregation result
            }
    
            // Accumulate values for each date
            acc[dimvalue].page_views += parseInt(value.page_views) || 0;
            acc[dimvalue].cwv_value += parseFloat(value.cwv_value) || 0;
            acc[dimvalue].count += parseInt(value.count) || 0;
            acc[dimvalue].cwv_avg_raw += parseInt(value.cwv_avg) || 0;


    
            // Calculate the average by dividing the total `cwv_value` by the count
            acc[dimvalue].cwv_avg = acc[dimvalue].cwv_value / acc[dimvalue].count;
    
            return acc;
        }, {});
    
        return aggResult; // Return the aggregated result array
    }


    const aggData = (data) => {
        const aggResult = []; // Initialize the aggregation result array
    
        const res = data.reduce((acc, value) => {
            const date = value.date; // Assuming each entry in data has a 'date' field
    
            if (!acc[date]) {
                // Initialize aggregation for a new date
                acc[date] = {
                    date: date,
                    page_views: 0,
                    cwv_value: 0,
                    cwv_avg: 0,
                    count: 0 // New field to keep track of the count for averaging
                };
                aggResult.push(acc[date]); // Add to aggregation result
            }
    
            // Accumulate values for each date
            acc[date].page_views += parseInt(value.page_views) || 0;
            acc[date].cwv_value += parseInt(value.cwv_value) || 0;
            acc[date].count += parseInt(value.count) || 0;

    
            // Calculate the average by dividing the total `cwv_value` by the count
            acc[date].cwv_avg = acc[date].cwv_value / acc[date].count;
    
            return acc;
        }, {});
    
        return aggResult; // Return the aggregated result array
    };

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    

    const ratingBase = aggregateTypeFunction(typeArray, 'cwv_rating')
    const ratingTotal = (ratingBase[0].page_views || 0) + (ratingBase[1].page_views || 0) + (ratingBase[2].page_views || 0)
    const rating1Percent = (ratingBase[0].page_views || 0) / ratingTotal
    const rating2Percent = (ratingBase[1].page_views || 0) / ratingTotal
    const rating3Percent = (ratingBase[2].page_views || 0) / ratingTotal

    const ratingArray = {
        labels: [ratingBase[0].cwv_rating, ratingBase[1].cwv_rating, ratingBase[2].cwv_rating],
        values: [rating1Percent, rating2Percent, rating3Percent]
    }
    
    const dateArray = aggData(timeParse);
    dateArray.sort((a, b) => {
              const dateA = moment.tz(a.date, 'Europe/Paris');
              const dateB = moment.tz(b.date, 'Europe/Paris');
              return dateA - dateB;
            });
          
            // Format the date objects as 'YYYY-MM-DD'
            dateArray.forEach(item => {
              try {
                item.date = moment.tz(item.date, 'Europe/Paris').format('YYYY-MM-DD');
              } catch (error) {
                console.error(`Error formatting date: ${item.date}`);
              }
            });
    

    const goodArray = filterByType(timeArray, 'good')
    const poorArray = filterByType(timeArray, 'poor')
    const improvementArray = filterByType(timeArray, 'needs-improvement')
    const pageArrays = aggregateTypeFunction(pageArray, 'page_location')
    const dateLabels = getUniqueValues(dateArray, 'date')
    const eventLabels = getUniqueValues(typeArray, 'event_name')
    const eventArray = aggregateTypeFunction(typeArray, 'event_name')
    const contentArray = aggregateTypeFunction(pageArray, 'content_category')

    return {
        goodArray,
        poorArray,
        improvementArray,
        dateArray,
        ratingArray,
        pageArrays,
        dateLabels,
        eventLabels,
        eventArray,
        contentArray
    }

}