import React, { useEffect, useState } from 'react';
import { View, Pressable, Dimensions, Text } from 'react-native';
//import Box from '@mui/material/Box';
//import SpeedDial from '@mui/material/SpeedDial';
//import SpeedDialIcon from '@mui/material/SpeedDialIcon';
//import SpeedDialAction from '@mui/material/SpeedDialAction';
//import TableChartIcon from '@mui/icons-material/TableChart';
//import MultilineChartIcon from '@mui/icons-material/MultilineChart';
//import ShowChartIcon from '@mui/icons-material/ShowChart';
//import BarChartIcon from '@mui/icons-material/BarChart';
//import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import GridLayout from "react-grid-layout";
//import NumbersIcon from '@mui/icons-material/Numbers';
import StudioParse from '../StudioCreate/StudioParse';
import StudioChartsContainer from '../StudioCharts/StudioChartsContainer';

const windowWidth = Dimensions.get('window').width;

const StudioPreviewGrid = (props) => {

    const theme = props.theme
    const currentStep = props.currentStep
    //const reportList = props.reportList
    //const setCurrentReport = props.setCurrentReport
    const activeLayoutItem = props.activeLayoutItem
    //const activeitem = props.activeitem
    //const setActiveItem = props.setActiveItem
    //const setActiveLayoutItem = props.setActiveLayoutItem
    const layout = props.layout
    const setLayout = props.setLayout
    const widgetLayout = props.widgetLayout
    const setWidgetLayout = props.setWidgetLayout
    const cubeResponse = props.cubeResponse
    const currentMetrics = props.currentMetrics
    const calcFields = props.calcFields
    const calcFieldsAliases = props.calcFieldsAliases
    const isCumulative = props.isCumulative
    const baseMetrics = props.baseMetrics

    /*const actions = [
        { icon: <SpaceBarIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Space', quickAction: () => speedDialhandler('space') },
        { icon: <NumbersIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Metric', quickAction: () => speedDialhandler('metric') },
        { icon: <TableChartIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Table', quickAction: () => speedDialhandler('table') },
        { icon: <MultilineChartIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Mixed', quickAction: () => speedDialhandler('mixed') },
        { icon: <ShowChartIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Line', quickAction: () => speedDialhandler('line') },
        { icon: <BarChartIcon sx={{ color: theme.backgroundColor2 }} />, name: 'Bar', quickAction: () => speedDialhandler('bar') },
    ];
    const speedDialhandler = (item) => {
        console.log(item)
    }
   */

    const calcCumulative = (data, dim, baseMetrics, calcMetrics, calcAliases) => {
        // Initialize cumulative values for base metrics
        const cumulativeBaseMetrics = baseMetrics.reduce((acc, metric) => {
            acc[metric] = 0;
            return acc;
        }, {});
    
        return data.map((item, index) => {
            // Update cumulative sums for base metrics
            const cumulativeItem = { [dim]: item[dim] };
            baseMetrics.forEach(metric => {
                cumulativeBaseMetrics[metric] += item[metric] || 0;
                cumulativeItem[metric] = cumulativeBaseMetrics[metric];
            });
    
            // Apply custom calculations on the cumulative values
            const safeEval = (formula, data) => {
                try {
                    const result = Function(
                        ...Object.keys(data),
                        `return (${formula});`
                    )(...Object.values(data));
    
                    if (!isFinite(result) || isNaN(result)) {
                        return 0; // Fallback value for invalid results
                    }
                    return result;
                } catch (error) {
                    return 0; // Fallback value in case of errors
                }
            };
    
            const cumulativeCalculatedValues = calcMetrics.reduce((acc, formula, i) => {
                const alias = calcAliases[i];
                if (alias) {
                    acc[alias] = safeEval(formula, cumulativeItem);
                }
                return acc;
            }, {});
    
            // Combine cumulative base metrics and calculated fields
            return {
                ...cumulativeItem,
                ...cumulativeCalculatedValues,
            };
        });
    };


    const WidgetContent = ({ item }) => {
        
        const borderColor = item.borderColor || 'grey'

        const parsedDataBase = StudioParse(cubeResponse, item, calcFieldsAliases, calcFields)
        let parsedData;
        

        if (isCumulative && item.xAxisValue === 'date' && (item.typeValue === 'bar' || item.typeValue === 'line' || item.typeValue === 'mixed' )) {
            parsedData = calcCumulative(parsedDataBase, item.xAxisValue, baseMetrics, calcFields, calcFieldsAliases)
        } else if (item.typeValue === 'cohorts') {
            parsedData = cubeResponse
        } else {
            parsedData = parsedDataBase
        }

        return <View style={{ background: theme.backgroundColor, borderRadius: 7, padding: '1%', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: borderColor, borderWidth: 1 }}>
            <StudioChartsContainer
                theme={theme}
                item={item}
                data={parsedData}
                currentMetrics={currentMetrics}
                activeLayoutItem={activeLayoutItem}
                currentStep={currentStep}
            />

        </View>

    }
    function sizeHandler(size) {

        let responseRow;

        if (windowWidth > 2000) {
            responseRow = 25
        } else if (windowWidth > 1700) {
            responseRow = 28
        } else if (windowWidth > 1500) {
            responseRow = 25
        } else if (windowWidth > 1400) {
            responseRow = 28
        } else if (windowWidth <= 1400) {
            responseRow = 16
        } else { responseRow = 0.5 }

        //return parseInt(size * responseRow)
        return responseRow

    }

    return (
        <View style={{ width: '100%', height: '100%', backgroundColor: theme.backgroundColor }}>
            <GridLayout
                className="complex-interface-layout"
                layout={layout}
                cols={12}
                rowHeight={sizeHandler(40)}
                width={windowWidth * 0.99}
                compactType="vertical"
                //onDragStart={handleDragStart}
                //onLayoutChange={(newLayout) => setLayout(newLayout)}
                isDraggable={false}
                isResizable={false}
                //isBounded={true}
                style={{ backgroundColor: theme.backgroundColor, height: "100%", flexWrap: 'wrap' }}
            >
                {widgetLayout.map((item, index) => (

                    <div key={item.i}
                        //style={activeHandler(item)
                        style={{ borderRadius: 7 }}
                    >
                        <Pressable
                            style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', justifyContent: 'center', borderRadius: 7 }}
                        >
                            <WidgetContent item={item} />

                        </Pressable>
                    </div>
                ))}
            </GridLayout>
        </View>
    )

}


export default StudioPreviewGrid;