import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToAPIpreagg } from '../../UserConfigs/ApiGateway.js';
import { signPreagg } from '../../UserConfigs/ApiGateway.js';
import LoadingComponent from '../../Components/Loader.js';
import NoDataContainer from '../../Components/NoDataContainer.js';
import CustomDailyPanels from './CustomDailyPanels.js';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway.js';
import postToApiGateway from '../../UserConfigs/ApiGateway.js';

const windowHeight = Dimensions.get('window').height;

const CustomDailyPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    let customDimensions;
    if (configArray.customGroupings.custom_dimensions.length === 0) {
        customDimensions = Object.keys(configArray.customModel.dimensions[0])
    } else {
        customDimensions = Object.keys(configArray.customModel.dimensions)
    }

    const customMeasures = Object.keys(configArray.customModel.measures) || []
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayPlus2 = yesterday2.clone().add(0, 'hour');
    const yesterdayProp = yesterdayPlus2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = `${client}_adgroup`
    const homeTitle = "Trends Summary";

    const custom_groupings = configArray.customGroupings
    const custom_dimensions_sql = custom_groupings.custom_dimensions_sql || []
    const custom_dimensions_aliases = custom_groupings.custom_dimensions_aliases || []

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'campaign'

    const [filterType1, setFilterType1] = useState(filter1name)
    const [filterType2, setFilterType2] = useState(filter2name)
    const [filterType3, setFilterType3] = useState(filter3name)
    const [timeValue, setTimeValue] = useState('daily')

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List: [],
        filter2List: [],
        filter3List: []
    }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name: filterType1,
        filter2name: filterType2,
        filter3name: filterType3,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        filterType1,
        setFilterType1,
        filterType2,
        setFilterType2,
        filterType3,
        setFilterType3,
        customDimensions,
        customMeasures
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filterType1]: ["Total"],
        [filterType2]: ['Total'],
        [filterType3]: ["Total"],
    };

    const [filters, setFilters] = useState(filterBase);

    //console.log(filters)
    //console.log(filterBase)

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filterType1] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filterType1].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filterType1][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filterType2] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filterType2].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filterType2][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filterType3] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filterType3].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filterType3][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filterType1]: "Total",
        [filterType2]: 'Total',
        [filterType3]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filterType1]: prop[filterType1],
            [filterType2]: prop[filterType2],
            [filterType3]: prop[filterType3],
        };

        setFilters(filterUpdate)

    };

    const cubeDims = ['date']
    const dimList = [filter1name, filter2name, filter3name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;

            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": cubeDims,
            "measures": [
                "impressions",
                "clicks",
                "spend",
                "regs",
                "ftds",
                "ftd_ngr",
                "retention_ngr"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": dimList,
            "measures": [
                "impressions"
            ],
            "custom_dimensions": custom_dimensions_sql,
            "custom_dimensions_aliases": custom_dimensions_aliases,
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const fetchPreAgg = async (event_data, support_url, support_key, support_id, reportName) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const endpointStage = configArray.testingState
        const requestType = 'preagg'
        const key = `stack-pattern/default_preagg%2F${client_id}%2F${reportName}`
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}`
        try {
            // First, get the signed URL
            const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPIpreagg(signedUrl, event_data, "GET");
            //const preAggEvent = {"data" : {"body": result.data}}
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const fetchPreAggDim = async (event_data, support_url, support_key, support_id, reportName) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
        const endpointStage = configArray.testingState
        const requestType = 'preagg'
        const key = `stack-pattern/default_preagg%2F${client_id}%2F${reportName}`
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}`
        try {
            // First, get the signed URL
            const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPIpreagg(signedUrl, event_data, "GET");
            //const preAggEvent = {"data" : {"body": result.data}}
            //console.log(result)
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    }


    //useEffect(() => {
    //    requestData(cubeQuery, patternAPI, support_key, support_id)
    //    requestDimData(dimQuery, patternAPI, support_key, support_id)
    //}, [configArray, filters])

    useEffect(() => {
        const queryStart = cubeQuery.query.start;
        const queryEnd = cubeQuery.query.end;
        const queryFilters = cubeQuery.query.filters;
    
        const cubeReport = `${client_lower}_${formatString(homeTitle)}_cube.json`
        const dimReport = `${client_lower}_${formatString(homeTitle)}_dim.json`

        

    const fetchData = async () => {
        if (queryStart === start && queryEnd === end) {
            
            if (queryFilters === filterSql || queryFilters.length === 0) {
                try {
                    console.log("preAgg")
                    await fetchPreAgg(cubeQuery, patternAPI, support_key, support_id, cubeReport);
                    await fetchPreAggDim(dimQuery, patternAPI, support_key, support_id, dimReport);

                } catch (error) {
                    console.error("Error with fetchPreAgg:", error);
                    requestData(cubeQuery, patternAPI, support_key, support_id)
                    requestDimData(dimQuery, patternAPI, support_key, support_id)
                }
            }
        } else {
            console.log("fallback")
            requestData(cubeQuery, patternAPI, support_key, support_id)
            requestDimData(dimQuery, patternAPI, support_key, support_id)
        }
    };

    fetchData();
}, [configArray]);



useEffect(() => {
    WebFont.load({
        google: {
            families: ['Droid Sans', 'Electrolize']
        }
    });
}, []);

if (isLoading) {
    return (
        <LoadingComponent theme={theme} />
    );
}

if (!configResponse) {
    return (
        <LoadingComponent theme={theme} />
    );
}

if (!dimResponse) {
    return (
        <LoadingComponent theme={theme} />
    );
}


const cubeResponse = configResponse.data.body
const dimCubeResponse = dimResponse.data.body

const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
};

if (cubeResponse.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
}

const uniqueFilter1 = getUniqueValues(dimCubeResponse, filterType1)
const uniqueFilter2 = getUniqueValues(dimCubeResponse, filterType2)
const uniqueFilter3 = getUniqueValues(dimCubeResponse, filterType3)

const brandLabels = getUniqueValues(cubeResponse, 'brand')

const dataProp = {
    aggResult: cubeResponse,
    uniqueFilter1,
    uniqueFilter2,
    uniqueFilter3,
}

const lastUpdated = 'loading...'

return (
    <View style={styles.container}>

        <View style={{ width: '100%', height: '100%' }}>
            <CustomDailyPanels
                theme={theme}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                timeFrame={"Daily"}
                filterParams={filterParams}
                dataProp={dataProp}
                agg_daily={cubeResponse}
                client={client}
                setFilterList={setFilterList}
                filterList={filterList}
                lastUpdated={lastUpdated}
                cubeTable={cubeTable}
                timeValue={timeValue}
                setTimeValue={setTimeValue}
                brandLabels={brandLabels}
                currentMetrics={currentMetrics} />


        </View>
    </View>

)

}

export default CustomDailyPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 1.21,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})