import React, { useState } from 'react';
import { View, Pressable, Dimensions } from 'react-native';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FormHelperText from '@mui/material/FormHelperText';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { ListItemIcon, ListItemText } from '@mui/material';

import StudioEditMetrics from '../StudioEditDim/StudioEditMetrics';
import StudioEditDim from '../StudioEditDim/StudioEditDim';
import StudioEditCalc from '../StudioEditDim/StudioEditCalc';
import StudioControlMultiSelect from './StudioControlMultiSelect';
import StudioControlColourPicker from './StudioControlColourPicker';

const windowWidth = Dimensions.get('window').width;

const StudioControl = (props) => {
    const configArray = props.configArray
    const currentMetrics = props.currentMetrics
    const activeItemIndex = props.activeLayoutItem
    const outputFields = props.outputFields
    const theme = props.theme
    const widgetLayout = props.widgetLayout
    const setWidgetLayout = props.setWidgetLayout
    const activeitem = widgetLayout[activeItemIndex]
    const reportName = props.reportName
    const setReportName = props.setReportName
    const defaultMetrics = props.defaultMetrics
    const customArray = props.customArray
    const customDimsAliases = customArray.customDimsAliases
    const customMetricsAliases = customArray.customMetricsAliases
    const customDimArray = customArray.customDimArray
    const calcFieldsAliases = customArray.calcFieldsAliases
    //const customMetricList = customArray.metricList
    const setMetricList = customArray.setMetricList
    const dimArray = props.dimArray
    const setDimArray = props.setDimArray
    const currentTable = props.currentTable
    const setUpdateQuery = props.setUpdateQuery
    const [textValue, setTextValue] = useState(reportName)
    const [currentDim, setCurrentDim] = useState([])
    const [currentDimIndex, setCurrentDimIndex] = useState(0)
    const [currentMetricIndex, setCurrentMetricIndex] = useState(0)
    const [currentCalcIndex, setCurrentCalcIndex] = useState(0)
    const [metricArray, setMetricArray] = useState([])
    const [calcArray, setCalcArray] = useState([])
    const [colourOpen, setColourOpen] = useState(false)
    const [colourType, setColourType] = useState('color')
    

    const type = activeitem.typeValue
    const xAxis = activeitem.xAxisValue
    const metric1 = activeitem.metric1Value
    const metric2 = activeitem.metric2Value
    const filters = activeitem.currentFilters
    const color = activeitem.color
    const dateAgg = activeitem.dateAgg
    const currentItem = activeitem.i
    const currentMetricList = activeitem.metricList
    const itemBorderColor = activeitem.borderColor
    const color2 = activeitem.color2
    const format1 = activeitem.format1
    const format2 = activeitem.format2
    const smoothing = activeitem.smoothing
    const moving_average = activeitem.moving_average

    const [widgetType, setWidgetType] = useState(type)
    const [widgetXAxis, setWidgetXAxis] = useState(xAxis)
    const [widgetMetric1, setWidgetMetric1] = useState(metric1)
    const [widgetMetric2, setWidgetMetric2] = useState(metric2)
    const [widgetFilters, setWidgetFilters] = useState(filters)
    const [widgetColor, setWidgetColor] = useState(color)
    const [widgetDateAgg, setWidgetDateAgg] = useState(dateAgg)
    const [widgetMetricList, setWidgetMetricList] = useState(currentMetricList)
    const [widgetBorderColor, setWidgetBorderColor] = useState(itemBorderColor)
    const [widgetColor2, setWidgetColor2] = useState(color2)
    const [widgetFormat1, setWidgetFormat1] = useState(format1)
    const [widgetFormat2, setWidgetFormat2] = useState(format2)
    const [widgetSmoothing, setWidgetSmoothing] = useState(smoothing)
    const [widgetMovingAverage, setWidgetMovingAverage] = useState(moving_average)

    const [editDimOpen, setEditDimOpen] = useState(false)
    const [editMetricsOpen, setEditMetricsOpen] = useState(false)
    const [editCalcOpen, setEditCalcOpen] = useState(false)

    const isTime = widgetXAxis === 'date'
    const isMixed = widgetType === 'mixed'
    const isTable = widgetType === 'table'
    const isLine = widgetType === 'line'

    const typesBase = ["bar", "line", "mixed", "table",, "pie", "scatter", "metric", "space"]
    const types_cohort = ["bar", "line", "mixed", "table",, "pie", "scatter", "cohorts", "metric", "space"]
    let types;
    if (currentTable.includes('cohorts')) {
        types = types_cohort
    } else {
        types = typesBase
    }
    const dateAggs = ["day", "week", "month", "year"]

    const extendedDims = [...outputFields.dimensions, ...customDimsAliases, "CREATE CUSTOM DIM"]
    const extendedMetrics = [...outputFields.metrics, ...calcFieldsAliases, "CREATE CALCULATED FIELD"]
    const baseDimLength = outputFields.dimensions.length
    const baseMetricLength = outputFields.metrics.length
    const [multiSelect, setMultiSelect] = useState(extendedMetrics)

    const currentMetricHandler = (metric) => {
        if (metric === 'ftds') {
            return currentMetrics.primaryMetric
        } else if (metric === 'ftd') {
            return currentMetrics.primaryMetric
        } else if (metric === 'regs') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'reg') {
            return currentMetrics.secondaryMetric
        } else if (metric === 'ngr') {
            return currentMetrics.primaryRevenue
        } else if (metric === 'ftd_ngr') {
            return currentMetrics.primaryRevenue
        } else {
            return metric
        }
    }


    const reportNameHander = (textValue) => {
        setReportName(textValue)
    }

    const customDimHandler = (item) => {

        if (item === 'CREATE CUSTOM DIM') {
            setCurrentDim([])
            setCurrentDimIndex(customDimsAliases.length)
            setEditDimOpen(true)
        } else {
            setWidgetXAxis(item)
        }

    }

    const customMetric1Handler = (item) => {
        if (item === 'CREATE CUSTOM METRIC') {
            setMetricArray({})
            setEditMetricsOpen(true)
            setCurrentMetricIndex(customMetricsAliases.length)
        } else if (item === 'CREATE CALCULATED FIELD') {
            setCalcArray({})
            setEditCalcOpen(true)
            setCurrentCalcIndex(calcFieldsAliases.length)
        }  else {
            setWidgetMetric1(item)
        }

    }

    const customMetric2Handler = (item) => {
        if (item === 'CREATE CUSTOM METRIC') {
            setMetricArray({})
            setEditMetricsOpen(true)
            setCurrentMetricIndex(customMetricsAliases.length)
        } else if (item === 'CREATE CALCULATED FIELD') {
            setCalcArray({})
            setEditCalcOpen(true)
            setCurrentCalcIndex(calcFieldsAliases.length)
        }  else {
            setWidgetMetric2(item)
        }
    }

    const customDimEditHandler = (item, index) => {
        const dimName = item
        const dimList = [];
        const currentDim = customDimArray.find(item => item.hasOwnProperty(dimName));
        dimList.push(currentDim)
        setCurrentDim(dimList)
        setCurrentDimIndex(index - baseDimLength)
        setEditDimOpen(true)

    }

    const customCalcEditHandler = (item, index) => {
        const customIndex = index - (baseMetricLength)
        const customMetricValues = customArray.calcFields
        const customMetricFormats = customArray.calcFieldsFormats
        const customMetricFunctions = customArray.calcFieldsFunctions
        const customMetricsAliases = customArray.calcFieldsAliases

        const output = {
            customMetricValues: [customMetricValues[customIndex]],
            customMetricFormats: [customMetricFormats[customIndex]],
            customMetricFunctions: [customMetricFunctions[customIndex]],
            customMetricsAliases: [customMetricsAliases[customIndex]]
        }
        setCalcArray(output)
        setEditCalcOpen(true)
    }

    const customMetricHandler = (item) => {
        setWidgetMetricList(item)
        setMultiSelect(item)

    }

    const colourClickHandler = (index) => {
        setColourType(index)
        setColourOpen(true)
    }

    const colourhandler = (colourType, colour) => {
        if (colourType === 'color') {
            setWidgetColor(colour)
        } else if (colourType === 'color2') {
            setWidgetColor2(colour)
        } else if (colourType === 'borderColor') {
            setWidgetBorderColor(colour)
        }
    }

    const smoothingHandler = (option) => {
        setWidgetSmoothing(option)
    }

    const updateHandler = () => {

        const newWidgetLayout = widgetLayout.map((item) => {
            if (item.i === currentItem) {
                return {
                    i: currentItem,
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    maxH: item.maxH,
                    maxW: item.maxW,
                    typeValue: widgetType,
                    xAxisValue: widgetXAxis,
                    metric1Value: widgetMetric1,
                    metric2Value: widgetMetric2,
                    currentFilters: widgetFilters,
                    color: widgetColor,
                    dateAgg: widgetDateAgg,
                    metricList: widgetMetricList,
                    borderColor: widgetBorderColor || 'grey',
                    color2: widgetColor2 || theme.backgroundColor,
                    format1: widgetFormat1 || 'int',
                    format2: widgetFormat2 || 'int',
                    smoothing: widgetSmoothing || true,
                    moving_average: widgetMovingAverage || 'none'
                }
            } else {
                return item
            }
        })
        setWidgetLayout(newWidgetLayout)
        setMetricList(widgetMetricList)
        setUpdateQuery(newWidgetLayout)
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor,
        //fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const InvertColorButton = styled(Button)(({ theme }) => ({
        color: theme.color,
        //fontSize: windowWidth * 0.007,
        fontWeight: 200,
        height: 50,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor,
        },
    }));

    const typesMap = types.map((item, index) => (
        <MenuItem
            key={`dimension-${index}`} value={item}
            sx={{ fontFamily: 'Electrolize' }}
        >
            {item}
        </MenuItem>
    ));

    const dateAggsMap = dateAggs.map((item, index) => (
        <MenuItem
            sx={{ fontFamily: 'Electrolize' }}
            key={`operator-${index}`} value={item}>
            {item}
        </MenuItem>
    ));

    const boolOptions = ["true", "false"]

    const smoothingMap = boolOptions.map((item, index) => (
        <MenuItem
            sx={{ fontFamily: 'Electrolize' }}
            key={`smoothing-${index}`} value={item}>
            {item}
        </MenuItem>
    ));

    const dimensionsMap = extendedDims.map((item, index) => (

            <MenuItem key={`dim-${index}`} value={item} sx={{fontFamily: 'Electrolize'}} >
                <ListItemText primary={customDimsAliases.includes(item) ? `${item} (Custom)` : item}
                    sx={{fontFamily: 'Electrolize'}}
                />
                {customDimsAliases.includes(item) && item !== widgetXAxis && (
                    <ListItemIcon sx={{fontFamily: 'Electrolize', width: '10%'}}>
                        <Pressable
                            onPress={() => customDimEditHandler(item, index)}
                        >
                        <ExitToAppIcon fontSize="small" sx={{ color: theme.backgroundColor2 }} />
                        </Pressable>
                    </ListItemIcon>
                )}
            </MenuItem>


    ));

    const metrics1Map = extendedMetrics.map((item, index) => (
        <MenuItem key={`dim-${index}`} value={item} sx={{fontFamily: 'Electrolize'}} >
                <ListItemText primary={calcFieldsAliases.includes(item) ? `${currentMetricHandler(item)} (Custom)` : currentMetricHandler(item)}
                    sx={{fontFamily: 'Electrolize'}}
                />
                {calcFieldsAliases.includes(item) && item !== widgetMetric1 && (
                    <ListItemIcon sx={{fontFamily: 'Electrolize', width: '10%'}}>
                        <Pressable
                            onPress={() => customCalcEditHandler(item, index)}
                        >
                        <ExitToAppIcon fontSize="small" sx={{ color: theme.backgroundColor2 }} />
                        </Pressable>
                    </ListItemIcon>
                )}
            </MenuItem>
    ));

    const metrics2Map = extendedMetrics.map((item, index) => (
        <MenuItem key={`dim-${index}`} value={item} sx={{fontFamily: 'Electrolize'}} >
                <ListItemText primary={calcFieldsAliases.includes(item) ? `${currentMetricHandler(item)} (Custom)` : currentMetricHandler(item)}
                    sx={{fontFamily: 'Electrolize'}}
                />
                {calcFieldsAliases.includes(item) && item !== widgetMetric2 && (
                    <ListItemIcon sx={{fontFamily: 'Electrolize', width: '10%'}}>
                        <Pressable
                            onPress={() => customCalcEditHandler(item, index)}
                        >
                        <ExitToAppIcon fontSize="small" sx={{ color: theme.backgroundColor2 }} />
                        </Pressable>
                    </ListItemIcon>
                )}
            </MenuItem>
    ));

    const smoothingForm = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize'}}>
                <FormHelperText>Line Smoothing</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetSmoothing}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                        flexDirection: 'row',
                        
                    }}
                    onChange={(event) => smoothingHandler(event.target.value)}
                    input={<OutlinedInput  />}
                >
                    {smoothingMap}
                </Select>

            </FormControl>
        </View>)
    }

    const reportForm = () => {

        return (
            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                    <FormHelperText>Report Name</FormHelperText>
                    <OutlinedInput
                        id="outlined-search"
                        onChange={e => setTextValue(e.target.value)}
                        type="search"
                        value={textValue}
                        endAdornment={<SaveAltIcon onClick={() => reportNameHander(textValue)} />}
                        sx={{
                            width: '100%',
                            left: 0,
                            fontFamily: 'Electrolize',
                            color: theme.color,
                            backgroundColor: theme.backgroundColor,
                            textAlign: 'center'

                        }}
                    />
                </FormControl>
            </View>
        )
    }

    const typeForm = () => {

        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Type</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetType}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                    }}
                    onChange={(event) => setWidgetType(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {typesMap}
                </Select>

            </FormControl>
        </View>)
    }

    const dimensionForm = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize'}}>
                <FormHelperText>Dimension</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetXAxis}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                        flexDirection: 'row',
                        
                    }}
                    onChange={(event) => customDimHandler(event.target.value)}
                    input={<OutlinedInput  />}
                >
                    {dimensionsMap}
                </Select>

            </FormControl>
        </View>)
    }

    const aggDateForm = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Aggregation</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetDateAgg}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                    }}
                    onChange={(event) => setWidgetDateAgg(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {dateAggsMap}
                </Select>

            </FormControl>
        </View>)
    }

    const metric1Form = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Metric 1</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetMetric1}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                    }}
                    onChange={(event) => customMetric1Handler(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {metrics1Map}
                </Select>

            </FormControl>
        </View>)
    }

    const metric2Form = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Metric 2</FormHelperText>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    label="Type"
                    value={widgetMetric2}
                    sx={{
                        fontFamily: 'Electrolize',
                        width: '100%',
                        backgroundColor: theme.backgroundColor,
                        color: theme.color,
                    }}
                    onChange={(event) => customMetric2Handler(event.target.value)}
                    input={<OutlinedInput />}
                >
                    {metrics2Map}
                </Select>

            </FormControl>
        </View>)
    }


    const tableForm = () => {

        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Metrics</FormHelperText>
                    <StudioControlMultiSelect
                        filterList={extendedMetrics}
                        setFilterList={customMetricHandler}
                        theme={theme}
                        type={"Metrics"}
                        currentValues={currentMetricList}
                        defaultMetrics={defaultMetrics}
                        currentMetrics={currentMetrics}
                    />


            </FormControl>
        </View>)

    }

    const colourForm = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Metric 1 Colour</FormHelperText>
                <View style={{ width: '100%', padding: '1%' }}>
                    <InvertColorButton variant="contained"
                        theme={theme}
                        onClick={() => colourClickHandler('color')}
                        endIcon={<ColorLensIcon style={{color: theme.backgroundColor2}} />}
                    >
                        Metric 1 Colour
                    </InvertColorButton>
                </View>
            </FormControl>
        </View>)
    }

    const colour2Form = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Metric 2 Colour</FormHelperText>
                <View style={{ width: '100%', padding: '1%' }}>
                    <InvertColorButton variant="contained"
                        theme={theme}
                        onClick={() => colourClickHandler('color2')}
                        endIcon={<ColorLensIcon style={{color: theme.backgroundColor2}} />}
                    >
                        Metric 2 Colour
                    </InvertColorButton>
                </View>
            </FormControl>
        </View>)
    }

    const borderForm = () => {
        return (<View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <FormControl sx={{ m: 1, width: '100%', fontFamily: 'Electrolize', }}>
                <FormHelperText>Border Colour</FormHelperText>
                <View style={{ width: '100%', padding: '1%' }}>
                    <InvertColorButton variant="contained"
                        theme={theme}
                        onClick={() => colourClickHandler('borderColor')}
                        endIcon={<ColorLensIcon style={{color: theme.backgroundColor2}} />}
                    >
                        Border Colour
                    </InvertColorButton>
                </View>
            </FormControl>
        </View>)
    }

    const dynamicForm = () => {
        if (widgetType === 'metric') {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {metric1Form()}
                    {colourForm()}
                    {borderForm()}

                </View>
            )
        } else if (widgetType === 'pie') {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {metric1Form()}

                    {borderForm()}

                </View>
            )
        } else if (widgetType === 'scatter') {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {metric1Form()}
                    {metric2Form()}
                    {colourForm()}
                    {borderForm()}

                </View>
            )
        } else if (isTime && (widgetType === 'bar' || widgetType === 'line')) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1}}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {aggDateForm()}
                    {metric1Form()}
                    {smoothingForm()}
                    {colourForm()}
                    {borderForm()}

                </View>
            )
        } else if (!isTime && (widgetType === 'bar' || widgetType === 'line')) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {metric1Form()}
                    {smoothingForm()}
                    {colourForm()}
                    {borderForm()}

                </View>
            )
        } else if (isTime && isMixed) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {aggDateForm()}
                    {metric1Form()}
                    {metric2Form()}
                    {smoothingForm()}
                    {colourForm()}
                    {colour2Form()}
                    {borderForm()}

                </View>
            )
        } else if (!isTime && isMixed) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {metric1Form()}
                    {metric2Form()}
                    {smoothingForm()}
                    {colourForm()}
                    {colour2Form()}
                    {borderForm()}

                </View>
            )
        } else if (isTime && isTable) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {aggDateForm()}
                    {tableForm()}
                    {borderForm()}

                </View>
            )
        } else if (!isTime && isTable) {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {tableForm()}
                    {borderForm()}

                </View>
            )
        } else if (widgetType === 'space') {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}

                </View>
            )
        } else if (widgetType === 'cohorts') {
            return (
                <View style={{ width: '100%', height: '90%', backgroundColor: theme.backgroundColor2, flexDirection: 'column', overflow: 'auto', borderColor: 'grey', borderWidth: 1 }}>
                    {reportForm()}
                    {typeForm()}
                    {dimensionForm()}
                    {tableForm()}
                    {borderForm()}

                </View>
            )
        }
    }

    return (
        <View style={{ width: '100%', height: '100%', flexDirection: 'column'}}>
            {dynamicForm()}
            <View style={{ width: '100%', height: '10%', alignItems: 'center', justifyContent: 'center' }}>

                <View style={{ width: '100%', alignItems: 'right' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => updateHandler()}
                        endIcon={<SendIcon />}
                    >
                        Update Widget
                    </ColorButton>
                </View>
                <StudioEditDim
                    theme={theme}
                    open={editDimOpen}
                    setOpen={setEditDimOpen}
                    customArray={customArray}
                    dimensions={outputFields.dimensions}
                    dimArray={dimArray}
                    setDimArray={setDimArray}
                    currentDim={currentDim}
                    currentDimIndex={currentDimIndex}
                    setCurrentDimIndex={setCurrentDimIndex}
                />
                <StudioEditMetrics
                    theme={theme}
                    open={editMetricsOpen}
                    setOpen={setEditMetricsOpen}
                    customArray={customArray}
                    metrics={outputFields.metrics}
                    currentMetricIndex={currentMetricIndex}
                    setCurrentMetricIndex={setCurrentMetricIndex}
                    configArray={configArray}
                    currentTable={currentTable}
                    baseMetricLength={baseMetricLength}
                    metricArray={metricArray}
                    setMetricArray={setMetricArray}
                />
                <StudioEditCalc
                    theme={theme}
                    open={editCalcOpen}
                    setOpen={setEditCalcOpen}
                    customArray={customArray}
                    metrics={outputFields.metrics}
                    currentMetricIndex={currentCalcIndex}
                    setCurrentMetricIndex={setCurrentCalcIndex}
                    configArray={configArray}
                    currentTable={currentTable}
                    baseMetricLength={baseMetricLength}
                    metricArray={calcArray}
                    setMetricArray={setCalcArray}
                />
                <StudioControlColourPicker
                    theme={theme}
                    open={colourOpen}
                    setOpen={setColourOpen}
                    message={'Select Colour'}
                    colourhandler={colourhandler}
                    colourType={colourType}
                />

            </View>
        </View>
    )
}



export default StudioControl;