import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
} from 'react-native'
import moment from 'moment';
import { postToSignedUrl } from '../OptionsGroupingDefinitions/OptionsGroupingGET';
import signRequest from '../OptionsGroupingDefinitions/OptionsGroupingGET';
import OptionsGroupingPreviewDataGrid from './OptionsGroupingPreviewDataGrid';
import LoadingComponent from '../../../Components/Loader';

const OptionsGroupingPreview = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    console.log(configArray)
    const clientID = configArray.clientID
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup";
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const groupingData = props.groupingData
    const currentMetrics = props.currentMetrics

    const custom_groupings = groupingData.data.custom_groupings
    const custom_dimensions_sql = custom_groupings.custom_dimensions_sql
    const custom_dimensions_aliases = custom_groupings.custom_dimensions_aliases   

    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayPlus2 = yesterday2.clone().add(0, 'hour');
    const yesterdayProp = yesterdayPlus2.format('YYYY-MM-DD');

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    const dimList = ['brand', 'channel', 'campaign']

    const filterSql = ["impressions > 0"]

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": startDateString,
          "end": yesterdayProp,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": custom_dimensions_sql,
          "custom_dimensions_aliases": custom_dimensions_aliases,
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql
        }
    }

    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
        }
      };

    useEffect(() => {
        requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
      }, [configArray])

      if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

    const dimCubeResponse = dimResponse.data || []
    
    return (
        <View style={styles.container}>
            <OptionsGroupingPreviewDataGrid
                theme={theme}
                currentMetrics={currentMetrics}
                dataProp={dimCubeResponse}
                />
            

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '1%',
        borderWidth: 0,
        borderColor: 'yellow',

    }
})

export default OptionsGroupingPreview