import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableChartIcon from '@mui/icons-material/TableChart';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway';
import postToApiGateway from '../UserConfigs/ApiGateway';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import LoadingComponent from '../Components/Loader';
import StudioCreate from './StudioCreate/StudioCreate';
import StudioDelete from './StudioDelete';
import StudioEdit from './StudioEdit/StudioEdit';
import StudioPreview from './StudioPreview/StudioPreview';
import StudioTemplate from './StudioEdit/StudioTemplate';

import templateCohorts from './StudioTemplates/TemplateCohorts.json'
import templateTrends from './StudioTemplates/TemplateTrends.json'
import templateCampaign from './StudioTemplates/TemplateCampaign.json'
import templateBudget from './StudioTemplates/TemplateBudget.json'
import templateAnalytics from './StudioTemplates/TemplateAnalytics.json'
import TemplateCard from './StudioTemplates/TemplateCard';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const StudioPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const clientMapId = configArray.clientMapId
    const patternAPI = configArray.patternAPI
    const [currentStep, setCurrentStep] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isDimLoading, setDimLoading] = useState(false)
    const [saveResponse, setSaveResponse] = useState({
        "statusCode" : 200
    })

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [currentItem, setCurrentitem] = useState(null)
    const [update, setUpdate] = useState(null)

    const campaignTemplateTable = `${client_lower}_adgroup_enhanced`
    const cohortsTemplateTable = `${client_lower}_cohorts`
    const trendsTemplateTable = `${client_lower}_daily`
    const budgetTemplateTable = `${client_lower}_budget`
    const analyticsTemplateTable =`${client_lower}_analytics_daily`

    const isNotAtt = clientMapId === ('1002') || clientMapId === ('1006')

    const [template, setTemplate] = useState([])
    const [templateTable, setTemplateTable] = useState(null)

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
            
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setIsLoading(false)
        }
    };

    const saveData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setSaveResponse(null);
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setSaveResponse(result)
            setIsLoading(true)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            setIsLoading(false)
            setUpdate({"id": Math.floor(10000000 + Math.random() * 90000000)})
        }
    };

    const dimData = async (event_data, support_url, support_key, support_id) => {
            setDimLoading(true)
            setDimResponse(null); // Clear previous response
            const requestType = event_data.request_type
            const endpointStage = configArray.testingState
            const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`    
            try {
                // First, get the signed URL
                const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
                // Send the POST request to the signed URL
                const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
                setDimResponse(result)
                setDimLoading(false)
            } catch (error) {
                console.error("Error posting to Lambda:", error);
            } finally {
                //setSaveLoading(false);
            }
        };

    const event_data = {
        "request_type": "get_report",
        "client": client_lower,
        "client_id": configArray.clientMapId,
        "auth0_user_id": configArray.auth0_user_id,
    }

    const dim_event_data = {
        "request_type": "client_tables",
        "client": client_lower
    }

    useEffect(() => {
        requestData(event_data, patternAPI, support_key, support_id)
        dimData(dim_event_data, patternAPI, support_key, support_id)
    }, [configArray, update])

    if (isLoading) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (!configResponse) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (!saveResponse) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (isDimLoading) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const customReports = configResponse.data.body
    const reportLength = Object.keys(customReports).length
    const schemaDataBase = dimResponse.data.body

    function filterByDateOrTimestamp(data) {
        const filteredData = {};
    
        for (const key in data) {
            if (data[key].some(field => field.type === "DATE" || field.type === "TIMESTAMP")) {
                filteredData[key] = data[key];
            }
        }
    
        return filteredData;
    }

    const schemaData = filterByDateOrTimestamp(schemaDataBase)

    const previewHandler = (item) => {
        setCurrentitem(item)
        setCurrentStep(3)

    }

    const editHandler = (item) => {
        setCurrentitem(item)
        setCurrentStep(2)
    }

    const deleteClickhandler = (item) => {
        setCurrentitem(item)
        setDeleteOpen(true)

    }

    const deleteHandler = (item) => {
        const baseCopy = JSON.parse(JSON.stringify(customReports))
        const newList = Object.fromEntries(Object.entries(baseCopy).filter(([key]) => key !== item));
        const saveEventData = {
            "request_type": "save_report",
            "client": client_lower,
            "client_id": configArray.clientMapId,
            "auth0_user_id": configArray.auth0_user_id,
            "report_data": newList
        }
        saveData(saveEventData, patternAPI, support_key, support_id)
        setUpdate({"id": Math.floor(10000000 + Math.random() * 90000000)})

    }


    const iconStyle = {
        fontSize: windowHeight * 0.03,
        color: theme.backgroundColor2
    }

    const templateHandler = (item) => {
        if (item === 1) {
            setTemplate(templateCampaign)
            setTemplateTable(campaignTemplateTable)
            setCurrentStep(4)
        } else if (item === 2) {
            setTemplate(templateTrends)
            setTemplateTable(trendsTemplateTable)
            setCurrentStep(4)
        } else if (item === 3) {
            setTemplate(templateCohorts)
            setTemplateTable(cohortsTemplateTable)
            setCurrentStep(4)
        } else if (item === 4) {
            setTemplate(templateBudget)
            setTemplateTable(budgetTemplateTable)
            setCurrentStep(4)
        } else if (item === 4) {
            setTemplate(templateBudget)
            setTemplateTable(budgetTemplateTable)
            setCurrentStep(4)
        } else if (item === 5) {
            setTemplate(templateAnalytics)
            setTemplateTable(analyticsTemplateTable)
            setCurrentStep(4)
        }
    }
    
    const reportMap = ({ reportName, reportDetails, index }) => {
        //const reportSize = reportDetails.layout.length
        return (
            <View style={{ width: '90%', height: '8%', flexDirection: 'row', filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))' }} key={index}>
                <View style={{ backgroundColor: theme.backgroundColor, width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                    <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: theme.color }}>
                        {reportName}
                    </Text>
                </View>
                <View style={{ backgroundColor: theme.backgroundColor, width: '20%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                    <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: theme.color }}>
                        {reportDetails.table}
                    </Text>
                </View>
                <Pressable style={{ backgroundColor: theme.backgroundColor, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}
                    onPress={() => editHandler(reportName)}
                >
                    <CreateIcon style={iconStyle} />

                </Pressable>
                <Pressable style={{ backgroundColor: theme.backgroundColor, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}
                    onPress={() => previewHandler(reportName)}
                    >
                    <VisibilityIcon style={iconStyle} />
                </Pressable>
                <Pressable style={{ backgroundColor: theme.backgroundColor, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}
                    onPress={() => deleteClickhandler(reportName)}
                    >
                    <DeleteIcon style={iconStyle} />
                </Pressable>
                
            </View>
        )
    }

    const reportUnderLimit = reportLength >= 10
    const newhandler = (value) => {
        if (reportLength < 10) {
            setCurrentStep(value)
        } else {
            return
        }
    }

    const templateLimitHandler = (value) => {
        if (reportLength < 10) {
            templateHandler(value)
        } else {
            return
        }
    }

    const disableColor = reportLength >= 10 ? 'grey' : theme.backgroundColor2;

    const addIcon = () => {
        return (<AddCircleOutlineOutlinedIcon
        style={{
            color: disableColor,
            fontFamily: 'Electrolize',
            fontSize: windowWidth * 0.04,
            filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

        }} />)
    }

    const trendsIcon = () => {
        return (<TimelineIcon
            style={{
                color: disableColor,
                fontFamily: 'Electrolize',
                fontSize: windowWidth * 0.04,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

            }} />)
    }

    const campaignIcon = () => {
        return (<TableChartIcon
            style={{
                color: disableColor,
                fontFamily: 'Electrolize',
                fontSize: windowWidth * 0.04,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

            }} />)
    }

    const cohortIcon = () => {
        return (<PivotTableChartIcon
            style={{
                color: disableColor,
                fontFamily: 'Electrolize',
                fontSize: windowWidth * 0.04,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

            }} />)
    }

    const budgetIcon = () => {
        return (<AccountBalanceIcon
            style={{
                color: disableColor,
                fontFamily: 'Electrolize',
                fontSize: windowWidth * 0.04,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

            }} />)
    }

    const analyticsIcon = () => {
        return (<AnalyticsIcon
            style={{
                color: disableColor,
                fontFamily: 'Electrolize',
                fontSize: windowWidth * 0.04,
                filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',

            }} />)
    }


    const renderContent = () => {
        if (currentStep === 0) {
            return <View style={styles.container}>
                <View style={{ width: '100%', height: '30%', flexDirection: 'row', borderColor: 'grey', borderWidth: 1, padding: '1%', filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))', overflow:'auto'}}>
                <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={newhandler}
                            templateId={1}
                            disableColor={disableColor}
                            text={'Create New Dashboard'}
                            icon={addIcon}
                            buttonText={'Create'}
                        />
                    </View>
                    <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={templateLimitHandler}
                            templateId={2}
                            disableColor={disableColor}
                            text={'Trends Template'}
                            icon={trendsIcon}
                            buttonText={'Add'}
                        />
                    </View>
                    <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={templateLimitHandler}
                            templateId={1}
                            disableColor={disableColor}
                            text={'Campaign Template'}
                            icon={campaignIcon}
                            buttonText={'Add'}
                        />
                    </View>
                    {!isNotAtt && <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={templateLimitHandler}
                            templateId={3}
                            disableColor={disableColor}
                            text={'Cohorts ROAS Template'}
                            icon={cohortIcon}
                            buttonText={'Add'}
                        />
                    </View>}
                    <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={templateLimitHandler}
                            templateId={4}
                            disableColor={disableColor}
                            text={'Budget Template'}
                            icon={budgetIcon}
                            buttonText={'Add'}
                        />
                    </View>
                    
                    {!isNotAtt && <View style={{ width: '15%', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0.5%'}}>
                        <TemplateCard
                            theme={theme}
                            clickHandler={templateLimitHandler}
                            templateId={5}
                            disableColor={disableColor}
                            text={'Analytics Template'}
                            icon={analyticsIcon}
                            buttonText={'Add'}
                        />
                    </View>}

                    

                </View>

                <View style={{ width: '100%', height: '70%', padding: '2%', flexDirection: 'column', alignItems: 'center' }}>
                    <View style={{ width: '90%', height: '8%', flexDirection: 'row' , filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))'}}>
                        <View style={{ backgroundColor: theme.backgroundColor2, width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                            <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: 'white' }}>
                                Name
                            </Text>
                        </View>
                        <View style={{ backgroundColor: theme.backgroundColor2, width: '20%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                            <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: 'white' }}>
                                Dataset
                            </Text>
                        </View>
                        <View style={{ backgroundColor: theme.backgroundColor2, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                            <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: 'white' }}>
                                Edit
                            </Text>
                        </View>
                        <View style={{ backgroundColor: theme.backgroundColor2, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                            <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: 'white' }}>
                                View
                            </Text>
                        </View>
                        <View style={{ backgroundColor: theme.backgroundColor2, width: '10%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: 'grey', borderWidth: 1 }}>
                            <Text style={{ fontFamily: 'Electrolize', fontSize: windowWidth * 0.012, color: 'white' }}>
                                Delete
                            </Text>
                        </View>
                    </View>
                    {Object.entries(customReports).map(([reportName, reportDetails], index) => (
                        reportMap({ reportName, reportDetails, index })
                    ))}
                    {reportUnderLimit && 
                    <View style={{width: '100%', height: '7%', alignItems: 'center', justifyContent: 'center', padding: '1%'}}>
                        <View style={{height: 30}}></View>
                    <Text style={{fontFamily: 'Electrolize', color: 'red', fontWeight: 600, filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.0))'}} >
                        You have reached the limit of 10 saved reports
                    </Text>
                    <Text style={{fontFamily: 'Electrolize', color: 'red', fontWeight: 600, filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.0))'}} >
                        Please either re-use slots or open a Support case to extend limits
                    </Text>
                    </View>}

                </View>

            </View>
        } else if (currentStep === 1) {
            return <StudioCreate
                theme={theme}
                currentMetrics={currentMetrics}
                configArray={configArray}
                setCurrentStep={setCurrentStep}
                customReports={customReports}
                setUpdate={setUpdate}
                schemaData={schemaData}
            />
        } else if (currentStep === 2) {
            return <StudioEdit
                theme={theme}
                currentMetrics={currentMetrics}
                configArray={configArray}
                setCurrentStep={setCurrentStep}
                customReports={customReports}
                setUpdate={setUpdate}
                currentItem={currentItem}
            />
        } else if (currentStep === 3) {
            return <StudioPreview
                theme={theme}
                currentMetrics={currentMetrics}
                configArray={configArray}
                setCurrentStep={setCurrentStep}
                customReports={customReports}
                setUpdate={setUpdate}
                currentItem={currentItem}
                schemaData={schemaData}
                currentStep={currentStep}
            />
        } else if (currentStep === 4) {
            return <StudioTemplate
                theme={theme}
                currentMetrics={currentMetrics}
                configArray={configArray}
                setCurrentStep={setCurrentStep}
                customReports={customReports}
                setUpdate={setUpdate}
                currentItem={currentItem}
                schemaData={schemaData}
                template={template}
                templateTable={templateTable}
            />
        }
    }

    return (
        <View style={styles.container}>
            {renderContent()}
                <StudioDelete
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    theme={theme}
                    deleteHandler={deleteHandler}
                    currentItem={currentItem}
                />
        </View>

    )

}

export default StudioPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',

    }
})