import React, { useRef , useEffect, useState} from 'react';
import { Line , Tooltip} from 'react-chartjs-2';
import WebFont from 'webfontloader';
import { Dimensions } from 'react-native'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../../Components/Download';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AttEnhancedTrendsLine = (props) => {
  const chartRef = useRef(null);
  const agg_daily_result = props.agg_daily_result || []
  const labels = props.dateLabels
  const theme = props.theme
  const metric = props.metric;
  const currentMetrics = props.currentMetrics
  const large = props.large || false
  const [contextMenu, setContextMenu] = useState(null);
  const breakdown = props.breakdown

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        :
          null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  let convLabel

  if (metric === 'ftds') {
    convLabel = currentMetrics.primaryMetric
  } else if (metric === 'ftd') {
    convLabel = currentMetrics.primaryMetric
  }else if (metric === 'ngr') {
    convLabel = currentMetrics.primaryRevenue
  } else if (metric === 'regs') {
    convLabel = currentMetrics.secondaryMetric
  } else {convLabel = metric}

  let responseText;

  if (large) {
    responseText = windowWidth * 0.01
  } else if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowWidth * 0.01
  } else {responseText = windowHeight * 0.01}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

   function toProperCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const downloadHandler = () => {
    Download(agg_daily_result)
    handleClose()
  
  }

  let dymamicDatasets = []

  function generateColor(index) {
    const colors = [theme.gradc10, theme.gradc30, theme.gradc50, theme.gradc80, theme.gradc100]; // Define your color palette
    return colors[index % colors.length];  // Cycle through the colors using modulo
}



  agg_daily_result.forEach((dataset, index) => {

    const dailyItem = dataset.map((obj) => obj[metric]);
    const countryItem = dataset[0][breakdown]
    const max = agg_daily_result.length
    const current = (index + 1) * ((index + 1) * 10)
    const gradIndex = ((current))
    const graVar = theme[`gradc${gradIndex}`]

    dymamicDatasets.push({
        label: toProperCase(countryItem),
        data: dailyItem,
        fill: true,
        borderColor: graVar,
        backgroundColor: generateColor(index),
        lineTension: 0.5,
        radius: 3
    })

  })



  const data = {
    labels: labels,
    datasets: dymamicDatasets,
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false, // Remove the gridlines on the y-axis
        },
        ticks: {
          callback: (value, index, values) => {
            if (metric === 'ggr' || metric === 'spend' || metric === 'cpa' || metric === 'ngr') {
                return new Intl.NumberFormat('en-EN', { // Note: Typically, locale would be 'en-US' or other specific locales for Euro.
                    style: 'currency',
                    currency: 'EUR',
                    maximumSignificantDigits: 3
                }).format(value);
            } else if (metric === 'ggr_percent' || metric === 'bet_percent') {
                return new Intl.NumberFormat('en-EN', {
                    style: 'percent',
                    minimumFractionDigits: 2, // Adjust number of decimal places as needed
                    maximumFractionDigits: 2
                }).format(value);
            } else {
                return value;
            }
        },
          color : theme.color,
          font: {
            size: responseText, // Set the font size for y-axis labels
            //weight: 'bold', // Set the font weight for y-axis labels
            family : 'Electrolize',
          },
        },
      },
      x: {
        grid: {
          display: false, // Remove the gridlines on the x-axis
        },
        ticks: {
          color : theme.color,
          font: {
            size: responseText, // Set the font size for x-axis labels
            family : 'Electrolize'
          },
        },
      },
    },
    tooltips: {
      enabled: true,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        text: 'Chart Title',
        labels: {
          color : theme.color,
          // This more specific font property overrides the global property
          font: {
              size: responseText,
              family : 'Electrolize'
          }
      }
      },
      tooltip: {
        mode: 'index'
      },
      title: {
        display: false,
        text: 'Chart Title',
        font: {
          size: 24, // Set the font size for the chart title
          weight: 'bold', // Set the font weight for the chart title
  
        },
      },
    },
  };


  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%' }}>
      <Line 
        ref={chartRef} 
        data={data}
        options={options} 
      />
      <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem style={{fontFamily : 'Electrolize'}} onClick={downloadHandler}>Download Data</MenuItem>
        </Menu>
    </div>);
};

export default AttEnhancedTrendsLine;
