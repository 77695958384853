import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import BudgetArea from '../../Budgets/BudgetArea';
import BarChart from '../../ChartsDesktop/BarChart';
import MixedChart from '../../ChartsDesktop/MixedChart';
import ChartBuilderTable from '../ChartBuilderTable';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ChartBuilderBudgetModal from './ChartBuilderBudgetModal';
import ChartBuilderSaveModal from '../ChartBuilderModals/ChartBuilderSaveModal';
import ChartBuilderOpenModal from '../ChartBuilderModals/ChartBuilderOpenModal';
import ChartBuilderDeleteModal from '../ChartBuilderModals/ChartBuilderDeleteModal';
import LoadingComponent from '../../Components/Loader';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ChartBuilderBudget = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const client = props.client
    const config = props.config.currentDataset    
    const panelContent = props.config.panelContent
    const [isCumulative, setIsCumulative] = useState(false)
    const [checked, setChecked] = React.useState(false);    
    const [settingsOpen, setSettingsOpen] =  useState(false)
    const [saveOpen, setSaveOpen] = useState(false)
    const [openOpen, setOpenOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [currentConfig, setCurrentConfig] = useState(config)    

    const currentConfigStorage = JSON.parse(localStorage.getItem('chartConfig'))
    const reportList = Object.keys(currentConfigStorage)
    const lastChartUsed = localStorage.getItem('lastChart')

    if (!lastChartUsed) {
        localStorage.setItem('lastChart', 'default')
    }

    const type = currentConfig.type
    const dimension = currentConfig.dimension
    const metric1 = currentConfig.metric1
    const metric2 = currentConfig.metric2
    const isDate = dimension === 'calendar_date'

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
        
      };   

    const saveHandler = (textValue) => {
        let storagePush;        

        if(reportList.includes(textValue)) {
            const configCopy = currentConfigStorage
            configCopy[lastChartUsed][panelContent] = currentConfig
            localStorage.setItem('chartConfig', JSON.stringify(configCopy))
            localStorage.setItem('lastChart', textValue)
            //push to storage
        } else if (panelContent === 'budget') {
            storagePush = {
                [textValue]: {
                    time: {
                        dataset: 'time',
                        type: 'line',
                        dimension: 'day',
                        metric1: 'spend',
                        metric2: 'cpa'
                    },
                    adgroup: {
                        dataset: 'adgroup',
                        type: 'table',
                        dimension: 'adgroup',
                        metric1: 'spend',
                        metric2: 'ftds'
                    },
                    budget: {
                        dataset: 'budget',
                        type: type,
                        dimension: dimension,
                        metric1: metric1,
                        metric2: metric2
                    }
        
                }
            }
            const storageUpdate = {...currentConfigStorage, ...storagePush}
            
            localStorage.setItem('chartConfig', JSON.stringify(storageUpdate))
            localStorage.setItem('lastChart', textValue)
        }

    }

    const openHandler = (value) => {
        const openConfig = currentConfigStorage[value][panelContent]
        setCurrentConfig(openConfig)
        localStorage.setItem('lastChart', value)
    }

    const deleteHandler = (value) => {

        function filterObjectByKey(obj, keyToRemove) {
            const filteredObject = {};
            Object.keys(obj).forEach(key => {
                if (key !== keyToRemove) {
                    filteredObject[key] = obj[key];
                }
            });
        
            return filteredObject;
        }

        const newReportList = filterObjectByKey(currentConfigStorage, value);

        if (value === lastChartUsed) {
            localStorage.setItem('chartConfig', JSON.stringify(newReportList))            
            localStorage.setItem('lastChart', 'default')
            openHandler('default')
            
        } else {
            localStorage.setItem('chartConfig', JSON.stringify(newReportList))  
        }
    }
      
      const headerToggleContainer = {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
        fontWeight: 'bold',
        color: theme.color,
        fontSize: windowWidth * 0.01
    }

    function aggregateData(data, dimension) {
        const groupedData = {};
    
        data.forEach(obj => {
            const dateObject = obj[dimension]
            
            if (!groupedData[dateObject]) {
                groupedData[dateObject] = {
                    [dimension]: obj[dimension],
                    budget: 0,
                    actual_spend: 0,
                    current_dom: obj.current_dom,
                    days_in_month: obj.days_in_month,
                    days_remaining: obj.days_remaining,
                    yesterday_spend: 0,
                    reg: 0,
                    ftd: 0,
                    ngr: 0,
                };
            }
            groupedData[dateObject].budget += parseInt(obj.budget);
            groupedData[dateObject].actual_spend += parseInt(obj.actual_spend);
            groupedData[dateObject].yesterday_spend += parseFloat(obj.yesterday_spend);
            groupedData[dateObject].reg += parseInt(obj.reg);
            groupedData[dateObject].ftd += parseInt(obj.ftd);
            groupedData[dateObject].ngr += parseFloat(obj.ngr);
        });
    
        // Calculate averages for CPC, CPA, ROAS
        const result = Object.values(groupedData).map(entry => {
            entry.cpa = entry.ftds > 0 ? (entry.spend / entry.ftds) : 0;

            return entry;
        });
    
        return result;
    }

    const agg_resultBase = aggregateData(dataProp, dimension)

    const agg_result = agg_resultBase.map((obj) => {
        const actual_spend = obj.actual_spend;
        const ftd = obj.ftd;
        const budget = obj.budget;
        const current_dom = obj.current_dom
        const days_in_month = obj.days_in_month
        const days_remaining = parseInt(obj.days_remaining)
        const cpa = actual_spend === 0 || ftd === 0 || isNaN(ftd) ? 0 : actual_spend / ftd;
        const spend_rate = (actual_spend / budget).toFixed(2)
        const totalSpend = ((budget / current_dom) * days_in_month)
        const remainingSpend = (totalSpend - actual_spend)
        const target_daily = parseFloat(remainingSpend / (days_remaining + 1)).toFixed(2) || 0.0
        return {
            ...obj,
            cpa: parseInt(cpa),
            spend_rate: parseFloat(isFinite(spend_rate) ? spend_rate : 0),
            target_daily: target_daily
        };
    });
    console.log(agg_result)
 
    const calcCumulative = (data) => {
        let cumubudget = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0, cumuNgr = 0, cumuYesterday = 0;

        return data.map(item => {

            const current_dom = item.current_dom
            const days_in_month = item.days_in_month
            const days_remaining = parseInt(item.days_remaining)
            cumubudget += item.budget;
            cumuYesterday += item.yesterday_spend
            cumuSpend += item.actual_spend;
            cumuRegs += item.reg;
            cumuFtds += item.ftd;
            cumuNgr += item.ngr;
            const totalSpend = ((cumubudget / current_dom) * days_in_month)
            const remainingSpend = (totalSpend - cumuSpend)
            const target_daily = parseFloat(remainingSpend / (days_remaining + 1)).toFixed(2) || 0.0

            return {
                [dimension]: item[dimension],
                budget: cumubudget,
                actual_spend: cumuSpend,
                yesterday_spend: cumuYesterday,                
                reg: cumuRegs,
                ftd: cumuFtds,
                ngr: cumuNgr,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0,
                spend_rate: (cumuSpend / cumubudget).toFixed(2),
                target_daily: target_daily,
                days_in_month: item.days_in_month,
                days_remaining: item.days_remaining,
                current_dom: item.current_dom
            }
        })
    }
    
    const cumuData = calcCumulative(agg_result)

    let dynamicData;

    if (isCumulative) {
    dynamicData = cumuData
    } else {
    dynamicData = agg_result
    }

    if (!agg_result[0]) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const configKeys = Object.keys(agg_result[0])

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(agg_result, dimension)

    const renderComponentContent = () => {
        if (type === 'area') {
            return <BudgetArea
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric1={metric1}
                        metric2={metric2}
                        timeFrame={'daily'}
                        currentMetrics={currentMetrics}
                    />;
        } else if (type === 'bar') {
                    return <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={metric1}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                />;
        } else if (type === 'mixed') {
                    return <MixedChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={metric1}
                    metric2={metric2}
                    timeFrame={'daily'}
                    client={client}
                    currentMetrics={currentMetrics}
                />
        } else if (type === 'table') {
                    return <ChartBuilderTable
                    dataProp={dynamicData}
                    theme={theme}
                    currentMetrics={currentMetrics}
                    currentDim={'date'}
                    isBudget={true}
                    isCumulative={isCumulative}
                />
        } else {
            return null;
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerRow}>
            <View style={styles.textContainer}>
                <Text style={{
                    fontSize: windowWidth * 0.01,
                    fontFamily: 'Electrolize',
                    fontWeight: 'bold',
                    color: theme.color,
                }}>
                    Current Template: {lastChartUsed}
                </Text>

            </View>
            {isDate && <View style={styles.buttonContainer}>
            
            <View style={headerToggleContainer}>
                    <Text style={cumuText}>Cumulative:</Text>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />

                    </View>

            </View>}
            
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setDeleteOpen(true)}
                    variant="contained">
                    Delete
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setOpenOpen(true)}
                    variant="contained">
                    Open
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setSaveOpen(true)}
                    variant="contained">
                    Save
                </Button>

            </View>
            <View style={styles.buttonContainer}>
                <Button 
                    sx={{
                        backgroundColor: theme.backgroundColor2,
                        fontFamily: 'Electrolize',
                        width: '100%',
                        height: '100%',
                        fontSize: windowWidth * 0.007
                    }}
                    onClick={() => setSettingsOpen(true)}
                    variant="contained">
                    Settings
                </Button>

            </View>

            </View>
            <View style={styles.chartRow}>
                {renderComponentContent()}
            </View>
            <ChartBuilderBudgetModal
                theme={theme}
                open={settingsOpen}
                setOpen={setSettingsOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                currentMetrics={currentMetrics}
            />
            <ChartBuilderSaveModal
                theme={theme}
                open={saveOpen}
                setOpen={setSaveOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                saveHandler={saveHandler}
            />
            <ChartBuilderOpenModal
                theme={theme}
                open={openOpen}
                setOpen={setOpenOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                openHandler={openHandler}
            />
            <ChartBuilderDeleteModal
                theme={theme}
                open={deleteOpen}
                setOpen={setDeleteOpen}
                setCurrentConfig={setCurrentConfig}
                lastChartUsed={lastChartUsed}
                panelContent={panelContent}
                currentConfig={currentConfig}
                configKeys={configKeys}
                reportList={reportList}
                deleteHandler={deleteHandler}
            />

        </View>

    )

}

export default ChartBuilderBudget

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.83,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    headerRow: {
        height: '7%',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'right',
        //position: 'absolute',
        //left: 0,
        //top: 0,
        //zIndex: 100000,
        borderWidth: 0.1,
        borderColor: 'grey',
        padding: '0.5%'
    },
    chartRow: {
        height: '93%',
        width: '100%',
        padding: '1%'
    },
    buttonContainer: {
        height: '100%',
        width: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.2%'
    },
    textContainer: {
        height: '100%',
        width: '20%',
        //alignItems: 'center',
        justifyContent: 'center',
        padding: '1%',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 100000,
    }

})