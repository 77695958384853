import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StudioEditDimDefinitions from './StudioEditDimDefinitions';

import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;


export default function StudioEditDim(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const customArray = props.customArray
    const dimensions = props.dimensions
    const dimArray = props.dimArray
    const setDimArray = props.setDimArray
    const currentDim = props.currentDim
    const currentDimIndex = props.currentDimIndex
    const setCurrentDimIndex = props.setCurrentDimIndex

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{width: '100%'}}>
                <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor, color: theme.color}}>Custom Dimensions</DialogTitle>
                <DialogContent sx={{
                    backgroundColor: theme.backgroundColor
                }}>
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>
                        <StudioEditDimDefinitions
                            theme={theme}
                            dimensions={dimensions}
                            customArray={customArray}
                            setOpen={setOpen}
                            open={open}
                            dimArray={dimArray}
                            setDimArray={setDimArray}
                            currentDim={currentDim}
                            currentDimIndex={currentDimIndex}
                            setCurrentDimIndex={setCurrentDimIndex}
                        />

                    </Box>

                </DialogContent>
                </View>

            </Dialog>
        </div>

    );
}