import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;


export default function StudioPreviewModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const controlArray = props.controlArray
    const savehandler = props.savehandler
    const filterType1 = controlArray.filterType1
    const filterType2 = controlArray.filterType2
    const filterType3 = controlArray.filterType3
    const customDimensions = controlArray.customDimensions
    const customMeasures = controlArray.customMeasures
    const timeValue = controlArray.timeValue
    const [textValue, setTextValue] = useState('')
    const [value1, setValue1] = useState(filterType1)
    const [value2, setValue2] = useState(filterType2)
    const [value3, setValue3] = useState(filterType3)
    const [value4, setValue4] = useState(timeValue)
    const timeList = ['daily', 'weekly', 'monthly', 'yearly']

    const updateArray = {
        value1,
        value2,
        value3,
        value4
    }

    const excludeRoutes = ['updated_at', 'timestamp']

    const filterList = customDimensions.filter((item) => {
        return !excludeRoutes.includes(item);
    });

    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        
        setOpen(false);
    };

    const handleSave = (value) => {
        savehandler(updateArray)
        setOpen(false);
    }

    const options = filterList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const timeOptions = timeList.map((item, index) => (
        <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
            {item}
        </MenuItem>
    ));

    const filterHandler = (value, index) => {

        if (index === 0) {
            setValue1(value)
        } else if (index === 1) {
            setValue2(value)
        } else if (index === 2) {
            setValue3(value)
        } else if (index === 3) {
            setValue4(value)
        }

    }

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{ width: '100%' }}>
                    <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor, color: theme.color }}>Report Config</DialogTitle>
                    <DialogContent sx={{
                        backgroundColor: theme.backgroundColor
                    }}>
                        <Box component="form" sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                                <FormHelperText sx={{color: theme.color}} >Select Filter 1</FormHelperText>
                                <Select
                                    labelId="demo-dialog-select-label1"
                                    id="demo-dialog-select"
                                    value={value1}
                                    sx={{fontFamily: 'Electrolize', backgroundColor: 'white',
                                        color: theme.color}}

                                    onChange={(event) => filterHandler(event.target.value, 0)}
                                    input={<OutlinedInput />}
                                >
                                    {options}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                            <FormHelperText sx={{color: theme.color}} >Select Filter 2</FormHelperText>
                                <Select
                                    labelId="demo-dialog-select-label2"
                                    id="demo-dialog-select"
                                    value={value2}
                                    sx={{fontFamily: 'Electrolize', backgroundColor: 'white',
                                        color: theme.color}}

                                    onChange={(event) => filterHandler(event.target.value, 1)}
                                    input={<OutlinedInput />}
                                >
                                    {options}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, fontFamily: 'Electrolize', }}>
                            <FormHelperText sx={{color: theme.color}} >Select Filter 3</FormHelperText>
                                <Select
                                    labelId="demo-dialog-select-label3"
                                    id="demo-dialog-select"
                                    value={value3}
                                    sx={{fontFamily: 'Electrolize', backgroundColor: 'white',
                                        color: theme.color}}

                                    onChange={(event) => filterHandler(event.target.value, 2)}
                                    input={<OutlinedInput />}
                                >
                                    {options}
                                </Select>
                            </FormControl>

                        </Box>
                    </DialogContent>
                    <DialogActions sx={{backgroundColor: theme.backgroundColor}} >
                        <Button sx={{color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize'}} onClick={handleClose}>Cancel</Button>
                        <Button sx={{color: theme.backgroundColor2, fontWeight: 600, fontFamily: 'Electrolize'}} onClick={handleSave}>Update</Button>
                    </DialogActions>
                </View>

            </Dialog>
        </div>

    );
}