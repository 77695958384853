import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import CustomDailyParse from './CustomDailyParse';
import CustomDailyModal from './CustomDailyModal';
import FilterSummary from '../../ComponentsEnhanced/FilterContainers/FilterSummary';
import PanelsText from '../../Components/PanelsText';
import BarChart from '../../ChartsDesktop/BarChart';
import MixedChart from '../../ChartsDesktop/MixedChart';
import LineChart from '../../ChartsDesktop/LineChart';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;


const CustomDailyPanels = (props) => {

    const client = props.client
    const agg_daily_result = props.agg_daily
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const filterType1 = filterParams.filterType1
    const filterType2 = filterParams.filterType2
    const filterType3 = filterParams.filterType3
    const setFilterType1 = filterParams.setFilterType1
    const setFilterType2 = filterParams.setFilterType2
    const setFilterType3 = filterParams.setFilterType3
    const customDimensions = filterParams.customDimensions
    const timeValue = props.timeValue
    const setTimevalue = props.setTimeValue
    const currentData = props.agg_daily
    const brandLabels = props.brandLabels
    const [selectedComponent, setSelectedComponent] = useState("Row2_Mixed1");
    const [checked, setChecked] = React.useState(false);
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const lastUpdated = props.lastUpdated
    const [isCumulative, setIsCumulative] = useState(false)
    const [open, setOpen] = useState(false)

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    

    const controlArray = {
        filterType1,
        setFilterType1,
        filterType2,
        setFilterType2,
        filterType3,
        setFilterType3,
        timeValue,
        setTimevalue,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        customDimensions
    }

    const dailyAgg = CustomDailyParse(agg_daily_result, 'date', cubeTable, timeValue)

    const calcCumulative = (data) => {
        let cumuImps = 0, cumuClicks = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0, cumuFtdNgr = 0, cumuRetentionNgr = 0, cumuDeposits = 0, cumuDepositAmount = 0, cumuReact = 0;

        return data.map(item => {
            cumuImps += item.impressions;
            cumuClicks += item.clicks;
            cumuSpend += item.spend;
            cumuRegs += item.regs;
            cumuFtds += item.ftds;
            cumuFtdNgr += item.ftd_ngr;
            cumuRetentionNgr += item.retention_ngr;
            cumuDeposits += item.deposits;
            cumuDepositAmount += item.deposit_amount;
            cumuReact += item.reactivations;

            return {
                date: item.date,
                impressions: cumuImps,
                clicks: cumuClicks,
                spend: cumuSpend,
                regs: cumuRegs,
                ftds: cumuFtds,
                ftd_ngr: cumuFtdNgr,
                retention_ngr: cumuRetentionNgr,
                deposits: cumuDeposits,
                deposit_amount: cumuDepositAmount,
                reactivations: cumuReact,
                cpc: cumuImps > 0 ? cumuClicks / cumuImps : 0,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0,
                roas: cumuFtdNgr > 0 ? cumuFtdNgr / cumuSpend : 0,
            }
        })
    }


    const cumuData = calcCumulative(dailyAgg)

    let dynamicData;

    if (isCumulative) {
        dynamicData = cumuData
    } else {
        dynamicData = dailyAgg
    }

    const dateLabels = getUniqueValues(dynamicData, 'date')

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)

    };

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    const savehandler = (updateArray) => {

        setFilterType1(updateArray.value1)
        setFilterType2(updateArray.value2)
        setFilterType3(updateArray.value3)
        setTimevalue(updateArray.value4)
    }

    const panelMain = {

        width: '66.666666666%',
        height: windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',


    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "8%"
        rightValue = "88%"
    } else {
        topValue = "12.5%"
        rightValue = "88%"
    }


    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
    }

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
            return <Row1Line1 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line2') {
            return <Row1Line2 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line3') {
            return <Row1Line3 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar1') {
            return <Row2Bar1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Mixed1') {
            return <Row2Mixed1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar2') {
            return <Row2Bar2 style={panelMain} />;
        } else {
            return null;
        }
    };

    const Row1Line1 = () => {

        return (
            <View style={lineChart}>
                <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={'spend'}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />

            </View>)
    }

    const Row1Line2 = () => {

        return (
            <View style={lineChart}>
                <BarChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    metric={'ftds'}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }


    const Row1Line3 = () => {

        return (
            <View style={lineChart}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={"cpa"}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row2Bar1 = () => {
        return (
            <View style={lineChart}>
                <LineChart
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    theme={theme}
                    metric={"regs"}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row2Mixed1 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'spend'}
                    metric2={'ftds'}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }

    const Row2Bar2 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={dateLabels}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'cpc'}
                    metric2={'cpa'}
                    timeFrame={timeValue}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>)
    }



    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterSummary
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            setOpen={setOpen}
                            isCumulative={isCumulative}
                            setIsCumulative={setIsCumulative}

                        />
                    </View>

                    {renderComponentContent()}

                </View>
                <PanelsText
                    style={{ width: '100%' }}
                    theme={theme}
                    dataProp={agg_daily_result}
                    client={client}
                    currentMetrics={currentMetrics}
                />
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
                    <View style={styles.lineChart}>
                        <BarChart
                            dataProp={dynamicData}
                            labels={dateLabels}
                            theme={theme}
                            metric={'spend'}
                            timeFrame={timeValue}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
                    <View style={styles.lineChart}>
                        <BarChart
                            dataProp={dynamicData}
                            labels={dateLabels}
                            theme={theme}
                            metric={'ftds'}
                            timeFrame={timeValue}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
                    <View style={styles.lineChart}>
                        <LineChart
                            agg_daily_result={dynamicData}
                            dateLabels={dateLabels}
                            theme={theme}
                            metric={"cpa"}
                            timeFrame={timeValue}
                            client={client}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                </Pressable>

            </View>

            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
                    <LineChart
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric={"regs"}
                        timeFrame={timeValue}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={dateLabels}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'spend'}
                        metric2={'ftds'}
                        timeFrame={timeValue}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={dateLabels}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpc'}
                        metric2={'cpa'}
                        timeFrame={timeValue}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
                </Pressable>

            </View>
            <CustomDailyModal
                theme={theme}
                open={open}
                setOpen={setOpen}
                controlArray={controlArray}
                savehandler={savehandler}
            />

        </View>

    )

}

export default CustomDailyPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93) * 1.3,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'   
    },
    

})