import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import OptionsGroupingLoader from '../OptionsGrouping/OptionsGroupingDefinitions/OptionsGroupingLoader';
import OptionsMetricChild from './OptionsMetricChild';
import { postToSignedUrl } from '../OptionsGrouping/OptionsGroupingDefinitions/OptionsGroupingGET';
import signRequest from '../OptionsGrouping/OptionsGroupingDefinitions/OptionsGroupingGET';
import OptionsMetricEdit from './OptionsMetricEdit';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PreviewIcon from '@mui/icons-material/Preview';
import SendIcon from '@mui/icons-material/Send';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const OptionsMetrics = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const setCurrentView = props.setCurrentView
    const customGroupings = configArray.customGroupings
    const customModel = configArray.customModel
    const baseResponse = {
        data: {
            custom_groupings: customGroupings,
            custom_model: customModel
        }
    }
    const client = configArray.clientID
    const clientMapId = configArray.clientMapId
    const support_url = configArray.config_url
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const auth0_user_id = configArray.auth0_user_id

    //const updateArray = props.updateArray
    const currentMetrics = props.currentMetrics
    const cubeTable = props.cubeTable
    const [preview, setPreview] = useState(false)
    const [edit, setEdit] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const editBase = {
        "child": {
            "name": "test",
            "value": "impressions",
            "format": "integer",
            "function": "sum"
        },
        "index": 0
    }
    const [currentEdit, setCurrentEdit] = useState(editBase)
    const custom_metrics = baseResponse.data.custom_groupings.custom_metrics || []
    const custom_metrics_aliases = baseResponse.data.custom_groupings.custom_metrics_aliases || []
    const custom_metrics_format = baseResponse.data.custom_groupings.custom_metrics_format || []
    const custom_metrics_function = baseResponse.data.custom_groupings.custom_metrics_function || []

    const [metricState, setMetricState] = useState(custom_metrics)
    const [metricAliasState, setMetricAliasState] = useState(custom_metrics_aliases)
    const [metricFormatState, setMetricFormatState] = useState(custom_metrics_format)
    const [metricFunctionState, setMetricFunctionState] = useState(custom_metrics_function)
    const [saveResponse, setSaveResponse] = useState(null)
  
    const sendDataToLambda = async (event_data, support_url, support_key, support_id, method) => {
        setSaveLoading(true); // Start loading
        setSaveResponse([]); // Clear previous response

        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setSaveResponse(result)
            //setSaveLoading(false);
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            setSaveLoading(false);
        }
    };

    function createCustomMetricsJson(aliases, metrics, format, functions) {
        if (aliases.length !== metrics.length) {
            throw new Error("Aliases and metrics arrays must have the same length.");
        }

        return aliases.map((alias, index) => ({
            name: alias,
            value: metrics[index],
            format: format[index],
            function: functions[index]
        }));
    }

    const metricsJsonBase = createCustomMetricsJson(custom_metrics_aliases, custom_metrics, custom_metrics_format, custom_metrics_function)
    const [metricsJson, setMetricsJson] = useState(metricsJsonBase)
    
    const [comparisonCopy, setComparisonCopy] = useState(metricsJson)
    const isSameBase = JSON.stringify(metricsJson) === JSON.stringify(comparisonCopy)    
    const isEmpty = metricsJson.length === 0
    const metricLessThan5 = metricsJson.length > 4

    const saveHandler = () => {
        
        const baseCopy = JSON.parse(JSON.stringify(baseResponse));
        baseCopy.data.custom_groupings.custom_metrics = metricState
        baseCopy.data.custom_groupings.custom_metrics_aliases = metricAliasState
        baseCopy.data.custom_groupings.custom_metrics_format = metricFormatState
        baseCopy.data.custom_groupings.custom_metrics_function = metricFunctionState

        const event_data = {
            client: client,
            client_id: clientMapId,
            auth0_user_id: auth0_user_id,
            "request_type": "custom_configs_update",
            "configData": baseCopy.data,
        }
        console.log(event_data)

        sendDataToLambda(event_data, support_url, support_key, support_id, 'POST')
        const metricsJsonBase = createCustomMetricsJson(baseCopy.data.custom_groupings.custom_metrics_aliases,
            baseCopy.data.custom_groupings.custom_metrics, 
            baseCopy.data.custom_groupings.custom_metrics_format, 
            baseCopy.data.custom_groupings.custom_metrics_function)
        setComparisonCopy(metricsJsonBase)

    }

    const previewHandler = () => {
        console.log("preview")
    }

    const editHandler = (child, index) => {
        const currentItem = {
            child,
            index
        }
        setCurrentEdit(currentItem)

    }

    const updateHandler = (newChild, index) => {
        const newNameValue = newChild.name
        const newValueValue = newChild.value
        const newFormatValue = newChild.format
        const newFunctionValue = newChild.function

        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const metricsBaseCopy = [...metricState]
        const aliasBaseCopy = [...metricAliasState]
        const formatBaseCopy = [...metricFormatState]
        const functionBaseCopy = [...metricFunctionState]

        baseJsonCopy[index] = newChild
        aliasBaseCopy[index] = newNameValue
        metricsBaseCopy[index] = newValueValue
        formatBaseCopy[index] = newFormatValue
        functionBaseCopy[index] = newFunctionValue

        setMetricsJson(baseJsonCopy)
        setMetricState(metricsBaseCopy)
        setMetricAliasState(aliasBaseCopy)
        setMetricFormatState(formatBaseCopy)
        setMetricFunctionState(functionBaseCopy)
        setEdit(false)
    }

    function getValuesByKey(data, key) {
        return data.map(item => item[key]);
    }
    const deleteHandler = (child, itemIndex) => {
        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const newList = baseJsonCopy.filter((_, index) => index !== itemIndex);
        
        const newNameValue = getValuesByKey(newList, 'name')
        const newValueValue = getValuesByKey(newList, 'value')
        const newFormatValue = getValuesByKey(newList, 'format')
        const newFunctionValue = getValuesByKey(newList, 'function')

        setMetricsJson(newList)
        setMetricState(newValueValue)
        setMetricAliasState(newNameValue)
        setMetricFormatState(newFormatValue)
        setMetricFunctionState(newFunctionValue)
    }

    const addCondition = () => {
        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const metricsBaseCopy = [...metricState]
        const aliasBaseCopy = [...metricAliasState]
        const formatBaseCopy = [...metricFormatState]
        const functionBaseCopy = [...metricFunctionState]
        const newIndex = Math.floor(100000 + Math.random() * 900000);

        const newItem = {
            "name": `new_metric_${newIndex}`,
            "value": "impressions",
            "format": "integer",
            "function": "sum"
        }

        const newJson = [...baseJsonCopy, newItem];
        const newMetrics = [...metricsBaseCopy, newItem.value];
        const newAliases = [...aliasBaseCopy, newItem.name];
        const newFormat = [...formatBaseCopy, newItem.format]
        const newFunctions = [...functionBaseCopy, newItem.function]

        setMetricsJson(newJson)
        setMetricState(newMetrics)
        setMetricAliasState(newAliases)
        setMetricFormatState(newFormat)
        setMetricFunctionState(newFunctions)
    }

    const EmptyRow = () => {

        return (<View style={{ width: '100%', height: windowHeight * 0.06, justifyContent: 'center', alignItems: 'center', borderColor: 'grey', borderWidth: 1 }}>
            <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 600 }}>
                Add a metric to get started...
            </Text>
        </View>)

    }

    const UnsavedChanges = () => {
        return (<View style={{ width: '100%', height: windowHeight * 0.06, justifyContent: 'center', alignItems: 'center', borderColor: 'grey', borderWidth: 0, padding: '1%' }}>
            <Text style={{ color: 'red', fontFamily: 'Electrolize', fontSize: windowWidth * 0.009, textAlign: 'end', fontWeight: 600, width: '100%' }}>
                You have unsaved changes...
            </Text>
        </View>)
    }

    const parentView = () => {

        if (!preview) {
            return (
                <View style={{ width: '90%', margin: '2%' }}>
                    <View style={{
                        height: windowHeight * 0.06,
                        width: '100%',
                        flexDirection: 'row',
                        backgroundColor: theme.backgroundColor2,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <View style={{ width: '15%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Name
                            </Text>
                        </View>
                        <View style={{ width: '45%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Expression
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Function
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Format
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Edit
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Delete
                            </Text>
                        </View>

                    </View>
                    {isEmpty && <EmptyRow/>}
                    {metricsJson.map((child, index) =>
                        <View style={{ width: '100%' }} key={`grouping-map-${index}`}>
                            <OptionsMetricChild
                                theme={theme}
                                child={child}
                                index={index}
                                editHandler={editHandler}
                                deleteHandler={deleteHandler}
                                setEdit={setEdit}
                            />
                            <OptionsMetricEdit
                                theme={theme}
                                open={edit}
                                setOpen={setEdit}
                                child={child}
                                index={index}
                                updateHandler={updateHandler}
                                cubeTable={cubeTable}
                                configArray={configArray}
                                currentMetrics={currentMetrics}
                                />
                        </View>
                    )}
                    
                    <View style={{ flexDirection: 'row', justifyContent: 'end', paddingTop: '1%' }}>
                    
                        <View style={{ width: '15%', alignItems: 'center' }}>
                            
                            <View
                                style={{ width: '100%', alignItems: 'right', padding: '0px' }}
                                onClick={() => addCondition()} >
                                <ColorButtonPrimary variant="contained" theme={theme} disabled={metricLessThan5}>
                                    ADD METRIC
                                </ColorButtonPrimary>
                            </View>
                        </View>
                        

                    </View>
                    {!isSameBase && <UnsavedChanges/>}
                </View>
            )
        } else {
            //return <OptionsGroupingPreview
            //theme={theme}
            //groupingData={groupingData}
            //cubeTable={cubeTable}
            //currentMetrics={currentMetrics}
            //updateArray={updateArray}
            //configArray={configArray}
            ///>
            return null
        }

    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor,
        },
    }));

    const ColorButtonPrimary = styled(Button)(({ theme }) => ({
        color: 'white',
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const PreviewButton = () => {
        if (!preview) {
            return <ColorButton variant="contained"
                theme={theme}
                endIcon={<PreviewIcon />}
                onClick={() => previewHandler()}
            >
                PREVIEW
            </ColorButton>
        } else {
            return <ColorButton variant="contained"
                theme={theme}
                endIcon={<PreviewIcon />}
                onClick={() => setPreview(false)}
            >
                CLOSE PREVIEW
            </ColorButton>
        }
    }


    return (
        <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
            <View style={{ width: '100%', height: '100%', borderColor: 'grey', borderWidth: 1, backgroundColor: theme.backgroundColor2, alignItems: 'center', justifyContent: 'end', padding: '0.5%', flexDirection: 'row' }}>

                <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => saveHandler()}
                        endIcon={<SendIcon />}>
                        SAVE ALL
                    </ColorButton>
                </View>
                <View style={{ width: '15%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => setCurrentView('Home')}
                        endIcon={<ExitToAppIcon />}
                    >
                        CLOSE WITHOUT SAVING
                    </ColorButton>
                </View>
            </View>
            {parentView()}
            <OptionsGroupingLoader
                theme={theme}
                open={saveLoading}
                message={'Saving Configs...'}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        minHeight: windowHeight * 0.93
        //backgroundColor: 'white'
    }
})

export default OptionsMetrics