import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import AdGroupEnhancedPanels from './AdGroupEnhancedPanels.js';
import LoadingComponent from '../Components/Loader';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway.js';
import postToApiGateway from '../UserConfigs/ApiGateway.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowWidth = Dimensions.get('window').width;

const AdGroupEnhancedPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const channelDim = props.channelDim
    const reportDim = props.reportDim
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = props.homeTitle;
    const clientID = props.clientID

    const filterList = props.filterList
    const setFilterList = props.setFilterList

    let currentChannel;

    if (clientID === 'Glitnor' && channelDim === 'PPC') {
        currentChannel = ['Google Ads', 'Microsoft Ads']
    } else if (clientID === 'Rank' && channelDim === 'PPC') {
        currentChannel = ['Google Ads', 'Microsoft Ads']
    } else if (clientID === 'PS' && channelDim === 'PPC') {
        currentChannel = ['Google Ads', 'Microsoft Ads']
    } else if (clientID === 'Rank' && channelDim === 'Paid Social') {
        currentChannel = ['Meta Ads']
    } else if (clientID === 'Glitnor' && channelDim === 'Paid Social') {
        currentChannel = ['Meta Ads']
    } else if (clientID === 'PS' && channelDim === 'Paid Social') {
        currentChannel = ['Meta Ads']
    } else if (clientID === 'Gamdom' && channelDim === 'Paid Social') {
        currentChannel = ['Facebook - Acquisition']
    } else {currentChannel = [channelDim]}

    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup_enhanced";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;


    if (clientID === 'Glitnor' && filters[filter2name] === 'Total') {
        filter2Filter = currentChannel
        filter2Operator = ['equals']
    } else if (clientID === 'PS' && filters[filter2name] === 'Total') {
        filter2Filter = currentChannel
        filter2Operator = ['equals']
    } else if (clientID === 'Rank' && filters[filter2name] === 'Total') {
        filter2Filter = currentChannel
        filter2Operator = ['equals']
    }
    else if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };
    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
          };
  
        setFilters(filterUpdate)
  
      };

      const cubeDims = [reportDim]
      const dimList = [filter1name, filter2name, filter3name]
  
      function convertToSQLCondition(dim, list) {
          if (list === 'Total' || list.includes('null')) {
              return `${dim} is not null`;
          } else {
              // Escape double quotes with a single backslash
              const escapedItems = list.map(item => `\"${item}\"`);
              const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
      
              // Return the raw string, ensuring it is not further escaped
              return sqlCondition;
          }
      }
  
      const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
      const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
      const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
      //const dim4value = `channel = \"${currentChannel}\"`
      const filterSql = [dim1Value, dim2Value, dim3Value]

      function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }
  
      const cubeQuery = {
  
          "request_type": "query",
          "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": cubeDims,
            "measures": [
              "impressions",
              "clicks",
              "spend",
              "regs",
              "ftds",
              "ftd_ngr",
              "retention_ngr",
              "deposits",
              "deposit_amount"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
          }
      }
  
      const dimQuery = {
  
          "request_type": "query",
          "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": dimList,
            "measures": [
              "impressions"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
          }
      }
  
      const requestData = async (event_data, support_url, support_key, support_id) => {
          setIsLoading(true)
          setConfigResponse(null); // Clear previous response
          const requestType = event_data.request_type
          const endpointStage = configArray.testingState
          const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
          try {
              // First, get the signed URL
              const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
      
              // Send the POST request to the signed URL
              const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
              setConfigResponse(result)
              setIsLoading(false)
          } catch (error) {
              console.error("Error posting to Lambda:", error);
          } finally {
              //setSaveLoading(false);
          }
      };
  
      const requestDimData = async (event_data, support_url, support_key, support_id) => {
          setIsLoading(true)
          setDimResponse(null); // Clear previous response
          const requestType = event_data.request_type
          const endpointStage = configArray.testingState
          const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
          try {
              // First, get the signed URL
              const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
      
              // Send the POST request to the signed URL
              const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
              setDimResponse(result)
              setIsLoading(false)
          } catch (error) {
              console.error("Error posting to Lambda:", error);
          } finally {
              //setSaveLoading(false);
          }
        };
  
      useEffect(() => {
          requestData(cubeQuery, patternAPI, support_key, support_id)
          requestDimData(dimQuery, patternAPI, support_key, support_id)
        }, [configArray, filters])

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const columnsLarge = {
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        cpr: false,
        retention_ngr: false,
        deposits: true,
        deposit_amount: true
    }

    const columnsSmall = {
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        cpr: false,
        retention_ngr: false,
        deposits: false,
        deposit_amount: false
    }

    let responsiveColumns;

    if (windowWidth > 1281) {
        responsiveColumns = columnsLarge
    } else {
        responsiveColumns = columnsSmall
    }

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(responsiveColumns);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data.body
    const dimCubeResponse = dimResponse.data.body
    
    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
    const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)
    const uniqueFilter3 = getUniqueValues(dimCubeResponse, filter3name)

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const agg_result_final = cubeResponse.map((obj) => {

        function checkInfinite (value) {
            let output;
            if (!isFinite(value)) {
                output = 0
            } else { output = value}
            return output
        }
        
        const dim = obj[reportDim];
        const impressions = obj.impressions;
        const clicks = obj.clicks;

        const ftds = obj.ftds;
        const regs = obj.regs;
        const ngr = obj.ftd_ngr;
        //const react = obj.reactivations;
        const deposit_amount = obj.deposit_amount;
        const retention_ngr = obj.retention_ngr;
        //const combinedConv = (ftds || 0) + (react || 0);
        const spend = parseFloat((obj.spend));
        const cpa = ftds === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        //const combined_cpa = combinedConv === 0 || combinedConv === 0 || isNaN(combinedConv) ? 0 : spend / combinedConv;
        const cpr = regs === 0 || spend === 0 || isNaN(regs) ? 0 : spend / regs;
        const roas = ngr === 0 || spend === 0 || isNaN(spend) ? 0 : ngr / spend;
        return {
            [reportDim]: dim,
            impressions: impressions,
            clicks: clicks,
            spend: spend,
            regs: regs,
            ftds: parseFloat(ftds),
            ftd_ngr: parseFloat(ngr) || 0,
            retention_ngr: parseFloat(retention_ngr) || 0,
            deposit_amount: parseFloat(deposit_amount) || 0,
            cpr: checkInfinite(cpr.toFixed(2)) || 0,
            cpa: checkInfinite(cpa.toFixed(2)) || 0,
            //combined_cpa: checkInfinite(combined_cpa.toFixed(2)) || 0,
            
            roas: checkInfinite(roas.toFixed(6)) || 0,
        };
    });

    const dataProp = {
        aggResult : agg_result_final,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <AdGroupEnhancedPanels
                style={{flex : 1 }}
                theme={theme}
                dataProp={agg_result_final}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                agg_result={agg_result_final}
                currentLevel={agg_result_final}
                currentChannel={currentChannel}
                filterParams={filterParams}
                dataPropChannel={dataProp}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
                columnVisibilityModel={columnVisibilityModel}
                setColumnVisibilityModel={setColumnVisibilityModel}
            />
            </View>           
        </View>

    )

}

export default AdGroupEnhancedPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})