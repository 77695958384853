import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Pressable,
    Dimensions
} from 'react-native'
import FilterMulti from './FilterMulti';
import FilterDate from './FilterDate';
import TuneIcon from '@mui/icons-material/Tune';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Tooltip from '@mui/material/Tooltip';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const FilterSummary = (props) => {

    const uniqueFilter1 = props.uniqueFilter1
    const uniqueFilter2 = props.uniqueFilter2
    const uniqueFilter3 = props.uniqueFilter3
    const isCumulative = props.isCumulative
    const setIsCumulative = props.setIsCumulative
    const largeProp = props.large
    const type = props.type
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const typeFilterState = props.typeFilterState !== undefined ? props.typeFilterState : false;
    const brandFilterState = props.brandFilterState !== undefined ? props.brandFilterState : false;
    const channelFilterState = props.channelFilterState !== undefined ? props.channelFilterState : false;
    const theme = props.theme
    const filterParams = props.filterParams
    const currentChannel = props.currentChannel
    const currentMetrics = props.currentMetrics
    const setOpen = props.setOpen

    const filter1name = filterParams.filterType1
    const filter2name = filterParams.filterType2
    const filter3name = filterParams.filterType3
    const [currentFilterValue, setCurrentValue] = useState(filter1name)
    const [currentFilter2Value, setCurrent2Value] = useState(filter2name)
    const [currentFilter3Value, setCurrent3Value] = useState(filter3name)
    const setFilter1List = filterParams.setFilter1List
    const setFilter2List = filterParams.setFilter2List
    const setFilter3List = filterParams.setFilter3List
    const filterList = props.filterList
    const setFilterValueList = props.setFilterList
    const [cumuState, setCumuState] = useState(false)

    const large = largeProp ? true : false;

    const settingStyle = {
        //position: 'absolute',
        //right: '0.6%',
        //top: '105%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
        borderWidth: 0,
        borderColor: 'red',
    }

    const cumuIconStyle = {
        color: isCumulative ? theme.backgroundColor2 : theme.color,
        fontSize: windowWidth * 0.015
    }
    const iconStyle = {
        color: theme.color,
        fontSize: windowWidth * 0.015
    }

    const toggleCumulative = () => {
        setIsCumulative((prevState) => !prevState);
    };

    return (


        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <FilterMulti
                    listValues={uniqueFilter1}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                    large={large}
                    type={filter1name}
                    filterParams={filterParams}
                    setList={setFilter1List}
                    singleModel={true}
                    currentValue={currentChannel}
                    currentFilterValue={currentFilterValue}
                    setCurrentValue={setCurrentValue}
                    filterIndex={0}
                    setFilterList={setFilterValueList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                />
            </View>
            <View style={styles.filterContainer}>
                <FilterMulti
                    listValues={uniqueFilter2}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                    large={large}
                    type={filter2name}
                    filterParams={filterParams}
                    setList={setFilter2List}
                    singleModel={true}
                    currentValue={currentChannel}
                    currentFilterValue={currentFilter2Value}
                    setCurrentValue={setCurrent2Value}
                    filterIndex={1}
                    setFilterList={setFilterValueList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                />
            </View>
            <View style={styles.filterContainer}>
                <FilterMulti
                    listValues={uniqueFilter3}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                    large={large}
                    type={filter3name}
                    filterParams={filterParams}
                    setList={setFilter3List}
                    singleModel={true}
                    currentValue={currentChannel}
                    currentFilterValue={currentFilter3Value}
                    setCurrentValue={setCurrent3Value}
                    filterIndex={2}
                    setFilterList={setFilterValueList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}

                />
            </View>


            <View style={styles.dateFilterContainer}>
                <FilterDate
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    large={large}
                    type={type}
                />
            </View>
            <View style={styles.controlContainer}>
                <View style={styles.controlItemContainer}>
                    <Tooltip
                        title={"Cumulative"}

                        componentsProps={{
                            arrow: { sx: { color: theme.backgroundColor2, } },
                            tooltip: {
                                sx: {
                                    bgcolor: theme.backgroundColor2,
                                    color: "white",
                                    fontFamily: 'Electrolize',
                                }
                            },
                        }}
                        arrow
                    >
                        <Pressable style={settingStyle} onPress={toggleCumulative}>
                            <ShowChartIcon style={cumuIconStyle} />
                        </Pressable>
                    </Tooltip>

                </View>
                <View style={styles.controlItemContainer}>
                    <Tooltip
                        title={"Adjust Report"}

                        componentsProps={{
                            arrow: { sx: { color: theme.backgroundColor2, } },
                            tooltip: {
                                sx: {
                                    bgcolor: theme.backgroundColor2,
                                    color: "white",
                                    fontFamily: 'Electrolize',
                                }
                            },
                        }}
                        arrow
                    >
                        <Pressable style={settingStyle} onPress={() => setOpen(true)}>
                            <TuneIcon style={iconStyle} />
                        </Pressable>
                    </Tooltip>

                </View>

            </View>

        </View>


    )

}

export default FilterSummary

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        height: windowHeight * 0.06,
        padding: '0.5%',
        marginTop: '0.5%'

    },
    filterContainer: {
        width: '23%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'blue',
        //paddingLeft: '0.5%',
        //padding: '0.5%'
    },
    dateFilterContainer: {

        borderWidth: 0,
        borderColor: 'red',
        height: '100%',
        width: '23%',
        //paddingLeft: '0.5%',
        padding: '0.5%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    controlContainer: {
        width: '8%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'row',

    },
    controlItemContainer: {
        height: '100%',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'yellow',
        borderWidth: 0,
    }
})