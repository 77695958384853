import {View , Dimensions} from 'react-native'
import '../../CSS/CohortCSS.css'

const windowWidth = Dimensions.get('window').width;

const CohortDepositChart = (props) => {

    const agg_cohorts = props.agg_cohorts
    const theme = props.theme
    const mergedData = {};

// Iterate through the data and merge running_ngr values
    agg_cohorts.forEach((item) => {
    const { cohort_month, running_dep_roas } = item;

    if (!mergedData[cohort_month]) {
        mergedData[cohort_month] = [];
    }

    // Push the running_ngr value to the corresponding cohort_month array
    mergedData[cohort_month].push(running_dep_roas);
    });

    // Ensure the arrays are sorted by months_from_ftd
    for (const cohort_month in mergedData) {
    mergedData[cohort_month].sort((a, b) => a - b);
    }

    const rawData = mergedData

    return (
      <View 
        style={{
          width: '90%',
          borderColor : 'red',
          borderWidth : 0,
          paddingTop : '0.5%',
          paddingBottom : '3%',
          }}
        >
        <CohortTable 
          data={rawData}
          theme={theme}
          />
      
      </View>
    )


}

export default CohortDepositChart

const CohortTable = ({ data , theme}) => {

    // Define startColor, stopColor, fontSize for both cells and thead
    const cellFontSize = windowWidth *0.01
    const theadFontSize = windowWidth *0.008

    // Define startColor and stopColor for cell background
    const startColor = theme.comboStopColor1ARRAY; // Example: Blue
    const stopColor = theme.comboStopColor2ARRAY; // Example: Red
  
    // Extract all the values from the data object
    const allValues = Object.values(data).flat().map(Number);
    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);
  
    // Function to map a value to a color based on min and max values
    const mapValueToColor = (value) => {
      // Normalize the value between 0 and 1 based on min and max
      const normalizedValue = (value - minValue) / (maxValue - minValue);
      // Calculate the RGB values based on the gradient between startColor and stopColor
      const red = Math.round(startColor[0] + normalizedValue * (stopColor[0] - startColor[0]));
      const green = Math.round(startColor[1] + normalizedValue * (stopColor[1] - startColor[1]));
      const blue = Math.round(startColor[2] + normalizedValue * (stopColor[2] - startColor[2]));
      return `rgb(${red}, ${green}, ${blue})`;
    };
  
    // Style for the table cells
    const cellStyle = {
      fontSize: `${cellFontSize}px`,
      color: theme.color,
    };

    const dataStyle = {
      fontSize: `${cellFontSize}px`,
      color: 'white'
    };
  
    // Style for the thead elements
    const theadStyle = {
      fontSize: `${theadFontSize}px`,
      color: theme.color,
      backgroundColor: `#3f83a3`
    };
  
    return (
      <table className="cohort-table">
        <thead>
          <tr style={theadStyle}>
            <th style={{ backgroundColor: theme.backgroundColor }}>Month</th>
            {Object.keys(data).map((month, index) => (
              <th key={month} style={{ backgroundColor: theme.backgroundColor }}>
                {index}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, values]) => (
            <tr key={key} style={cellStyle}>
              <td>{key}</td>
              {values.map((value, index) => (
                <td
                  key={index}
                  style={{
                    backgroundColor: mapValueToColor(Number(value)),
                    ...dataStyle,
                  }}
                >
                  {value}%
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );

};