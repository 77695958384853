import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import WebFont from 'webfontloader';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { View } from 'react-native'

const LogoutButton = (props) => {
  const { logout } = useAuth0();
  const theme = props.theme

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Electrolize']
      }
    });
  }, []);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.backgroundColor,
    //fontSize: windowWidth * 0.007,
    fontWeight: 600,
    fontFamily: 'Electrolize',
    backgroundColor: theme.backgroundColor2,
    '&:hover': {
      backgroundColor: theme.backgroundColor2,
    },
  }));

  const LogoutContainer = () => {
    return (<View style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', padding: '1%', marginBottom: '2%'}}>
      <ColorButton 
        theme={theme}
        onClick={() => logout({ logoutParams: { returnTo: 'https://pattern-inc.io/' } })}
        endIcon={<LogoutIcon style={{color: theme.backgroundColor}} />}
        style={{fontSize: 10, width: '95%'}}
      >
        Logout
      </ColorButton>
      
    </View>

    )
  }

  return (
    <LogoutContainer/>
  );
};

export default LogoutButton;