import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions, Pressable } from 'react-native'
import WebFont from 'webfontloader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../../Components/Download';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';


const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function StudioTable(props) {

  const currentMetrics = props.currentMetrics
  const primaryMetric = currentMetrics.primaryMetric
  const secondaryMetric = currentMetrics.secondaryMetric
  const primaryRevenue = currentMetrics.primaryRevenue
  const isCumulative = props.isCumulative || false
  const dataProp = props.dataProp
  const currentDim = props.currentDim
  const theme = props.theme
  const deleteHandler = props.deleteHandler
  const activeLayoutItem = props.activeLayoutItem
  const isPreview = props.isPreview

  const [contextMenu, setContextMenu] = useState(null);
  
    const handleContextMenu = (event) => {
      event.preventDefault();
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          :
            null,
      );
    };
  
    const handleClose = () => {
      setContextMenu(null);
    };



  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false
  })

  const [aggregationModel, setAggregationModel] = useState({
    dimension: 'total',
    spend: 'sum',
    ftds: 'sum',
    clicks: 'sum',
    impressions: 'sum',
    regs: 'sum',
    date: 'total',
    adgroup: 'total',
    ngr: 'sum',
    ftd_ngr: 'sum',
    channel: 'total',
    brand: 'total',
    campaign: 'total',
    roas: 'roas',
    budget: 'sum',
    actual_spend: 'sum',
    yesterday_spend: 'sum',
    calendar_date: 'total',    
    [primaryMetric] : 'sum',
    [secondaryMetric] : 'sum',
    [primaryRevenue] : 'sum',
    target_daily: 'sum',
    spend_rate: 'spend_rate',
    retention_ngr: 'sum',
    deposits: 'sum',
    deposit_amount: 'sum',
    reactivations: 'sum',
    cpc: 'cpc'
  });


  const aggregationModelCumu = {

  };

  let dynamicAgg;

  if (isCumulative) {
    dynamicAgg = aggregationModelCumu
  } else {
    dynamicAgg = aggregationModel
  }

  

  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  const valueFormat = (value, field) => {
    if (field === 'spend_rate' ||field === 'roas'  ) {
        const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
        return `${percent}%`;
    } else if (field === 'cpa' || field === 'cpc') {
      if (value.includes(',')) {
        const valueBase = value.replace(/,/g, '');
        const returnValue = (parseFloat(valueBase)).toFixed(0);
        
        return `€ ${returnValue}`;
      } else {
        const returnValue = (parseFloat(value)).toFixed(0);
        return `€${returnValue}`;
      }

      
    } else if (field === 'cpa' || field === 'spend'|| field === 'ngr'|| field === 'ftd_ngr'|| field === 'target_daily' || field === 'cpc') {
        return `€${value}`;
    } else
    return value;
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labelsBase = Object.keys(labelObject).map((key , index) => {

      let newLabel;
      if (key === 'ftds') {
          newLabel = currentMetrics.primaryMetric
      } else if (key === 'ftd') {
          newLabel = currentMetrics.primaryMetric
      }else if (key === 'regs') {
          newLabel = currentMetrics.secondaryMetric
      } else if (key === 'kw_type') {
          newLabel = currentMetrics.primaryDimensionString.toLowerCase()
      } else if (key === 'ngr') {
        newLabel = currentMetrics.primaryRevenue
      } else if (key === 'ftd_ngr') {
        newLabel = currentMetrics.primaryRevenue
      }  else {newLabel = key}

        return { 
        field: key, 
        headerName: newLabel, 
        //minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            const heatmapColor = calculateHeatmapColor(value, { field });
            if (index < 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: heatmapColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8,
                      fontFamily: 'Electrolize'
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: heatmapColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8,
                    fontFamily: 'Electrolize'
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  

      const reorderFields = (data) => {
        const order = [
            "impressions",
            "clicks",
            "ctr",
            "spend",
            "cpc",
            "cpm",
            "regs",
            "reg",
            "ftds",
            "ftd",
            "ngr",
            "ftd_ngr",
            "retention_ngr",
            "cpa",
            "roas",
        ];
    
        // Separate the dimension fields (not in the order array) and metric fields (in the order array)
        const dimensionFields = [];
        const metricFields = [];
    
        data.forEach(item => {
            if (order.includes(item.field)) {
                metricFields.push(item);
            } else {
                dimensionFields.push(item);
            }
        });
    
        // Sort the metric fields based on the order array
        metricFields.sort((a, b) => order.indexOf(a.field) - order.indexOf(b.field));
    
        // Return concatenated array: dimensions first, then sorted metrics
        return [...dimensionFields, ...metricFields];
    };

    const labels = reorderFields(labelsBase)

    const columnRanges = {};
    Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
  const { min, max } = columnRanges[column.field];
  const range = max + 1- min;

// Define input colors for the heatmap in RGB format
  const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
  const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;

  const relativeValue = (value - min) / range;
  const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
  const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
  const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

  return `rgba(${red},${green},${blue},0.9)`;
}

//function CustomToolbar() {
 // return (
   // <GridToolbarContainer sx={{color: theme.color}}>
     // <GridToolbarColumnsButton sx={{color: theme.color}}/>
      //  <GridToolbarFilterButton sx={{color: theme.color}}/>
       // <GridToolbarDensitySelector sx={{color: theme.color}}/>
       // <GridToolbarExport sx={{color: theme.color}}/>
    //</GridToolbarContainer>
  //);
//}

function calculateCPA (spend, ftd) {
  const cpa =  (spend / ftd)
  

  return Math.round(cpa)
}

function calculateROAS (ngr, spend) {
  const roas =  (ngr / spend)

  return roas
}

function calculateTargetDaily (budget, actual_spend, current_dom, days_in_month, days_remaining) {
  
  const totalSpend = ((budget / current_dom) * days_in_month)
  const remainingSpend = (totalSpend - actual_spend)
  const target_daily = parseFloat(remainingSpend / (days_remaining + 1)).toFixed(2) || 0.0

  return target_daily
}

function calculateSpendRate (spend, budget) {

  const spend_rate = (spend / budget).toFixed(2)

  if (isFinite(spend_rate)) {
    return spend_rate
    } else {
      return 0
    }
  }

function calculateCPC (spend, clicks) {

  const cpc = (spend / clicks).toFixed(2)

  return cpc

}



function calculateTotal (spend, ftd) {
  return "TOTAL"
  }

  const spend_rate = {
    label: '',
    getCellValue: ({ row }) => ({ actual_spend: row.actual_spend, budget: row.budget }),
    apply: ({ values }) => {
      let actual_spend = 0;
      let budget = 0;
      values.forEach((value) => {
        if (value) {
          actual_spend += value.actual_spend || 0; // Adds 0 if value.spend is null or NaN
            budget += value.budget || 0;
        }
      });
      return calculateSpendRate(actual_spend, budget);
    },
    columnTypes: ['number'],
  };

  const target_daily = {
    label: '',
    getCellValue: ({ row }) => ({ budget: row.budget, actual_spend: row.actual_spend, current_dom: row.current_dom, days_in_month: row.days_in_month, days_remaining: row.days_remaining }),
    apply: ({ values }) => {
      let budget = 0;
      let actual_spend = 0;
      let current_dom = 0;
      let days_in_month = 0;
      let days_remaining = 0;

      values.forEach((value) => {
        if (value) {
          budget += value.budget || 0; // Adds 0 if value.spend is null or NaN
          actual_spend += value.actual_spend || 0;
          current_dom = value.current_dom || 0;
          days_in_month = value.days_in_month || 0;
          days_remaining = value.days_remaining || 0;

        }
      });
      return calculateTargetDaily(budget, actual_spend, current_dom, days_in_month, days_remaining);
    },
    columnTypes: ['number'],
  };

  const roas = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.spend, ngr: row.ngr }),
  apply: ({ values }) => {
    let spend = 0;
    let ngr = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ngr += value.ngr || 0;
      }
    });
    return calculateROAS(ngr, spend);
  },
  columnTypes: ['number'],
};

const cpc = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.spend, clicks: row.clicks }),
  apply: ({ values }) => {
    let spend = 0;
    let clicks = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          clicks += value.clicks || 0;
      }
    });
    return calculateCPC(spend, clicks);
  },
  columnTypes: ['number'],
};

const cpa = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
  apply: ({ values }) => {
    let spend = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateCPA(spend, ftd);
  },
  columnTypes: ['number'],
};

const cpa_budget = {
  label: '',
  getCellValue: ({ row }) => ({ spend: row.actual_spend, ftd: row.ftd }),
  apply: ({ values }) => {
    let spend = 0;
    let ftd = 0;
    values.forEach((value) => {
      if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
      }
    });
    return calculateCPA(spend, ftd);
  },
  columnTypes: ['number'],
};

  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
            spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
            ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%'}}>
    <Box
    style={{
      width: '100%',
      height : '100%', 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
      marginTop: '0%'
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: 'grey'},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        pagination
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }

        initialState={{
          //pagination: {
            //paginationModel: { pageSize: 5 }           
          //},
        sorting: {
        sortModel: [{ field: currentDim, sort: 'desc' }],
        },
        }}
        
        />
    </Box>
    <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {isPreview && <Pressable onPress={() => deleteHandler(activeLayoutItem)}>
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: 'white'}} 
            //onClick={downloadHandler}
            //onClick={() => clickHandler(activeLayoutItem)}
            >
              Delete Widget
          </MenuItem>
          </Pressable>}
        </Menu>
    </div>
  );
}