import React from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;

const OptionsMetricChild = (props) => {

    const theme = props.theme
    const child = props.child
    const index = props.index
    const editHandler = props.editHandler
    const deleteHandler = props.deleteHandler
    const setEdit = props.setEdit

    const clickHandler = (child, index) => {
        editHandler(child, index)
        setEdit(true)
    }

    const iconsSyle = {
        fontSize: windowWidth * 0.015,
        color: 'grey'
    }

    return (
        <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: windowheight * 0.05 }}>
            <View style={{ width: '15%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 200 }}>
                    {child.name}
                </Text>
            </View>
            <View style={{ width: '45%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 200 }}>
                    {child.value}
                </Text>
            </View>
            <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 200 }}>
                {child.function}
                </Text>
            </View>
            <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}>
                <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 200 }}>
                {child.format}
                </Text>
            </View>
            <Pressable 
                style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center', padding: '1%' }}
                onPress={() => clickHandler(child, index)}
                >
                <EditIcon style={iconsSyle}/>
            </Pressable>
            <Pressable 
                style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' , padding: '1%'}}
                onPress={() => deleteHandler(child, index)}
                >
                <RemoveCircleIcon style={iconsSyle}/>
            </Pressable>

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        //backgroundColor: 'white'
    }
})

export default OptionsMetricChild