import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, Pressable } from 'react-native'
import { Scatter } from 'react-chartjs-2';
import WebFont from 'webfontloader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../../Components/Download';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const StudioScatter = (props) => {
  const chartRef = useRef(null);
  const dataProp = props.data
  const labels = props.labels
  const theme = props.theme
  const metric1 = props.metric1
  const metric2 = props.metric2
  const currentMetrics = props.currentMetrics
  const deleteHandler = props.deleteHandler
  const activeLayoutItem = props.activeLayoutItem
  const isPreview = props.isPreview
  const color = props.item.color || theme.backgroundColor2
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        :
          null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };


  let convLabel1

  if (metric1 === 'ctr') {
    convLabel1 = `${metric1}%`
  } else if (metric1 === 'ftds') {
    convLabel1 = currentMetrics.primaryMetric
  } else if (metric1 === 'ftd') {
    convLabel1 = currentMetrics.primaryMetric
  } else if (metric1 === 'ngr') {
    convLabel1 = currentMetrics.primaryRevenue
  } else if (metric1 === 'regs') {
    convLabel1 = currentMetrics.secondaryMetric
  } else if (metric1 === 'reg') {
    convLabel1 = currentMetrics.secondaryMetric
  } else {convLabel1 = metric1}

  let convLabel2

  if (metric2 === 'ctr') {
    convLabel2 = `${metric1}%`
  } else if (metric2 === 'ftds') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ftd') {
    convLabel2 = currentMetrics.primaryMetric
  } else if (metric2 === 'ngr') {
    convLabel2 = currentMetrics.primaryRevenue
  } else if (metric2 === 'regs') {
    convLabel2 = currentMetrics.secondaryMetric
  } else if (metric2 === 'reg') {
    convLabel2 = currentMetrics.secondaryMetric
  } else {convLabel2 = metric2}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.012
  } else {responseText = windowHeight * 0.012}

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);


const downloadHandler = () => {
  Download(dataProp)
  handleClose()

}

  const data = {
    //labels: labels,
    datasets: [
      {
        label: `${convLabel1} Vs ${convLabel2}`,
        data: dataProp,
        backgroundColor: color,
        borderRadius: 5,
      },
    ],
  };
  
const options = {
  maintainAspectRatio: false,
  locale: 'en-EU',
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false, // Remove the gridlines on the y-axis
      },
      ticks: {
        
        color : theme.color,
        font: {
          size: responseText, // Set the font size for y-axis labels
          weight: 'bold', // Set the font weight for y-axis labels
          family : 'Electrolize',
        },
      },
    },
    x: {
      grid: {
        display: false, // Remove the gridlines on the x-axis
      },
      ticks: {
        color : theme.color,
        font: {
          size: responseText, // Set the font size for x-axis labels
          family : 'Electrolize'
        },
      },
    },
  },
  tooltips: {
    enabled: true,    
  },
  plugins: {
    legend: {
      display: true,
      text: 'Chart Title',
      labels: {
        color : theme.color,
        // This more specific font property overrides the global property
        font: {
            size: responseText,
            family : 'Electrolize'
        }
    }
    },
    tooltip: {
      enabled: true,
    },
    title: {
      display: false,
      text: 'Chart Title',
      font: {
        size: responseText, // Set the font size for the chart title
        weight: 'bold', // Set the font weight for the chart title

      },
    },
  },
};

  return (
    <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%'}}>
      <Scatter 
        ref={chartRef} 
        data={data} 
        options={options} 
        style={{fontFamily : 'Electrolize'}} 
      />
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <Pressable onPress={() => downloadHandler}>
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: 'white'}} 
            onClick={downloadHandler}
            >
              Download Data
          </MenuItem>
          </Pressable>
          {isPreview && <Pressable onPress={() => deleteHandler(activeLayoutItem)}>
          <MenuItem 
            style={{fontFamily : 'Electrolize', backgroundColor: 'white'}} 
            //onClick={downloadHandler}
            //onClick={() => clickHandler(activeLayoutItem)}
            >
              Delete Widget
          </MenuItem>
          </Pressable>}
        </Menu>

    </div>
      );
};

export default StudioScatter;
