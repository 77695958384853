import React, { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Pressable, View, Text } from 'react-native'
import WebFont from 'webfontloader';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Download from '../../Components/Download';

const StudioPie = (props) => {
    const chartRef = useRef(null);
    const dataProp = props.data
    const labels = props.labels
    const theme = props.theme
    const metric = props.metric
    const isPreview = props.isPreview
    

    const currentMetrics = props.currentMetrics
    const deleteHandler = props.deleteHandler
    const activeLayoutItem = props.activeLayoutItem

    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :
                null,
        );
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    const filteredData = dataProp.filter(item => item.brand !== "");

    const desiredFields = [metric];

    const filteredValuesArray = filteredData.flatMap((item) =>
        desiredFields.map((field) => item[field])
    );

    const downloadHandler = () => {
        Download(filteredData)
        handleClose()

    }

    let convLabel

  if (metric === 'ctr') {
    convLabel = `${metric}%`
  } else if (metric === 'ftds') {
    convLabel = currentMetrics.primaryMetric
  } else if (metric === 'ftd') {
    convLabel = currentMetrics.primaryMetric
  } else if (metric === 'ngr') {
    convLabel = currentMetrics.primaryRevenue
  } else if (metric === 'regs') {
    convLabel = currentMetrics.secondaryMetric
  } else if (metric === 'reg') {
    convLabel = currentMetrics.secondaryMetric
  } else {convLabel = metric}

    const data = {
        labels: labels,
        datasets: [
            {
                label: convLabel,
                data: filteredValuesArray,
                backgroundColor: theme.backgroundColor2,
                backgroundColor: [
                    theme['gradc10'],
                    theme['gradc40'],
                    theme['gradc70'],
                    theme['gradc100'],
                    theme['gradc20'],
                    theme['gradc60'],
                    theme['gradc90'],
                    theme['gradc30'],
                    theme['gradc50'],
                    theme['gradc20']
                ],
            },
        ],
    };

    const options = {

        plugins: {
            legend: {
                display: true,
                text: 'Chart Title',
                labels: {
                    color: theme.color,
                    // This more specific font property overrides the global property
                    font: {
                        size: 8,
                        family: 'Electrolize'
                    }
                }
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: false,
                text: 'Chart Title',
                font: {
                    size: 24, // Set the font size for the chart title
                    weight: 'bold', // Set the font weight for the chart title

                },
            },
        },
    };

    return (
        <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <View style={{alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                <Doughnut ref={chartRef} data={data} options={options}/>
            </View>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <Pressable onPress={() => downloadHandler}>
                    <MenuItem
                        style={{ fontFamily: 'Electrolize', backgroundColor: 'white' }}
                        onClick={downloadHandler}
                    >
                        Download Data
                    </MenuItem>
                </Pressable>
                {isPreview && <Pressable onPress={() => deleteHandler(activeLayoutItem)}>
                    <MenuItem
                        style={{ fontFamily: 'Electrolize', backgroundColor: 'white' }}
                    //onClick={downloadHandler}
                    //onClick={() => clickHandler(activeLayoutItem)}
                    >
                        Delete Widget
                    </MenuItem>
                </Pressable>}
            </Menu>
        </div>)
};

export default StudioPie;
