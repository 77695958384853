import moment from 'moment-timezone';

function StudioParse(data, config, calcFieldsAliases, calcFields) {

  var dataset = data

  if (!dataset || dataset.length === undefined) {
    return null;
  }

  const type = config.typeValue
  const xAxisValue = config.xAxisValue
  const metric1Value = config.metric1Value
  const metric2Value = config.metric2Value
  const currentFilters = config.currentFilters
  const dateAgg = config.dateAgg
  const metriclist = config.metricList


  var aggResult = [];
  
  if ((type === 'bar' || type === 'line' || type === 'mixed' || type === 'metric' || type === 'table' || type === 'pie' || type === 'scatter')) { 
    dataset.reduce(function (res, value) {
        let axisValue;
    
        // Determine the axis value based on xAxisValue and dateAgg
        if (xAxisValue === 'date' && dateAgg === 'day') {
            axisValue = moment.tz(value[xAxisValue], 'Europe/Paris').format('YYYY-MM-DD');
        } else if (xAxisValue === "date" && dateAgg === 'month') {
            axisValue = moment.tz(value[xAxisValue], 'Europe/Paris').format('YYYY-MM');
        } else if (xAxisValue === "date" && dateAgg === "week") {
            axisValue = moment.tz(value[xAxisValue], 'Europe/Paris').startOf('isoWeek').format('YYYY-MM-DD');
        } else if (xAxisValue === "date" && dateAgg === "year") {
            axisValue = moment.tz(value[xAxisValue], 'Europe/Paris').format('YYYY-MM');
        } else {
            axisValue = value[xAxisValue];
        }
    
        if (!res[axisValue]) {
            res[axisValue] = {
                [xAxisValue]: axisValue
            };
            aggResult.push(res[axisValue]);
        }
    
        // Iterate over all keys in the dataset entry to aggregate metrics
        Object.keys(value).forEach((key) => {
            if (key !== xAxisValue && !isNaN(parseFloat(value[key]))) { // Exclude non-numeric fields
                res[axisValue][key] = (res[axisValue][key] || 0) + parseFloat(value[key]) || 0;
            }
        });
    
        return res;
    }, {});
    

        aggResult = aggResult.map((obj) => {
            const calcMetrics = calcFields;
            const calcAliases = calcFieldsAliases;
        
            // Helper function to safely evaluate a metric
            const safeEval = (formula, data) => {
                try {
                    const result = Function(
                        ...Object.keys(data),
                        `return (${formula});`
                    )(...Object.values(data));
        
                    // Check for invalid values
                    if (!isFinite(result) || isNaN(result)) {
                        return 0; // Fallback value for invalid results
                    }
                    return result;
                } catch (error) {
                    return 0; // Fallback value in case of errors
                }
            };
        
            // Calculate each metric and use corresponding alias as the key
            const calculatedValues = calcMetrics.reduce((acc, metric, index) => {
                const alias = calcAliases[index]; // Get the alias for the metric
                if (alias) {
                    acc[alias] = safeEval(metric, obj);
                }
                return acc;
            }, {});
        
            return {
                ...obj,
                ...calculatedValues, // Add the new metrics with aliases
            };
        });

        if (type === 'bar' || type === 'line' || type === 'mixed' || type === 'pie') {
            aggResult = aggResult
        } else if (type === 'table') {
            function filterMetrics(dataset, metricList) {
                if (!metricList || metricList.length === 0) {
                    // If metricList is empty or not provided, return the dataset as-is
                    return dataset;
                }
            
                return dataset.map(entry => {
                    return Object.keys(entry)
                        .filter(key => metricList.includes(key) || key === xAxisValue) // Keep "date" and keys in metricList
                        .reduce((filteredEntry, key) => {
                            filteredEntry[key] = entry[key];
                            return filteredEntry;
                        }, {});
                });
            }

            aggResult = filterMetrics(aggResult, metriclist)
        } else if (type === 'metric') {

            const result = dataset.reduce((res, value) => {
                // Iterate over all keys in the dataset entry to aggregate metrics
                Object.keys(value).forEach((key) => {
                    if (!isNaN(parseFloat(value[key]))) { // Include only numeric fields
                        res[key] = (res[key] || 0) + parseFloat(value[key]);
                    }
                });
            
                return res;
            }, {});
        
                aggResult = [result].map((obj) => {
                    const calcMetrics = calcFields;
                    const calcAliases = calcFieldsAliases;
                
                    // Helper function to safely evaluate a metric
                    const safeEval = (formula, data) => {
                        try {
                            const result = Function(
                                ...Object.keys(data),
                                `return (${formula});`
                            )(...Object.values(data));
                
                            // Check for invalid values
                            if (!isFinite(result) || isNaN(result)) {
                                return 0; // Fallback value for invalid results
                            }
                            return result;
                        } catch (error) {
                            return 0; // Fallback value in case of errors
                        }
                    };
                
                    // Calculate each metric and use corresponding alias as the key
                    const calculatedValues = calcMetrics.reduce((acc, metric, index) => {
                        const alias = calcAliases[index]; // Get the alias for the metric
                        if (alias) {
                            acc[alias] = safeEval(metric, obj);
                        }
                        return acc;
                    }, {});
                
                    return {
                        ...obj,
                        ...calculatedValues, // Add the new metrics with aliases
                    };
                });

        } else if (type === 'scatter') {
            function transformMetrics(data, metric1Value, metric2Value) {
                return data.map(entry => ({
                    x: entry[metric1Value], // Replace key for metric1Value with "x"
                    y: entry[metric2Value]  // Replace key for metric2Value with "y"
                }));
            }
            aggResult = transformMetrics(aggResult, metric1Value, metric2Value)
        } else {
            aggResult = aggResult
        }

      }
  return aggResult


}

export default StudioParse