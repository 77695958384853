import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import LoadingComponent from '../Components/Loader';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway.js';
import postToApiGateway from '../UserConfigs/ApiGateway.js';
import { postToAPIpreagg } from '../UserConfigs/ApiGateway.js';
import { signPreagg } from '../UserConfigs/ApiGateway.js';

const windowHeight = Dimensions.get('window').height;

const TestingPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const clientID = configArray.clientID
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "PPC Campaigns";
    const filterList = props.filterList
    const setFilterList = props.setFilterList

    let currentChannel;
    

    if (clientID === 'Glitnor' || 'Rank') {
        currentChannel = ['Google Ads', 'Microsoft Ads']
    } else {currentChannel = ['PPC']}

    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup_enhanced";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);


    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (clientID === 'Glitnor' && filters[filter2name] === 'Total') {
        filter2Filter = ['Google Ads', 'Microsoft Ads']
        filter2Operator = ['equals']
    } else if (clientID === 'PS' && filters[filter2name] === 'Total') {
        filter2Filter = ['Google Ads', 'Microsoft Ads']
        filter2Operator = ['equals']
    } else if (clientID === 'Rank' && filters[filter2name] === 'Total') {
        filter2Filter = ['PPC']
        filter2Operator = ['equals']
    }
    else if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
          };
  
        setFilters(filterUpdate)
  
      };

      const cubeDims = ['date']
    const dimList = [filter1name, filter2name, filter3name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
    const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
    const filterSql = [dim1Value, dim2Value, dim3Value]

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
              "clicks",
              "spend",
              "regs",
              "ftds",
              "ftd_ngr",
              "retention_ngr",
              "deposits",
              "deposit_amount"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": 'test_ppc_campaigns_cube'
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_budget`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`    
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };


      const fetchPreAgg = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const endpointStage = configArray.testingState
        const requestType = 'preagg'
        const reportName = `test_ppc_campaigns_cube.json`;
        const key = `stack-pattern/default_preagg%2F${client_id}%2F${reportName}`
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}/${key}` 
        console.log(support_id, support_key )
        try {
            // First, get the signed URL
            const signedUrl = await signPreagg(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPIpreagg(signedUrl, event_data, "GET");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      }

      /*useEffect(() => {
        const queryStart = cubeQuery.query.start;
        const queryEnd = cubeQuery.query.end;
        const queryFilters = cubeQuery.query.filters;        
        const endpointStage = configArray.testingState

    
        const fetchData = async () => {
            if (queryStart === start && queryEnd === end) {
                if (queryFilters === filterSql || queryFilters.length === 0) {
                    try {
                        await fetchPreAgg(cubeQuery, patternAPI, support_key, support_id);
                    } catch (error) {
                        console.error("Error with fechPreAgg:", error);
                        requestData(cubeQuery, patternAPI, support_key, support_id);
                    }
                }
            } else {
                requestData(cubeQuery, patternAPI, support_key, support_id);
            }
        };
    
        fetchData();
    }, [configArray, filters]);
    */
    

    useEffect(() => {
       //requestData(cubeQuery, patternAPI, support_key, support_id)
    fetchPreAgg(cubeQuery, patternAPI, support_key, support_id);

      }, [configArray])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        cpr: false
      });

      if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    
    const cubeResponse = configResponse.data.body
    //const dimCubeResponse = dimResponse.data
    //console.log(cubeResponse)
  
   


    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>

            </View>           
        </View>

    )

}

export default TestingPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        minHeight: windowHeight * 0.9,
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})