import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import AllChannelWeeklyPanels from './AllChannelWeeklyPanels.js';
import { postToSignedUrl } from '../../UserConfigs/AwsSigner.js';
import signRequest from '../../UserConfigs/AwsSigner.js';
import LoadingComponent from '../../Components/Loader.js';
import NoDataContainer from '../../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const AllChannelWeeklyPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const config_url = configArray.config_url
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const weeklyStartRaw = currentDate2.clone().subtract(105, 'day');
    const startProp = weeklyStartRaw.format('YYYY-MM-DD');
    const homeTitle = "AllChannelWeekly";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_daily_all";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
          };
  
        setFilters(filterUpdate)  
      };

      const cubeDims = ['date']
      const dimList = [filter1name, filter2name, filter3name]
  
      function convertToSQLCondition(dim, list) {
          if (list === 'Total' || list.includes('null')) {
              return `${dim} is not null`;
          } else {
              // Escape double quotes with a single backslash
              const escapedItems = list.map(item => `\"${item}\"`);
              const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
      
              // Return the raw string, ensuring it is not further escaped
              return sqlCondition;
          }
      }
  
      const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
      const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
      const dim3Value = convertToSQLCondition(filter3name, filter3Filter)
      const filterSql = [dim1Value, dim2Value, dim3Value]
      
      function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }
  
      const cubeQuery = {
  
          "request_type": "query",
          "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": cubeDims,
            "measures": [
              "impressions",
              "clicks",
              "spend",
              "regs",
              "ftds",
              "ngr"
            ],
            "custom_dimensions": ["date_trunc (date, week(monday)) as week"],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
          }
      }
  
      const dimQuery = {
  
          "request_type": "query",
          "query": {
            "start": start,
            "end": end,
            "table": cubeTable,
            "date_dim": "date",
            "dimensions": dimList,
            "measures": [
              "impressions"
            ],
            "custom_dimensions": [],
            "custom_dimensions_aliases": [],
            "custom_measures": [],
            "custom_measures_aliases": [],
            "filters": filterSql,
            "client_id": client_id,
            "auth0_user_id": auth0_user_id,
            "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
          }
      }
  
      const requestData = async (event_data, support_url, support_key, support_id, method) => {
          setIsLoading(true)
          setConfigResponse(null); // Clear previous response
      
          try {
              // First, get the signed URL
              const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
      
              // Send the POST request to the signed URL
              const result = await postToSignedUrl(signedUrl, event_data, method);
              setConfigResponse(result)
              setIsLoading(false)
          } catch (error) {
              console.error("Error posting to Lambda:", error);
          } finally {
              //setSaveLoading(false);
          }
      };
  
      const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
          setIsLoading(true)
          setDimResponse(null); // Clear previous response
      
          try {
              // First, get the signed URL
              const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
      
              // Send the POST request to the signed URL
              const result = await postToSignedUrl(signedUrl, event_data, method);
              setDimResponse(result)
              setIsLoading(false)
          } catch (error) {
              console.error("Error posting to Lambda:", error);
          } finally {
              //setSaveLoading(false);
          }
        };
  
      useEffect(() => {
          requestData(cubeQuery, config_url, support_key, support_id, 'POST')
          requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
        }, [configArray, filters])
  
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponse = configResponse.data
    const dimCubeResponse = dimResponse.data


    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
    const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)
    const uniqueFilter3 = getUniqueValues(dimCubeResponse, filter3name)

    if (cubeResponse.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    var agg_resultBase = [];
    cubeResponse.reduce(function (res, value) {

    const date = value.week
    const impressions = `impressions`
    const clicks = `clicks`
    const spend = `spend`
    const regs = `regs`
    const ftds = `ftds`
    const ngr = `ngr`

    if (!res[date]) {
      res[date] = {
        date: date,     
        impressions: 0,
        clicks: 0,
        spend: 0,
        regs: 0,
        ftds: 0,
        ngr: 0
      };
      agg_resultBase.push(res[date]);
    }
    res[date].impressions += parseInt(value[impressions]) || 0;
    res[date].clicks += parseInt(value[clicks]) || 0;
    res[date].spend += parseInt(value[spend]) || 0;
    res[date].regs += parseInt(value[regs]) || 0;
    res[date].ftds += parseInt(value[ftds]) || 0;
    res[date].ngr += parseInt(value[ngr]) || 0;
    return res;
  }, {});

  const agg_result = agg_resultBase.map((obj) => {
    const date = obj.date
    const spend = parseFloat(obj.spend)
    const ftds = parseInt(obj.ftds)
    const cpa = parseFloat((spend / ftds).toFixed(2))
    const impressions = parseInt(obj.impressions)
    const clicks = parseInt(obj.clicks)
    const regs = parseInt(obj.regs)
    const cpc = parseFloat((spend / clicks).toFixed(2))
    const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

    return {
        date: date,
        impressions: impressions,
        clicks: clicks,
        spend: spend,
        cpc: cpc,
        regs: regs,
        ftds: ftds,
        cpa: cpa,
        clickConv: ftdConv

    };
});

    

    const dateLabels = getUniqueValues(agg_result, 'date')

    const dataProp = {
        aggResult : agg_result,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <AllChannelWeeklyPanels 
                style={{flex : 1 }}
                theme={theme}
                dateLabels={dateLabels}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                timeFrame={"Weekly"}
                filterParams={filterParams}
                dataProp={dataProp}
                agg_daily={agg_result}
                agg_monthly={agg_result}
                agg_brand={agg_result}
                agg_type={agg_result}
                agg_weekly={agg_result}
                client={client}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
                />
            </View>           
        </View>

    )

}

export default AllChannelWeeklyPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 1.21,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})