import React, { useState, useEffect } from 'react';
import { View, 
    StyleSheet, 
    Dimensions, 
    Text,
    Pressable,
    ScrollView
 } from 'react-native'
import FiltersEnhancedSingle_2 from '../ComponentsEnhanced/FiltersEnhancedSingle_2';
import { postToAPISignedUrl } from '../UserConfigs/ApiGateway.js';
import postToApiGateway from '../UserConfigs/ApiGateway.js';
import LoadingComponent from '../Components/Loader';
import AttributionPanelLarge from './AtttributionPanelLarge';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AttributionPanelSingle = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filters = props.filters
    const chartType = props.chartType
    const cubeTable = props.cubeTable
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const filterParams = props.filterParams
    const homeTitle = 'attribution dimensions'

    const filter1name = filterParams.filter1name
    const filter2name = filterParams.filter2name
    const filter1List = filterParams.filter1List
    const filter2List = filterParams.filter2List

    const cubeDimensions = ['region', 
      'mobile_brand_name', 
      'operating_system_version',
      'browser',
      'campaign',
      'adgroup'
    ]

    const filterValueBase = {
      filter1List : [],
      filter2List : [],
      filter3List : []
    }
  const [filterList, setFilterList] = useState(filterValueBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }


    const [chartClick, setChartClick] = useState('Grouped Bar')
    const [axisClick, setAxisClick] = useState('Brand')
    const [metric1Click, setMetric1] = useState('FTDs')
    const [metric2Click, setMetric2] = useState('NGR')

    const xAxisLower = axisClick.toLowerCase();

  const cubeDims = [xAxisLower]
  const dimList = [filter1name, filter2name]

  function convertToSQLCondition(dim, list) {
    if (list === 'Total' || list.includes('null')) {
      return `${dim} is not null`;
    } else {
      // Escape double quotes with a single backslash
      const escapedItems = list.map(item => `\"${item}\"`);
      const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;

      // Return the raw string, ensuring it is not further escaped
      return sqlCondition;
    }
  }

  const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
  const dim2Value = convertToSQLCondition(filter2name, filter2Filter)
  const filterSql = [dim1Value, dim2Value]

  function formatString(input) {
    return input.toLowerCase().replace(/ /g, "_");
}

  const cubeQuery = {

    "request_type": "query",
    "query": {
      "start": start,
      "end": end,
      "table": cubeTable,
      "date_dim": "date",
      "dimensions": cubeDims,
      "measures": [
        "ftd_custom"
      ],
      "custom_dimensions": [],
      "custom_dimensions_aliases": [],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": filterSql,
      "client_id": client_id,
      "auth0_user_id": auth0_user_id,
      "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
    }
  }

  const dimQuery = {

    "request_type": "query",
    "query": {
      "start": start,
      "end": end,
      "table": cubeTable,
      "date_dim": "date",
      "dimensions": dimList,
      "measures": [
        "ftd_custom"
      ],
      "custom_dimensions": [],
      "custom_dimensions_aliases": [],
      "custom_measures": [],
      "custom_measures_aliases": [],
      "filters": filterSql,
      "client_id": client_id,
      "auth0_user_id": auth0_user_id,
      "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
    }
  }

  const requestData = async (event_data, support_url, support_key, support_id) => {
    setIsLoading(true)
    setConfigResponse(null); // Clear previous response
    const requestType = event_data.request_type
    const endpointStage = configArray.testingState
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    try {
      // First, get the signed URL
      const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

      // Send the POST request to the signed URL
      const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
      setConfigResponse(result)
      setIsLoading(false)
    } catch (error) {
      console.error("Error posting to Lambda:", error);
    } finally {
      //setSaveLoading(false);
    }
  };

  const requestDimData = async (event_data, support_url, support_key, support_id) => {
    setIsLoading(true)
    setDimResponse(null); // Clear previous response
    const requestType = event_data.request_type
    const endpointStage = configArray.testingState
    const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
    try {
      // First, get the signed URL
      const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);

      // Send the POST request to the signed URL
      const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
      setDimResponse(result)
      setIsLoading(false)
    } catch (error) {
      console.error("Error posting to Lambda:", error);
    } finally {
      //setSaveLoading(false);
    }
  };

  useEffect(() => {
    requestData(cubeQuery, patternAPI, support_key, support_id)
    requestDimData(dimQuery, patternAPI, support_key, support_id)
  }, [configArray, filters, axisClick])


  if (isLoading) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!configResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (!dimResponse) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeResponsebase = configResponse.data.body
  const dimCubeResponse = dimResponse.data.body

  const cubeResponse = cubeResponsebase.map((obj) => {
    
    return {
      [xAxisLower] : obj[xAxisLower],
      ftd_custom: obj.ftd_custom
    }
  })

  const getUniqueValues = (arr, key) => {
    const uniqueSet = new Set(arr.map(item => item[key]));
    return [...uniqueSet];
  };

  const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)
  const uniqueFilter2 = getUniqueValues(dimCubeResponse, filter2name)

  if (cubeResponse.length === 0) {
    return (
      <NoDataContainer
        theme={theme}
      />
    )
  }




    function axisClickHandler(props) {
        setAxisClick(props)
    }

    function metric1Handler(props) {
        setMetric1(props)
    }

    return (
        <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
        <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
        <FiltersEnhancedSingle_2
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={uniqueFilter1}
                uniqueFilter2={uniqueFilter2}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                large={1}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
            />
        </View>
            <TopPanel
                theme={theme}
                axisClickHandler={axisClickHandler}
                metric1Handler={metric1Handler}
                cubeDimensions={cubeDimensions}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}

            />
        </View>
    )

}

const DimensionBox = ({cubeDimensions, axisClickHandler, theme, currentMetrics}) => {

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.01
    } else {responseText = windowHeight * 0.017}


    
    return (
        <ScrollView style={{ flex: 1 }}>
          {cubeDimensions.map((item, index) => {
            let formatValue

            if (item === 'kw_type') {
              formatValue = currentMetrics.primaryDimensionString
            } else {formatValue = item}

            return (
            <Pressable
              key={index}
              style={[[styles.pressable, 
                        index !== 0 && styles.marginTop , 
                            {borderColor : theme.color,
                            backgroundColor: theme.backgroundColor2
                            }]]} // Apply margin to all but the first Pressable
              onPress={() => axisClickHandler(item)}
            >
              <Text
                style={{color: theme.backgroundColor, fontSize:responseText}}
              >{item}</Text>
            </Pressable>
          )})}
        </ScrollView>
      );
    };



const TopPanel = (props) => {

    const theme = props.theme
    const chartConfig = props.chartConfig
    const cubeDimensions = props.cubeDimensions
    const axisClickHandler = props.axisClickHandler
    const metric1Handler = props.metric1Handler
    const cubeResponse = props.cubeResponse
    const chartType = props.chartType
    const cubeTable = props.cubeTable
    const currentMetrics = props.currentMetrics


    return (
        <View style={[styles.topPanelParent, { borderColor: theme.color }]}>
            <View style={styles.topPanelChildLarge}>
            <AttributionPanelLarge
                theme={theme}
                cubeResponse={cubeResponse}
                chartType={chartType}
                cubeTable={cubeTable}
                currentMetrics={currentMetrics}
            />

            </View>
            <View style={styles.topPanelChildSmall}>
            <DimensionBox
                cubeDimensions={cubeDimensions}
                axisClickHandler={axisClickHandler}
                theme={theme}
                currentMetrics={currentMetrics}
                />
            </View>

        </View>
    )
}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        width: '100%',
        height: windowHeight * 0.931,

    },
    topPanelParent: {
        width: '100%',
        height: '90%',
        borderWidth: 0,
        paddingTop: '1.5%',
        paddingBottom: '1%',
        flexDirection: 'row',
    },
    topPanelChildLarge: {
        width: '81%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
    },
    topPanelChildSmall: {
        width: '19%',
        height: '100%',
        borderWidth: 0,
        borderColor: 'yellow',
        flexDirection: 'column',
        padding: '1.5%'
    },
    dimensionBox: {
        width: '100%', 
        height: '40%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 1,
        paddingBottom: '1%'

    },
    pressable: {
        width: '100%',
        padding: 10, // Add padding for space around text
        backgroundColor: 'white', // Optional background color
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'red',
        borderWidth: 1,
        borderRadius: 5,
      },
      marginTop: {
        marginTop: 10, // Adjust the margin as needed
      },
    
})

export default AttributionPanelSingle