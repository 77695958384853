import React, { useEffect, useState } from 'react';
import { View, Pressable, Dimensions, Text } from 'react-native';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import TableChartIcon from '@mui/icons-material/TableChart';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import SpaceBarIcon from '@mui/icons-material/SpaceBar';
import GridLayout from "react-grid-layout";
import NumbersIcon from '@mui/icons-material/Numbers';
import StudioParse from './StudioParse';
import StudioChartsContainer from '../StudioCharts/StudioChartsContainer';

const windowWidth = Dimensions.get('window').width;

const StudioCreateStep2Grid = (props) => {

    const theme = props.theme
    const currentSchema = props.currentSchema
    const activeLayoutItem = props.activeLayoutItem
    const activeitem = props.activeitem
    const setActiveItem = props.setActiveItem
    const setActiveLayoutItem = props.setActiveLayoutItem
    const layout = props.layout
    const setLayout = props.setLayout
    const widgetLayout = props.widgetLayout
    const setWidgetLayout = props.setWidgetLayout
    const cubeResponse = props.cubeResponse
    const currentMetrics = props.currentMetrics
    const calcFields = props.calcFields
    const calcFieldsAliases = props.calcFieldsAliases
    const defaultMetrics = props.defaultMetrics

    const actions = [
        { icon: <SpaceBarIcon sx={{color: theme.backgroundColor2}}/>, name: 'Space', quickAction: () => addWidget('space') },
        { icon: <NumbersIcon sx={{color: theme.backgroundColor2}}/>, name: 'Metric', quickAction: () => addWidget('metric') },
        { icon: <TableChartIcon sx={{color: theme.backgroundColor2}}/>, name: 'Table', quickAction: () => addWidget('table') },
        { icon: <MultilineChartIcon sx={{color: theme.backgroundColor2}}/>, name: 'Mixed', quickAction: () => addWidget('mixed') },
        { icon: <ShowChartIcon sx={{color: theme.backgroundColor2}}/>, name: 'Line', quickAction: () => addWidget('line') },
        { icon: <BarChartIcon sx={{color: theme.backgroundColor2}}/>, name: 'Bar', quickAction: () => addWidget('bar') },
    ];

    //const maxY = layout.reduce((maxY, item) => Math.max(maxY, item.y + item.h), 0)


    const addWidget = (type) => {
        const id = Math.floor(10000000 + Math.random() * 90000000)
        const newWidget = { i: `${type}_widget_${id}`, 
                            x: 2, 
                            y: 2, 
                            w: 2, 
                            h: 4, 
                            maxH: 20, 
                            maxW: 20, 
                            typeValue: type, 
                            xAxisValue: 'date', 
                            metric1Value: defaultMetrics.first, 
                            metric2Value: defaultMetrics.second, 
                            currentFilters: [], 
                            color: theme.backgroundColor2, 
                            dateAgg: 'day', 
                            metricList: defaultMetrics.list,
                            borderColor: 'grey',
                            color2: theme.color,
                            format1: 'int',
                            format2: 'int',
                            smoothing: true,
                            moving_average: 'none'
     };
        setLayout([...layout, newWidget]);
        setWidgetLayout([...widgetLayout, newWidget])
        const report = [...layout, newWidget]
        const reportLength = report.length - 1
        setActiveLayoutItem(reportLength)
        setActiveItem(newWidget)
    };

    const deleteHandler = (item) => {
        const currentIndex = item
        if (layout.length === 1) {
            return
        } else {
        const newLayout = layout.filter((_, index) => index !== currentIndex);
        const newWidgetLayout = widgetLayout.filter((_, index) => index !== currentIndex);
        //const newWidgetLayout = widgetLayout.filter((item) => item.i !== activeitem.i)
        
        setActiveLayoutItem(0)
        setLayout(newLayout)
        setWidgetLayout(newWidgetLayout)
        
        }
        
    }

    const activeHandler = (item, index) => {
        setActiveItem(item)
        setActiveLayoutItem(index)
    }

    const WidgetContent = ({item}) => {

        const borderColor = item.borderColor || 'grey'

        const parsedDataBase = StudioParse(cubeResponse, item, calcFieldsAliases, calcFields)
        let parsedData;

        if (item.typeValue === 'cohorts') {
            parsedData = cubeResponse
        } else {
            parsedData = parsedDataBase
        }

        if (item.i === activeitem.i) {
            return <View style={{ background: theme.backgroundColor, borderRadius: 7, padding: '1%', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', borderColor: theme.backgroundColor2, borderWidth: 3}}>
                <View style={{height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <StudioChartsContainer
                        theme={theme}
                        item={item}
                        data={parsedData}
                        currentMetrics={currentMetrics}
                        deleteHandler={deleteHandler}
                        activeLayoutItem={activeLayoutItem}
                        
                    />
                </View>

            </View>
            
            
        } else {
            return <View style={{ background: theme.backgroundColor, borderRadius: 7, padding: '1%', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', borderColor: borderColor, borderWidth: 1}}>
                <StudioChartsContainer
                    theme={theme}
                    item={item}
                    data={parsedData}
                    currentMetrics={currentMetrics}
                    deleteHandler={deleteHandler}
                    activeLayoutItem={activeLayoutItem}
                />

            </View>
        }
        }

        function sizeHandler(size) {

            let responseRow;
    
            if (windowWidth > 2000) {
                responseRow = 25
            } else if (windowWidth > 1700) {
                responseRow = 25.5
            } else if (windowWidth > 1500) {
                responseRow = 22
            } else if (windowWidth > 1400) {
                responseRow = 25
            } else if (windowWidth <= 1400) {
                responseRow = 16
            } else { responseRow = 0.5 }
    
            //return parseInt(size * responseRow)
            return responseRow
    
        }


    return (
        <View style={{ width: '100%', height: '100%', backgroundColor: theme.backgroundColor }}>
            <GridLayout
                className="complex-interface-layout"
                layout={layout}
                cols={12}
                rowHeight={sizeHandler(37)}
                width={windowWidth * 0.73}
                compactType="vertical"
                //onDragStart={handleDragStart}
                onLayoutChange={(newLayout) => setLayout(newLayout)}
                style={{ backgroundColor: theme.panelBackground, border: '1px solid grey', height: "100%", flexWrap: 'wrap'}}
            >
                {widgetLayout.map((item, index) => (

                    <div key={item.i}
                        //style={activeHandler(item)
                        style={{borderRadius: 7}}
                    >
                        <Pressable 
                            style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', justifyContent: 'center', borderRadius: 7 }}
                            onPress={() => activeHandler(item, index)}
                        >  
                        <WidgetContent item={item}/>

                        </Pressable>
                    </div>
                ))}
                {/* Add button to trigger addWidget */}
            </GridLayout>
            <Box sx={{ height: 0, transform: 'translateZ(0px)', flexGrow: 1, zIndex: 99999 }}>
                <SpeedDial
                    ariaLabel="SpeedDial basic example"
                    sx={{ position: 'absolute', bottom: 16, right: 16}}
                    icon={<SpeedDialIcon  />}
                    FabProps={{
                        sx: {
                          bgcolor: theme.backgroundColor2,
                          '&:hover': {
                            bgcolor: 'grey',
                          }
                        }
                    }}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            tooltipOpen={true}
                            onClick={action.quickAction}
                            
                        />
                    ))}
                </SpeedDial>
            </Box>
        </View>
    )

}


export default StudioCreateStep2Grid;