import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { View, 
    Dimensions } from 'react-native'

import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

const windowWidth = Dimensions.get('window').width;

export default function StudioControlColourPicker(props) {
  const open = props.open
  const setOpen = props.setOpen
  const theme = props.theme
  const message = props.message
  const colourhandler = props.colourhandler
  const colourType = props.colourType
  const [color, setColor] = useColor(theme.backgroundColor2);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    colourhandler(colourType, color.hex)
    setOpen(false)
  }

  

  return (
    <View style={{width: windowWidth * 0.8, borderColor: theme.color, borderWidth: 0, backgroundColor: theme.backgroundColor}}>
    <div>
      <Dialog fullWidth={true} disableEscapeKeyDown open={open}>
        
        <DialogContent sx={{backgroundColor: theme.backgroundColor}}>
          <Box component="form" sx={{ 
                //display: 'flex', 
                //flexDirection: 'row',
                //alignItems: 'center',
                //justifyContent: 'center'
                }}>
                    <ColourSelect
                        color={color}
                        setColor={setColor}
                        //style={{width: '100%'}}
                    />
                
          </Box>
          
        </DialogContent>
        <DialogActions sx={{backgroundColor: theme.backgroundColor}}>
          <Button sx={{fontFamily: 'Electrolize', color: theme.color}} onClick={handleClose}>Cancel</Button>
          <Button sx={{fontFamily: 'Electrolize', color: theme.color}} autoFocus
                onClick={() => handleSave()}
            >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}

const ColourSelect = (props) => {

    const color = props.color
    const setColor = props.setColor
    
    return <ColorPicker 
        height={200}
        hideInput={["rgb", "hsv"]} 
        color={color} 
        onChange={setColor} />
  }