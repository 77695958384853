import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'

import OptionsGroupingLoader from '../../Options/OptionsGrouping/OptionsGroupingDefinitions/OptionsGroupingLoader';
import StudioEditMetricsChild from './StudioEditMetricsChild';
import StudioEditMetricsEdit from './StudioEditMetricsEdit';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const StudioEditMetricsDefinitions = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const setOpen = props.setOpen
    const currentMetricIndex = props.currentMetricIndex
    const customArray = props.customArray
    const customMetricsAliases = customArray.customMetricsAliases
    const setCustomMetricsAliases = customArray.setCustomMetricsAliases
    const customMetricValues = customArray.customMetricValues
    const setCustomMetricValues = customArray.setCustomMetricValues
    const customMetricFormats = customArray.customMetricFormats
    const setCustomMetricFormats = customArray.setCustomMetricFormats
    const customMetricFunctions = customArray.customMetricFunctions
    const setCustomMetricFunctions = customArray.setCustomMetricFunctions
    const currentMetrics = customArray.currentMetrics
    const metricArray = props.metricArray
    const cubeTable = props.cubeTable
    const [edit, setEdit] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)


    const custom_metrics_aliases = metricArray.customMetricsAliases || []
    const custom_metrics_format = metricArray.customMetricFormats || []
    const custom_metrics = metricArray.customMetricValues || []
    const custom_metrics_function = metricArray.customMetricFunctions || []

    const editBase = {
        "child": {
            "name": "test",
            "value": "impressions",
            "format": "integer",
            "function": "sum"
        },
        "index": 0
    }

    const [currentEdit, setCurrentEdit] = useState(editBase)
    const [metricState, setMetricState] = useState(custom_metrics)
    const [metricAliasState, setMetricAliasState] = useState(custom_metrics_aliases)
    const [metricFormatState, setMetricFormatState] = useState(custom_metrics_format)
    const [metricFunctionState, setMetricFunctionState] = useState(custom_metrics_function)


    let oneMetric = metricState.length > 0;

    function createCustomMetricsJson(aliases, metrics, format, functions) {
        console.log(aliases, metrics, format, functions)
            if (aliases.length !== metrics.length) {
                throw new Error("Aliases and metrics arrays must have the same length.");
            }
    
            return aliases.map((alias, index) => ({
                name: alias,
                value: metrics[index],
                format: format[index],
                function: functions[index]
            }));
        }
    
        const metricsJsonBase = createCustomMetricsJson(custom_metrics_aliases, custom_metrics, custom_metrics_format, custom_metrics_function)
        const [metricsJson, setMetricsJson] = useState(metricsJsonBase)
        
        const [comparisonCopy, setComparisonCopy] = useState(metricsJson)
        const isSameBase = JSON.stringify(metricsJson) === JSON.stringify(comparisonCopy)    
        const isEmpty = metricsJson.length === 0
        //const metricLessThan5 = metricsJson.length > 4


    const saveHandler = () => {
        
        const customMetricsAliasesCopy = [...customMetricsAliases]
        customMetricsAliasesCopy[currentMetricIndex] = metricAliasState[0]

        const customMetricValuesCopy = [...customMetricValues]
        customMetricValuesCopy[currentMetricIndex] = metricState[0]

        const customMetricFormatsCopy = [...customMetricFormats]
        customMetricFormatsCopy[currentMetricIndex] = metricFormatState[0]

        const customMetricFunctionsCopy = [...customMetricFunctions]
        customMetricFunctionsCopy[currentMetricIndex] = metricFunctionState[0]

        if (!metricAliasState[0]) {
            return
        } else {
            setCustomMetricsAliases(customMetricsAliasesCopy)
            setCustomMetricValues(customMetricValuesCopy)
            setCustomMetricFormats(customMetricFormatsCopy)
            setCustomMetricFunctions(customMetricFunctionsCopy)
        }

        



        
        /*const baseCopy = JSON.parse(JSON.stringify(baseResponse));
        baseCopy.data.custom_groupings.custom_metrics = metricState
        baseCopy.data.custom_groupings.custom_metrics_aliases = metricAliasState
        baseCopy.data.custom_groupings.custom_metrics_format = metricFormatState
        baseCopy.data.custom_groupings.custom_metrics_function = metricFunctionState

        const event_data = {
            client: client,
            client_id: clientMapId,
            auth0_user_id: auth0_user_id,
            "request_type": "custom_configs_update",
            "configData": baseCopy.data,
        }
        console.log(event_data)

        sendDataToLambda(event_data, support_url, support_key, support_id, 'POST')
        const metricsJsonBase = createCustomMetricsJson(baseCopy.data.custom_groupings.custom_metrics_aliases,
            baseCopy.data.custom_groupings.custom_metrics, 
            baseCopy.data.custom_groupings.custom_metrics_format, 
            baseCopy.data.custom_groupings.custom_metrics_function)
        setComparisonCopy(metricsJsonBase)
        */
    }

    const editHandler = (child, index) => {
        const currentItem = {
            child,
            index
        }
        setCurrentEdit(currentItem)
        
    }

    const updateHandler = (newChild, index) => {
        const newNameValue = newChild.name
        const newValueValue = newChild.value
        const newFormatValue = newChild.format
        const newFunctionValue = newChild.function

        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const metricsBaseCopy = [...metricState]
        const aliasBaseCopy = [...metricAliasState]
        const formatBaseCopy = [...metricFormatState]
        const functionBaseCopy = [...metricFunctionState]

        const currentIndex = currentEdit.index

        baseJsonCopy[currentIndex] = newChild
        aliasBaseCopy[currentIndex] = newNameValue
        metricsBaseCopy[currentIndex] = newValueValue
        formatBaseCopy[currentIndex] = newFormatValue
        functionBaseCopy[currentIndex] = newFunctionValue

        setMetricsJson(baseJsonCopy)
        setMetricState(metricsBaseCopy)
        setMetricAliasState(aliasBaseCopy)
        setMetricFormatState(formatBaseCopy)
        setMetricFunctionState(functionBaseCopy)
        setEdit(false)
        
    }

    function getValuesByKey(data, key) {
        return data.map(item => item[key]);
    }

    const deleteHandler = (child, itemIndex) => {
        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const newList = baseJsonCopy.filter((_, index) => index !== itemIndex);
        
        const newNameValue = getValuesByKey(newList, 'name')
        const newValueValue = getValuesByKey(newList, 'value')
        const newFormatValue = getValuesByKey(newList, 'format')
        const newFunctionValue = getValuesByKey(newList, 'function')

        setMetricsJson(newList)
        setMetricState(newValueValue)
        setMetricAliasState(newNameValue)
        setMetricFormatState(newFormatValue)
        setMetricFunctionState(newFunctionValue)
        
    }

    const addCondition = () => {
        const baseJsonCopy = JSON.parse(JSON.stringify(metricsJson));
        const metricsBaseCopy = [...metricState]
        const aliasBaseCopy = [...metricAliasState]
        const formatBaseCopy = [...metricFormatState]
        const functionBaseCopy = [...metricFunctionState]
        const newIndex = Math.floor(100000 + Math.random() * 900000);

        const newItem = {
            "name": `new_metric_${newIndex}`,
            "value": "impressions",
            "format": "integer",
            "function": "sum"
        }


        const newJson = [...baseJsonCopy, newItem];
        const newMetrics = [...metricsBaseCopy, newItem.value];
        const newAliases = [...aliasBaseCopy, newItem.name];
        const newFormat = [...formatBaseCopy, newItem.format]
        const newFunctions = [...functionBaseCopy, newItem.function]

        setMetricsJson(newJson)
        setMetricState(newMetrics)
        setMetricAliasState(newAliases)
        setMetricFormatState(newFormat)
        setMetricFunctionState(newFunctions)
        
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.backgroundColor2,
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor,
        '&:hover': {
            backgroundColor: theme.backgroundColor,
        },
    }));

    const ColorButtonPrimary = styled(Button)(({ theme }) => ({
        color: 'white',
        fontSize: windowWidth * 0.007,
        fontWeight: 600,
        fontFamily: 'Electrolize',
        backgroundColor: theme.backgroundColor2,
        '&:hover': {
            backgroundColor: theme.backgroundColor2,
        },
    }));

    const EmptyRow = () => {
    
            return (<View style={{ width: '100%', height: windowHeight * 0.06, justifyContent: 'center', alignItems: 'center', borderColor: 'grey', borderWidth: 1 }}>
                <Text style={{ color: theme.color, fontFamily: 'Electrolize', fontSize: windowWidth * 0.01, textAlign: 'center', fontWeight: 600 }}>
                    Add a metric to get started...
                </Text>
            </View>)
    
        }
    
        const UnsavedChanges = () => {
            return (<View style={{ width: '100%', height: windowHeight * 0.06, justifyContent: 'center', alignItems: 'center', borderColor: 'grey', borderWidth: 0, padding: '1%' }}>
                <Text style={{ color: 'red', fontFamily: 'Electrolize', fontSize: windowWidth * 0.009, textAlign: 'end', fontWeight: 600, width: '100%' }}>
                    You have unsaved changes...
                </Text>
            </View>)
        }

    const parentView = () => {

            return (
                <View style={{ width: '100%', margin: '1%' }}>
                    <View style={{
                        height: windowHeight * 0.06,
                        width: '100%',
                        flexDirection: 'row',
                        backgroundColor: theme.backgroundColor2,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <View style={{ width: '15%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Name
                            </Text>
                        </View>
                        <View style={{ width: '45%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Expression
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Function
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Format
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Edit
                            </Text>
                        </View>
                        <View style={{ width: '10%', height: '100%', borderColor: 'grey', borderWidth: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'white', fontFamily: 'Electrolize', fontSize: windowWidth * 0.015, textAlign: 'center', fontWeight: 600 }}>
                                Delete
                            </Text>
                        </View>

                    </View>
                    {isEmpty && <EmptyRow/>}
                    {metricsJson.map((child, index) =>
                        <View style={{ width: '100%' }} key={`grouping-map-${index}`}>
                            <StudioEditMetricsChild
                                theme={theme}
                                child={child}
                                index={index}
                                editHandler={editHandler}
                                deleteHandler={deleteHandler}
                                setEdit={setEdit}
                            />
                            <StudioEditMetricsEdit
                                theme={theme}
                                open={edit}
                                setOpen={setEdit}
                                child={child}
                                index={index}
                                updateHandler={updateHandler}
                                cubeTable={cubeTable}
                                configArray={configArray}
                                currentMetrics={currentMetrics}
                                />
                        </View>
                    )}
                    
                    <View style={{ flexDirection: 'row', justifyContent: 'end', paddingTop: '1%' }}>
                    
                        <View style={{ width: '15%', alignItems: 'center' }}>
                            
                            <View
                                style={{ width: '100%', alignItems: 'right', padding: '0px' }}
                                onClick={() => addCondition()} >
                                <ColorButtonPrimary variant="contained" theme={theme} disabled={oneMetric}>
                                    ADD METRIC
                                </ColorButtonPrimary>
                            </View>
                        </View>
                        

                    </View>
                    {!isSameBase && <UnsavedChanges/>}
                </View>
            )
        

    }

    return (
        <View style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
            <View style={{ width: '100%', height: '100%', borderColor: 'grey', borderWidth: 1, backgroundColor: theme.backgroundColor2, alignItems: 'center', justifyContent: 'end', padding: '0.5%', flexDirection: 'row' }}>
                <View style={{ width: '20%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => saveHandler()}
                        endIcon={<SendIcon />}>
                        SAVE ALL
                    </ColorButton>
                </View>
                <View style={{ width: '20%', alignItems: 'right', padding: '2px' }}>
                    <ColorButton variant="contained"
                        theme={theme}
                        onClick={() => setOpen(false)}
                        endIcon={<ExitToAppIcon />}
                    >
                        CLOSE WITHOUT SAVING
                    </ColorButton>
                </View>
            </View>
            {parentView()}
            <OptionsGroupingLoader
                theme={theme}
                open={saveLoading}
                message={'Saving Configs...'}
            />
        </View>
    )

}

export default StudioEditMetricsDefinitions

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        //backgroundColor: 'white'
    }
})