import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { postToAPISignedUrl } from '../../UserConfigs/ApiGateway.js';
import postToApiGateway from '../../UserConfigs/ApiGateway.js';
import LoadingComponent from '../../Components/Loader.js';
import AnalyticsEventsPanels from './AnalyticsEventsPanels.js';
import NoDataContainer from '../../Components/NoDataContainer.js';

const AnalyticsEventsPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_id = configArray.support_id
    const support_key = configArray.support_key
    const client_upper = configArray.clientID
    const client_lower = client_upper.toLowerCase()
    const client_id = configArray.clientMapId
    const auth0_user_id = configArray.auth0_user_id
    const patternAPI = configArray.patternAPI
    const [configResponse, setConfigResponse] = useState(null)
    const [dimResponse, setDimResponse] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "AnalyticsEventsPanel";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    let clientAdj;
    if (client === "test") {
        clientAdj = "rank"
    } else {
        clientAdj = client
    }
    const cubeTable = clientAdj + "_att_ga4_viz";
    const reportDimBase = props.reportDim
    const currentMetrics = props.currentMetrics
    const currentConfigBase = {
        dim: reportDimBase,
        type: 'Total'
    }
    const reportDim = currentConfigBase.dim
    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp

    const filter1name = 'brand'
    const filter2name = reportDim

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter1List,
        filter2List,
        setFilter1List,
        setFilter2List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        users: true,
        event_count: true,
        users_logged_in: true,
      });

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
        };

        setFilters(filterUpdate)

    };

    const cubeDims = ['dim']
    const dimList = [filter1name]

    function convertToSQLCondition(dim, list) {
        if (list === 'Total' || list.includes('null')) {
            return `${dim} is not null`;
        } else {
            // Escape double quotes with a single backslash
            const escapedItems = list.map(item => `\"${item}\"`);
            const sqlCondition = `${dim} in [${escapedItems.join(", ")}]`;
    
            // Return the raw string, ensuring it is not further escaped
            return sqlCondition;
        }
    }

    const dim1Value = convertToSQLCondition(filter1name, filter1Filter)
    const dim2Value = `type = \"events\"`
    const filterSql = [dim1Value, dim2Value]

    function formatString(input) {
        return input.toLowerCase().replace(/ /g, "_");
    }

    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "users",
            "event_count",
            "users_logged_in",
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "users"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": filterSql,
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
        const requestType = event_data.request_type
        const endpointStage = configArray.testingState
        const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`
        try {
            // First, get the signed URL
            const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
    
            // Send the POST request to the signed URL
            const result = await postToAPISignedUrl(signedUrl, event_data, "POST");
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestData(cubeQuery, patternAPI, support_key, support_id)
        requestDimData(dimQuery, patternAPI, support_key, support_id)
      }, [configArray, filters])


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

    const cubeResponsebase = configResponse.data.body
    const dimCubeResponse = dimResponse.data.body

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueFilter1 = getUniqueValues(dimCubeResponse, filter1name)


    if (cubeResponsebase.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    const cubeResponse = cubeResponsebase.map((obj) => {
        return {
            dim: obj.dim,
            event_count: obj.event_count,
            users: obj.users,
            users_logged_in: obj.users_logged_in
        }
    })




    return (
        <View style={styles.container}>
            <AnalyticsEventsPanels
            theme={theme}
            dataProp={cubeResponse}
            filterLabels={uniqueFilter1}
            handleStateChange={handleStateChange}
            propObject={propObject}
            filterParams={filterParams}
            filterList={filterList}
            setFilterList={setFilterList}
            currentMetrics={currentMetrics}
            columnVisibilityModel={columnVisibilityModel}
            setColumnVisibilityModel={setColumnVisibilityModel}
            />
        </View>

    )

}

export default AnalyticsEventsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})