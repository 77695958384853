import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import { postToSignedUrl } from '../UserConfigs/AwsSigner.js';
import signRequest from '../UserConfigs/AwsSigner.js';
import DocPanel from '../DocBuilder/DocPanel';
import docConfig from '../DocBuilder/docConfig.json'
import moment from 'moment-timezone';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const DocBuilder = (props) => {

  const configArray = props.configArray
  const config_url = configArray.config_url
  const support_id = configArray.support_id
  const support_key = configArray.support_key
  const client_upper = configArray.clientID
  const client_lower = client_upper.toLowerCase()
  const client_id = configArray.clientMapId
  const auth0_user_id = configArray.auth0_user_id
  const [configResponse, setConfigResponse] = useState(null)
  const [dimResponse, setDimResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const currentMetrics = configArray.currentMetrics[0]
  const tooltipArray = props.tooltipArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const client = clientID.toLowerCase();
  
  const cubeTable = client + "_daily";
  const theme = props.theme
  const homeTitle = "Presentation Builder";
  const role = configArray.role
  let clientText;
  if (clientID === 'PS') {
    clientText = 'Pepperstone'
  } else {
    clientText = client
  }

  const filter1name = 'brand'
  const filter2name = 'channel'
  const filter3name = 'kw_type'

  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');

  const currentDate = new Date();

  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  function updateDates(data, newStartDate, newEndDate) {
    return data.map(item => {
        return {
            ...item,  // Spread the existing properties of the item
            start: newStartDate,  // Overwrite the start date
            end: newEndDate       // Overwrite the end date
        };
    });
}

const updatedConfig = updateDates(docConfig, startDateString, yesterdayProp);
  
  const [currentConfig, setCurrentConfig] = useState(JSON.parse((localStorage.getItem('docConfig'))) ?? updatedConfig)

  const dates = {
    startDateString,
    yesterdayProp
  }

  

  function updateSlide(prop) {
    const { currentSlide, currentVar, currentValue } = prop;

    // Create a new copy of the currentConfig array
    const updatedConfig = [...currentConfig];

    // If the slide exists, update the specific variable
    if (updatedConfig[currentSlide]) {
        updatedConfig[currentSlide] = {
            ...updatedConfig[currentSlide],
            [currentVar]: currentValue
        };
    }

    // Set the new state
    setCurrentConfig(updatedConfig);
    localStorage.setItem("docConfig", JSON.stringify(updatedConfig));
}

function updateDate(currentSlide, start, end) {
  
  // Create a new copy of the currentConfig array
  const updatedConfig = [...currentConfig];

  // If the slide exists, update the specific variable
  if (updatedConfig[currentSlide]) {
      updatedConfig[currentSlide] = {
          ...updatedConfig[currentSlide],
          'start': start,
          'end' : end
      };
  }

  // Set the new state
  setCurrentConfig(updatedConfig);
  localStorage.setItem("docConfig", JSON.stringify(updatedConfig));
}

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  function findDateRange(data) {
    let earliestStartDate = data[0].start;
    let latestEndDate = data[0].end;

    data.forEach(slide => {
      if (new Date(slide.start) < new Date(earliestStartDate)) {
        earliestStartDate = slide.start;
      }
      if (new Date(slide.end) > new Date(latestEndDate)) {
        latestEndDate = slide.end;
      }
    });

    return {
      start: earliestStartDate,
      end: latestEndDate
    };
  }

  const dateRange = findDateRange(currentConfig);

  const start = dateRange.start;
    const end = dateRange.end;


  const cubeFilters = [

]

    const cubeDims = ['date', 'brand', 'channel', 'kw_type']
    const dimList = [filter1name, filter2name, filter3name]

    function formatString(input) {
      return input.toLowerCase().replace(/ /g, "_");
  }


    const cubeQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": cubeDims,
          "measures": [
            "impressions",
            "clicks",
            "spend",
            "regs",
            "ftds",
            "ngr"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": [],
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_cube`
        }
    }

    const dimQuery = {

        "request_type": "query",
        "query": {
          "start": start,
          "end": end,
          "table": cubeTable,
          "date_dim": "date",
          "dimensions": dimList,
          "measures": [
            "impressions"
          ],
          "custom_dimensions": [],
          "custom_dimensions_aliases": [],
          "custom_measures": [],
          "custom_measures_aliases": [],
          "filters": [],
          "client_id": client_id,
          "auth0_user_id": auth0_user_id,
          "report_name": `${client_lower}_${formatString(homeTitle)}_dim`
        }
    }

    const requestData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setConfigResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setConfigResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
    };

    const requestDimData = async (event_data, support_url, support_key, support_id, method) => {
        setIsLoading(true)
        setDimResponse(null); // Clear previous response
    
        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);
    
            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setDimResponse(result)
            setIsLoading(false)
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            //setSaveLoading(false);
        }
      };

    useEffect(() => {
        requestData(cubeQuery, config_url, support_key, support_id, 'POST')
        requestDimData(dimQuery, config_url, support_key, support_id, 'POST')
      }, [configArray])

      if (isLoading) {
        return (
          <LoadingComponent theme={theme} />
        );
      }
      
      if (!configResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }

      if (!dimResponse) {
        return (
          <LoadingComponent theme={theme} />
        );
      }



    const cubeResponseBase = configResponse.data


    const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DDT00:00:00.000");

    const brandProp = `brand`
    const channelProp = `channel`
    const clicksProp = `clicks`
    const dateProp = `date`
    const ftdsProp = `ftds`
    const impProp = `$impressions`
    const typeProp = `kw_type`
    const regProp = `regs`
    const spendProp = `spend`
    const timestampProp = `timestamp`
    const timestampDayProp = `timestamp_day`
    

    const fallback = [{
        [brandProp]: "No Data",
        [channelProp]: "No Data",
        [clicksProp]: "0",
        [dateProp]: yesterday,
        [ftdsProp]: "0",
        [impProp]: "0",
        [typeProp]: "No Data",
        [regProp]: "0",
        [spendProp]: "0",
        [timestampProp]: yesterday,
        [timestampDayProp]: yesterday,
    }]


const cubeResponse = cubeResponseBase.length > 0 
    ? cubeResponseBase
    : fallback;

const dimData = dimResponse.data

const getUniqueValues = (arr, key) => {
  const uniqueSet = new Set(arr.map(item => item[key]));
  return [...uniqueSet];
};

const uniqueBrandsBase = getUniqueValues(dimData, filter1name);
const uniqueChannelsBase = getUniqueValues(dimData, filter2name);
const uniqueTypesBase = getUniqueValues(dimData, filter3name);
const uniqueBrands = ['Total', ...uniqueBrandsBase];
const uniqueChannels = ['Total', ...uniqueChannelsBase];
const uniqueTypes = ['Total', ...uniqueTypesBase];
const chartTypes = ['line', 'bar', 'mixed', 'table', 'cohorts']
const metrics = ['impressions', 'clicks', 'spend', 'regs', 'ftds', 'cpa']

const lastUpdated = 'loading...';

if (cubeResponse.length === 0) {
  return (
      <NoDataContainer
          theme={theme}
      />
  )
}


  return (

    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <DocPanel
        theme={theme}
        currentConfig={currentConfig}
        setCurrentConfig={setCurrentConfig}
        uniqueBrands={uniqueBrands}
        uniqueChannels={uniqueChannels}
        uniqueTypes={uniqueTypes}
        chartTypes={chartTypes}
        metrics={metrics}
        updateSlide={updateSlide}
        updateDate={updateDate}
        cubeResponse={cubeResponse}
        cubeTable={cubeTable}
        client={clientText}
        tooltipArray={tooltipArray}
        currentMetrics={currentMetrics}
        dates={dates}
      />


    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 1,
    flexDirection: 'column',
    width: '100%',
    minHeight: windowHeight
  },
  panel: {
    width: '100%'
  }
})

export default DocBuilder