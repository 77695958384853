import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import WebFont from 'webfontloader';
import { LicenseInfo } from '@mui/x-license-pro';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExecSummaryMetricsTable from '../ExecSummaryMetrics';


const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const ExecSummaryChannelContent = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const dataProp = props.dataProp
    const aggResult = dataProp.aggResult
    const theme = props.theme
    const totalSweAgg = aggResult.totalSweAgg
    const luckySweAgg = aggResult.luckySweAgg
    const happySweAgg = aggResult.happySweAgg    
    const flaxSweAgg = aggResult.flaxSweAgg

    const execDataProp = [
        {
            id: "luckySweAgg",
            title: "Lucky - SWE",
            dataProp: luckySweAgg,
            isAllPercent: false
        },
        {
            id: "happySweAgg",
            title: "Happy - SWE",
            dataProp: happySweAgg,
            isAllPercent: false
        },        
        {
            id: "flaxSweAgg",
            title: "Flax - SWE",
            dataProp: flaxSweAgg,
            isAllPercent: false
        }
    ]

    const execDataPropExpanded = [
        {
            id: "totalSweAgg",
            title: "All - SWE",
            dataProp: totalSweAgg,
            isAllPercent: false
        }
    ]

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let responseHeight;

    if (windowWidth < 1441) {
        responseHeight = windowHeight * 0.11
    } else if (windowWidth > 2000) {
        responseHeight = windowHeight * 0.08
    } else { responseHeight = windowHeight * 0.08 }


    const panelMain = {

        width: '100%',
        height: responseHeight,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
    }

    

    const AccordionComponent = ({ filterParams, dynamicFilter, currentMetrics }) => {


        return (
            < >
                {execDataProp.map((item) => (

                    <Accordion key={item.id} sx={{ border: `3px solid ${theme.backgroundColor}` }}
                    //defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                            sx={{
                                color: 'white',
                                backgroundColor: theme.backgroundColor2,
                                fontFamily: 'Electrolize',
                                fontWeight: 'bold'
                            }}
                        >
                            {item.title}
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            color: 'white',
                            backgroundColor: theme.backgroundColor,
                            fontFamily: 'Electrolize',
                            fontWeight: 'bold'
                        }}>
                            <ExecSummaryMetricsTable
                                dataProp={item.dataProp}
                                theme={theme}
                                filterParams={filterParams}
                                dynamicFilter={dynamicFilter}
                                currentMetrics={currentMetrics}
                                isAllPercent={item.isAllPercent}
                                itemId={item.id}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        );
    };

    const AccordionComponentExpand = ({ filterParams, dynamicFilter, currentMetrics }) => {


        return (
            < >
                {execDataPropExpanded.map((item) => (

                    <Accordion key={item.id} sx={{ border: `3px solid ${theme.backgroundColor}` }}
                    defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                            sx={{
                                color: 'white',
                                backgroundColor: theme.backgroundColor2,
                                fontFamily: 'Electrolize',
                                fontWeight: 'bold'
                            }}
                        >
                            {item.title}
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            color: 'white',
                            backgroundColor: theme.backgroundColor,
                            fontFamily: 'Electrolize',
                            fontWeight: 'bold'
                        }}>
                            <ExecSummaryMetricsTable
                                dataProp={item.dataProp}
                                theme={theme}
                                filterParams={filterParams}
                                dynamicFilter={dynamicFilter}
                                currentMetrics={currentMetrics}
                                isAllPercent={item.isAllPercent}
                                itemId={item.id}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        );
    };

    

        return (
            <View style={styles.container}>
                <AccordionComponentExpand/>
                <AccordionComponent/>
                

            </View>

        )

    }

    export default ExecSummaryChannelContent

    const styles = StyleSheet.create({
        container: {
            borderWidth: 0,
            borderColor: 'blue',
            height: (windowHeight * 0.93),
            width: '100%',
            flexDirection: 'column',
            fontFamily: 'Electrolize'
        }
    })