import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import {
    View,
    Dimensions,
    Text
} from 'react-native'
import Button from '@mui/material/Button';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


export default function OptionsMetricsAvailable(props) {
    const open = props.open
    const setOpen = props.setOpen
    const theme = props.theme
    const currentMetrics = props.currentMetrics

    

 
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        setOpen(false);
    };

    const metricContainerStyle = {
        width: "25%",
        height: '100%',
        backgroundColor: theme.backgroundColor2,
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',
        borderColor: theme.backgroundColor,
        borderWidth: 2,
    }

    const metricTextStyle = {
        textAlign: 'center',
        fontSize: windowWidth * 0.01,
        color: 'white',

        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.4))',
    }

    const MetricPaper = ({value}) => {

        return (
            <View style={metricContainerStyle}>
                <Text style={metricTextStyle}>
                    {value}
                </Text>
            </View>

        )
    }

    const metricList = [
        "impressions",
        "clicks",
        "spend",
        "regs",
        "ftds",
        "ftd_ngr",
        "deposits",
        "deposit_amount",
        "retention_ngr",
        "reactivations"
    ]


    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                maxWidth={"true"}

            >
                <View style={{width: '100%'}}>
                <DialogTitle sx={{ fontFamily: 'Electrolize', backgroundColor: theme.backgroundColor2, color: 'white'}}>Available Metrics</DialogTitle>
                <DialogContent sx={{
                }}>
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: windowWidth * 0.5,
                        height: windowHeight * 0.20,
                        //backgroundColor: theme.backgroundColor2
                        }}>
                            <View style={{width: '100%', height: '33.33333%', alignItems:'center', justifyContent: 'center', flexDirection: 'row', padding: '1%'}}>
                            
                            <MetricPaper value={"impressions"}/>
                            <MetricPaper value={"clicks"}/>
                            <MetricPaper value={"spend"}/>
                            <MetricPaper value={currentMetrics.secondaryMetric}/>
                            </View>
                            <View style={{width: '100%', height: '33.33333%', alignItems:'center', justifyContent: 'center', flexDirection: 'row', padding: '1%'}}>
                            
                            <MetricPaper value={currentMetrics.primaryMetric}/>
                            <MetricPaper value={currentMetrics.primaryRevenue}/>
                            <MetricPaper value={"deposits"}/>
                            <MetricPaper value={"deposit_amount"}/>
                            </View>
                            <View style={{width: '100%', height: '33.33333%', alignItems:'center', justifyContent: 'center', flexDirection: 'row', padding: '1%'}}>
                            
                            <MetricPaper value={`retention_${currentMetrics.primaryRevenue}`}/>
                            <MetricPaper value={"reactivations"}/>
                            </View>
                            
                    </Box>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                </DialogActions>
                </View>

            </Dialog>
        </div>

    );
}

