import './App.css';
import React, { useState, createContext, useEffect } from 'react';
import { Dimensions } from 'react-native';
import Login from './Screens/Login';
import SignedUp from './Screens/SignedUp';
import MobileScreen from './Mobile/MobileScreen';
import { themes } from './themes'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AppRoutes from './Routes';
import { useAuth0 } from "@auth0/auth0-react";
import { postToAPISignedUrl } from './UserConfigs/ApiGateway';
import postToApiGateway from './UserConfigs/ApiGateway';
import LoadingComponent from './Components/Loader'
import TagManager from 'react-gtm-module';
import map_1001 from './ClientRoutes/1001.json'
import map_1002 from './ClientRoutes/1002.json'
import map_1003 from './ClientRoutes/1003.json'
import map_1004 from './ClientRoutes/1004.json'
import map_1005 from './ClientRoutes/1005.json'
import map_1006 from './ClientRoutes/1006.json'
import map_1007 from './ClientRoutes/1007.json'
import NotFound from './Components/NoutFound';


const windowWidth = Dimensions.get('window').width;

export const RouteMapContext = createContext()

function App(props) {

  const fmKey = props.fmKey
  const testingState = props.testingState
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [themeType , setTheme] = useState((localStorage.getItem('mode')) ?? 'white')
  const [toolTips , setToolTips] = useState((localStorage.getItem('mode')) ?? true)
  const [clientID, setClientID] = useState((localStorage.getItem('client')))
  const [clientMapID, setClientMapID] = useState((localStorage.getItem('clientId')))
  const [userConfig, setUserConfig] = useState(null)
  const [configResponse, setConfigResponse] = useState(null)

  function tootTipHandler (props) {
    localStorage.setItem("toolTips", props)
    setToolTips(false)
  }

  const tooltipArray = {
    toolTips,
    tootTipHandler
  }

  function configHandler (props) {
    localStorage.setItem("mode", props)
    
    setTheme(props)
  }

  function clientHandler (clientId, clientMapId) {
    localStorage.setItem("client", clientId)
    localStorage.setItem("clientId", clientMapId)
    setClientID(clientId)
    setClientMapID(clientMapId)
  }

  useEffect(() => {

    if (!user) return;

    const userProfile = user
    const userRole = userProfile?.stack_user_metadata?.role || 'guest'
    const userClient = userProfile?.stack_user_metadata?.client || 'guest'
    const userClientId = userProfile?.stack_user_metadata?.clientId || 'guest'
    const userName = userProfile.name
    const userNickname = userProfile.nickname
    const auth0_user_id = userProfile?.stack_user_identities[0]?.user_id
    const comparisonURL = userProfile?.stack_user_metadata?.comparison_url || 'guest'
    const support_url = userProfile?.stack_user_metadata?.support_url || 'guest'
    const testing_url = userProfile?.stack_user_metadata?.testing_url || 'guest'
    const schema_url = userProfile?.stack_user_metadata?.schema_url
    const config_url = userProfile?.stack_user_metadata?.config_url || 'guest'
    const support_id = userProfile?.stack_user_metadata?.support_id || 'guest'
    const support_key = userProfile?.stack_user_metadata?.support_key || 'guest'
    const patternAPI = userProfile?.stack_user_metadata?.pattern_api

    const clientIdentifier = clientID || userClient;
    const clientMapId = clientMapID || userClientId

    let cleanId, cleanClient

    if (userRole === 'admin' && clientMapID) {
      cleanId = clientMapID
      cleanClient = clientID
    } else if (userRole === 'admin' && !clientMapID) {
      cleanId = userClientId
      cleanClient = userClient
    } else {
      cleanId = userClientId
      cleanClient = userClient
    }
    
    const userBase = {
      userRole,
      userClient: clientIdentifier,
      userClientId,
      userName,
      userNickname,
      userProfile,
      comparisonURL,
      config_url,
      testing_url,
      support_url,
      schema_url,
      support_id,
      support_key,
      auth0_user_id,
      clientIdentifier,
      clientMapId,
      testingState,
      patternAPI
    }
    setUserConfig(userBase)

    const event_data = {
      client: cleanClient,
      client_id: cleanId,
      auth0_user_id: auth0_user_id,
      "request_type": "custom_configs"
  }
    const sendDataToLambda = async (event_data, support_url, support_key, support_id) => {
      //setConfigLoading(true); // Start loading
      setConfigResponse(null); // Clear previous response
      const requestType = event_data.request_type
      const endpointStage = testingState
      const patternEndpoint = `${support_url}/${endpointStage}/${requestType}`

      try {
          // First, get the signed URL
          const signedUrl = await postToApiGateway(event_data, patternEndpoint, support_key, support_id);
          // Send the POST request to the signed URL
          const result = await postToAPISignedUrl(signedUrl, event_data, 'POST');
          setConfigResponse(result)
          //setSaveLoading(false);
      } catch (error) {
          console.error("Error posting to Lambda:", error);
      } finally {
          //setSaveLoading(false);
      }
  };

  sendDataToLambda(event_data, patternAPI, support_key, support_id)

  }, [user])

  const theme = themes[themeType]

  if (isLoading) {
    return <LoadingComponent theme={theme} />;
  }

  if (!isAuthenticated) {
    return <Login theme={theme}/>
  }

  if (!configResponse) {
    return <LoadingComponent theme={theme} />;
  } 

  const userRole = userConfig.userRole
  const userClient = userConfig.userClient
  const userClientId = userConfig.userClientId
  const userName = userConfig.userName
  const userNickname = userConfig.userNickname
  const comparisonURL = userConfig.comparisonURL
  const support_url = userConfig.support_url
  const support_id = userConfig.support_id
  const support_key = userConfig.support_key
  const config_url = userConfig.config_url
  const testing_url = userConfig.testing_url
  const schema_url = userConfig.schema_url
  const auth0_user_id = userConfig.auth0_user_id
  const patternAPI = userConfig.patternAPI

  const clientIdentifier = clientID || userClient;
  const clientMapId = clientMapID || userClientId
  const routeMap = {
    ...map_1001, 
    ...map_1002, 
    ...map_1003, 
    ...map_1004, 
    ...map_1005, 
    ...map_1006, 
    ...map_1007
  }
  
  const routeMapBase = routeMap[clientMapId]
  const currentRoutes = routeMap[clientMapId]['config']
  const currentMetrics = routeMap[clientMapId]['metricConfig']

  const configArray = {
    role : userRole,
    userName: userName,
    userNickname: userNickname,
    auth0_user_id,
    clientID: clientIdentifier,
    comparison_url: comparisonURL,
    clientMapId,
    configHandler,
    clientHandler,
    tooltipArray,
    currentMetrics,
    testingState,
    fmKey,
    patternAPI,
    config_url,
    testing_url,
    support_url,
    schema_url,
    support_id,
    support_key,
    customGroupings: configResponse.data.body.custom_groupings,
    customModel: configResponse.data.body.custom_model,
    preSigned: configResponse.data.body.presigned
  }

  const tagManagerArgs = {
    gtmId: 'GTM-N956Z6GW',
  }

  TagManager.initialize(tagManagerArgs);

  if (clientIdentifier === "guest") {
    return (<SignedUp theme={theme} configArray={configArray} themeType={themeType} />)
  }

  else if (windowWidth < 450) {
    return (
      <MobileScreen theme={theme} configArray={configArray} themeType={themeType} />
    )
  } else { 
    return (
    <RouteMapContext.Provider value={routeMapBase}>
    <Router >
    <Routes>
    {currentRoutes.map((route, index) => {
          const Component = AppRoutes[route.component];
          return (
            
            <Route 
              key={index}
              path={route.path}
              element={<Component theme={theme} 
                                  configArray={configArray} 
                                  configHandler={configHandler}
                                  clientHandler={clientHandler}
                                  userProfile={userConfig.userProfile}
                                  themeType={themeType} 
                                  
                                  />}
              exact={route.exact || false}
            />
            
          );
        })}
      <Route path="*" element={<NotFound theme={theme}/>} />
    </Routes>
    </Router>
    </RouteMapContext.Provider>

    )
  }
}

export default App;
