export default function AttEnhancedTrendsGrouping(data, dim, grouping, labels, metric) {

    var dataset = data

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    const metricValue = metric
    const groupingValue = grouping

    // Step 1: Aggregate the dataset
    // Step 1: Aggregate the dataset
    const agg_result = dataset.reduce((res, value) => {
        const dimension = value[dim];
        const grouping = value[groupingValue];
        const metric = value[metricValue];

        // Create a unique key combining dimension and grouping
        const key = `${dimension}-${grouping}`;

        // Initialize the aggregation object if this key is not in res
        if (!res[key]) {
            res[key] = {
                [dim]: dimension,       // Store the dimension
                [groupingValue]: grouping, // Store the grouping
                [metricValue]: 0,       // Initialize the metric
            };
        }

        // Aggregate values by summing them
        res[key][metricValue] += parseInt(metric) || 0;

        return res;
    }, {});

    // Convert agg_result object to an array for easier processing
    const agg_result_array = Object.values(agg_result);

    // Step 2: Build all possible combinations of dimensions and groupings
    const allCombinations = [];
    const uniqueDimensions = [...new Set(dataset.map(item => item[dim]))]; // Get unique dimensions
    const uniqueGroupings = [...new Set(dataset.map(item => item[groupingValue]))]; // Get unique groupings

    uniqueDimensions.forEach(dimension => {
        uniqueGroupings.forEach(grouping => {
            allCombinations.push({
                [dim]: dimension,
                [groupingValue]: grouping,
                [metricValue]: 0, // Default metric value
            });
        });
    });

    // Step 3: Merge aggregated results with all combinations (preventing cumulative values)
    const mergedResult = allCombinations.map(combination => {
        const matchingEntry = agg_result_array.find(
            entry =>
                entry[dim] === combination[dim] &&
                entry[groupingValue] === combination[groupingValue]
        );

        // Ensure only one-to-one mapping, no accumulation
        return matchingEntry
            ? { ...combination, [metricValue]: matchingEntry[metricValue] }
            : combination;
    });

    // Step 4: Separate results into arrays by grouping (country)
    const aggOutput = uniqueGroupings.map(grouping => {
        return mergedResult.filter(entry => entry[groupingValue] === grouping);
    });

    return aggOutput

}