import AWS from "aws-sdk";

export default async function postToApiGateway(event_data, apiUrl, config_key, config_secret) {

      // Configure AWS credentials
      const { host, pathname: path } = new URL(apiUrl);
      const credentials = new AWS.Credentials(config_secret, config_key);
      const region = "eu-central-1"
      console.log(event_data)

      // Set up the AWS HttpRequest
      const request = new AWS.HttpRequest(apiUrl, region);
      request.method = 'POST';
      request.headers['Content-Type'] = 'application/json';
      request.headers["host"] = host;
      request.path = path || "/";
      request.body = JSON.stringify(event_data);
      //request.headers['Host'] = new URL(apiUrl).host;

      // Sign the request using AWS SigV4
      const signer = new AWS.Signers.V4(request, 'execute-api');
      signer.addAuthorization(credentials, new Date());

      // Extract signed headers
      return {
        signedUrl: apiUrl, // Base URL
        headers: request.headers, // Signed headers including Authorization
      };

}

export async function postToAPISignedUrl({ signedUrl, headers }, payload, method) {

  try {
    const response = await fetch(signedUrl, {
      method: method,
      headers: {
        ...headers, // Spread the signed headers, including Authorization, X-Amz-Date, etc.
        //"Content-Type": "application/json", // Ensure Content-Type is present
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Request failed with status ${response.status}. AWS response: ${errorText}`);
    }

    const data = await response.json();

    return { statusCode: response.status, data };
  } catch (error) {
    console.error("Error posting to API Gateway:", {
      message: error.message,
      stack: error.stack,
      additionalInfo: {
        signedUrl,
        headers,
        payload,
      },
    });
    return { statusCode: "error", data: null };
  }
}

export async function postToAPIpreagg({ signedUrl, headers }, payload, method) {

  try {
    const response = await fetch(signedUrl, {
      method: method,
      headers: {
        ...headers, // Spread the signed headers, including Authorization, X-Amz-Date, etc.
        //"Content-Type": "application/json", // Ensure Content-Type is present
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Request failed with status ${response.status}. AWS response: ${errorText}`);
    }

    const data = await response.json();

    return { statusCode: response.status, data };
  } catch (error) {
    console.error("Error posting to API Gateway:", {
      message: error.message,
      stack: error.stack,
      additionalInfo: {
        signedUrl,
        headers,
        payload,
      },
    });
    return { statusCode: "error", data: null };
  }
}

export async function signPreagg(event_data, apiUrl, config_key, config_secret) {

  // Configure AWS credentials
  const { host, pathname: path } = new URL(apiUrl);
  const credentials = new AWS.Credentials(config_secret, config_key);
  const region = "eu-central-1"
  console.log(event_data)

  // Set up the AWS HttpRequest
  const request = new AWS.HttpRequest(apiUrl, region);
  request.method = 'GET';
  request.headers['Content-Type'] = 'application/json';
  request.headers["host"] = host;
  request.path = path || "/";
  //request.headers['Host'] = new URL(apiUrl).host;

  // Sign the request using AWS SigV4
  const signer = new AWS.Signers.V4(request, 'execute-api');
  signer.addAuthorization(credentials, new Date());
  

  // Extract signed headers
  return {
    signedUrl: apiUrl, // Base URL
    headers: request.headers, // Signed headers including Authorization
  };

}