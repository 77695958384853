import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import FilterSummary from '../../ComponentsEnhanced/FilterContainers/FilterSummary';
import AttEnhancedTrendsParse from './AttEnhancedTrendsParse';
import AttEnhancedTrendsGrouping from './AttEnhancedTrendsGrouping';
import AttEnhancedTrendsLine from './AttEnhancedTrendsLine';
import AttEnhancedTrendsBar from './AttEnhancedTrendsBar';
import AttEnhancedTrendsModal from './AttEnhancedTrendsModal';

const windowHeight = Dimensions.get('window').height;

const AttEnhancedTrendsPanels = (props) => {

    const client = props.client
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const aggResult = dataProp.aggResult
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const [isCumulative, setIsCumulative] = useState(false)
    const [open, setOpen] = useState(false)
    const configBase = {
        metric: 'spend',
        timeValue: 'daily',
        graph: 'bar',
        dimension: 'channel'
    }
    const [config, setConfig] = useState(configBase)
    const metric = config.metric
    const dimension = config.dimension
    const graph = config.graph
    const timeValue = config.timeValue

    const dimensionAgg = AttEnhancedTrendsParse(aggResult, 'date', timeValue, metric, dimension)

    const controlArray = {
        setOpen,
        open,
        metric,
        dimension,
        graph,
        timeValue,
        config,
        setConfig
    }

    const calcCumulative = (data) => {
        let cumuMetric = 0;

        return data.map(item => {
            cumuMetric += item[metric];
            return {
                [dimension]: item[dimension],
                [metric]: cumuMetric
            }
        })
    }

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(dimensionAgg, 'date')
    const dimLabels = getUniqueValues(dimensionAgg, dimension)

    const stackedData = AttEnhancedTrendsGrouping(dimensionAgg, 'date', dimension, dimLabels, metric)

    let dynamicData, cumuDataFrame = [];

    stackedData.forEach(country => {

        cumuDataFrame.push(calcCumulative(country))

    })

    if (!isCumulative) {
        dynamicData = stackedData
    } else {
        dynamicData = cumuDataFrame
    }

    const renderComponentContent = () => {
        if (graph === 'line') {
            return <AttEnhancedTrendsLine
                agg_daily_result={dynamicData}
                dateLabels={dateLabels}
                theme={theme}
                metric={metric}
                timeFrame={timeValue}
                client={client}
                currentMetrics={currentMetrics}
                breakdown={dimension}
            />
        } else if (graph === 'bar') {
            return <AttEnhancedTrendsBar
                agg_daily_result={dynamicData}
                dateLabels={dateLabels}
                theme={theme}
                metric={metric}
                timeFrame={timeValue}
                client={client}
                currentMetrics={currentMetrics}
                breakdown={dimension}
            />
        } else {
            return null;
        }
    };

    const panelMain = {

        width: '100%',
        height: windowHeight * 0.07,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
    }

    const panelGraph = {

        width: '100%',
        height: windowHeight * 0.86,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        padding: '2%'
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'column' }}>
                <View style={panelMain}>
                    <View style={{ width: '66%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FilterSummary
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            setOpen={setOpen}
                            isCumulative={isCumulative}
                            setIsCumulative={setIsCumulative}
                            large={1}

                        />
                    </View>
                </View>
                <View style={panelGraph}>

                    {renderComponentContent()}

                </View>

            </View>
            <AttEnhancedTrendsModal
                theme={theme}
                controlArray={controlArray}
            />



        </View>

    )



}

export default AttEnhancedTrendsPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93),
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    }
})