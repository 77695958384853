import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { GridToolbarContainer,
        GridToolbarColumnsButton,
        GridToolbarFilterButton,
        GridToolbarDensitySelector,
        GridToolbarExport 
       } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function StudioCreateDataGrid(props) {

  const currentMetrics = props.currentMetrics
  const dataProp = props.dataProp
  const theme = props.theme
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false})
  

  let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}


  /*const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    clicks: false,
    impressions: false,
    regs: false,
    channel: false,
    cpr: false
  });*/

  

  const valueFormat = (value, field) => {
    if (value === 'ftds') {
        return currentMetrics.primaryMetric
    } else if (value === 'ftd') {
        return currentMetrics.primaryMetric
    } else if (value === 'regs') {
        return currentMetrics.secondaryMetric
    } else if (value === 'ftd_ngr') {
        return currentMetrics.primaryRevenue
  }  else if (value === 'kw_type') {
    return currentMetrics.primaryDimensionString.toLowerCase()
    } else {return value}
};

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
   }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
    });

    const labelObject = dataProp[0] || []

    const labels = Object.keys(labelObject).map((key , index) => {

      let newLabel;
      if (key === 'ftds') {
          newLabel = currentMetrics.primaryMetric
      } else if (key === 'ftd') {
          newLabel = currentMetrics.primaryMetric
      } else if (key === 'regs') {
          newLabel = currentMetrics.secondaryMetric
      } else if (key === 'ftd_ngr') {
        newLabel = currentMetrics.primaryRevenue
    }  else if (key === 'kw_type') {
          newLabel = currentMetrics.primaryDimensionString.toLowerCase()
      } else {newLabel = key}

        return { 
        field: key, 
        headerName: newLabel, 
        //minWidth: windowWidth * columnWidth * 1.25, 
        flex: 1, 
        headerAlign: 'center', 
        headerClassName: 'table-header', 
        align: 'center',
        type: 'number',
        renderCell: (params) => {
            const { value, field } = params;
            if (index <= 1) {
                return (
                  <div
                    className="heatmap-cell"
                    style={{
                      backgroundColor: theme.backgroundColor,
                      padding: '7%',
                      color: theme.color,
                      width: '100%',
                      fontSize: responseText * 0.8
                    }}
                  >
                    {valueFormat(params.formattedValue, params.field)}
                  </div>
                );
              } else if (params.aggregation) {
                return (
                    <div
                        className="heatmap-cell-aggregated"
                        style={{
                            fontWeight: 'bold', // Add your custom font weight
                            display: 'flex', // Apply flex styles to center content vertically/horizontally
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%', // Ensure the cell takes up the full height
                            width: '100%',
                            color: theme.color,
                            paddingLeft: 0,
                            fontFamily: 'Electrolize',
                            fontSize: responseText * 0.8,
                        }}
                    >
                        {valueFormat(params.formattedValue, params.field)}
                    </div>
                );
            } else return (
                <div
                  className="heatmap-cell"
                  style={{
                    backgroundColor: theme.backgroundColor,
                    padding: '10%',
                    width: '100%' ,
                    fontSize: responseText * 0.8
                    
                  }}
                >
                  {valueFormat(params.formattedValue, params.field)}
                </div>
              );
            },
        
        };
      });  



  const largeRows = ((windowHeight / 60).toFixed(0))

  return (
    <Box
    style={{
      width: '75%',
      height : windowHeight * 0.82, 
      fontSize: 50,
      color: 'black',
      fontFamily : 'Electrolize',
      marginTop: '1%'
      
    }}
      sx={{
        '& .table-header': { 
          headerAlign: 'center', 
          align : 'center' , 
          fontFamily : 'Electrolize' , 
          color: theme.color, 
          fontSize: responseText, },
          '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
          '& .MuiTablePagination-root' : {color: theme.color},
        width: '100%'}}
    >
      <DataGridPremium 
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        pagination
        columnVisibilityModel={columnVisibilityModel}

        initialState={{
          pagination: {
            paginationModel: { pageSize: parseInt(largeRows) }           
          },
        sorting: {
        sortModel: [{ field: 'spend', sort: 'desc' }],
        },
        }}
        
        />
    </Box>
  );
}